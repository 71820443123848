import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getFactoringRequestsReceived,
  getFactoringRequestsReceivedFiltered,
  getRecieverFactoringRequest,
} from '../../../../../../../lib/api';
import CenteredCircularProgress from '../../../../../../../lib/designSystem/CircularProgress';
import EnhancedTable from '../../../../../../../lib/designSystem/Table';
import CustomAlert from '../../../../../../../lib/designSystem/Alert';
import {
  validateCompanyTypes,
  validatePermissions,
} from '../../../../../../../lib/functions/utils';
import { AuthState } from '../../../../../../../services/redux/authSlice';
import ShoppingDialogRecieved from './components/ShoppingDialog';
import HeaderWithActions, {
  CustomButtonProps,
} from '../../../../../../../lib/designSystem/HeaderWithActions';
import ShoppingCartFactoring from '../../../../../../BusinessProfile/SII/Components/BusinessInvoices/components/ShoppingCart';
import DocumentSelector from '../../../common/documentsSelector';
import { useParams } from 'react-router-dom';
import AddInvoicesManually from '../../../common/invoicesAdder';
import AddDocumentsManually from '../../../common/documentsAdder';
import {
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
} from '@mui/material';
import FactoringRequestDeleteConfirmationModal from './components/DeleteModal';
const statuses = [
  'Creada',
  'En revisión',
  'Cancelada',
  'Aceptada',
  'Activa',
  'Completada',
];

const openStatuses = ['Creada', 'En revisión', 'Aceptada'];

export interface FactoringRequest {
  id: number;
  status: string;
  sender: string;
  recipient: string;
  validUntil: Date;
  paymentDate: Date;
  amount: number;
  extraData: boolean;
  relation?: any;
  businessId: number;
}

const dataHeadersDefault = [
  { key: 'id', label: 'ID' },
  { key: 'exception', label: 'Exc', type: 'boolean-exception-null' },
  { key: 'turn', label: 'Turno', type: 'turn' },
  { key: 'businessName', label: 'Cliente' },
  { key: 'amount', label: 'Monto', type: 'money' },
  { key: 'minutesSinceUpdate', label: 'Estancado', type: 'formatMinutes' },
  { key: 'createdAt', label: 'Creado en', type: 'date-large' },
  { key: 'status', label: 'Estado', type: 'status' },
];

const FactoringRequestsRecieved = (props: any) => {
  const { profile } = props;
  const { id } = useParams();
  const dispatch = useDispatch();
  const [factoringRequests, setFactoringRequests] = useState<
    FactoringRequest[]
  >([]);
  const [filter, setFilter] = useState<string[]>(openStatuses);
  const [filteredFactoringRequests, setFilteredFactoringRequests] = useState<
    FactoringRequest[]
  >([]);
  const [exchangeDay, setExchangeDay] = useState(false);
  const [loading, setLoading] = useState(false);
  const [shoppingCartType, setShoppingCartType] = useState('');
  const [openShoppingCart, setOpenShoppingCart] = useState(false);
  const [openRequest, setOpenRequest] = useState<FactoringRequest | null>(null);
  const [success, setSuccess] = useState(false);
  const [failed, setFailed] = useState(false);
  const [successText, setSuccesText] = useState('');
  const [failedMessage, setFailedText] = useState('');
  const [dataHeaders, setDataHeaders] = useState<any>([]);
  const user = useSelector((state: { auth: AuthState }) => state.auth.user);
  const [openDocumentsSelector, setOpenDocumentsSelector] = useState(false);
  const [openNewShoppingCart, setNewOpenShoppingCart] = useState(false);
  const [continueToSimulation, setContinueToSimulation] = useState(false);
  const [selectedDocuments, setSelectedDocuments] = useState<any[]>([]);
  const [selectedRequest, setSelectedRequest] = useState<any>({});
  const [business, setBusiness] = useState<any>({});
  const [openInvoicesAdder, setOpenInvoicesAdder] = useState(false);
  const [openDocumentAdder, setOpenDocumentAdder] = useState<any>(null);
  const [subProductSelected, setSubProductSelected] = useState<any>(null);
  const [subProduct, setSubProduct] = useState<any>(null);
  const [backwards, setBackwards] = useState(false);
  const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false);
  const [requestIdOnDelete, setRequestIdOnDelete] = useState<number | null>(
    null,
  );

  const setSuccessOpen = (text: string) => {
    setSuccess(true);
    setSuccesText(text);
  };

  const setFailureOpen = (text: string) => {
    setFailed(true);
    setFailedText(text);
  };

  const handleDetails = async (id: number) => {
    setLoading(true);
    try {
      const request = await getRecieverFactoringRequest(dispatch, {
        requestId: id,
      });
      setOpenRequest(request);
      setLoading(false);
      setShoppingCartType(request.status);
      setOpenShoppingCart(true);
    } catch (error) {
      setLoading(false);
      return alert('error');
    }
  };

  const canShowOpenDetails = (id: number) => {
    const request = factoringRequests.find((x: any) => x.id === id);
    if (!request) return alert('Ha ocurrido un error');

    return true;
  };

  const handleOpenDeleteConfirmModal = (id: number) => {
    console.log('id', id);
    setRequestIdOnDelete(id);
    setOpenDeleteConfirm(true);
  };

  const actions = [
    {
      action: handleDetails,
      actionLabel: 'Detalles',
      canShow: canShowOpenDetails,
    },
  ];
  const resetShoppingCart = () => {
    console.log('reset');
    if (continueToSimulation) {
      setContinueToSimulation(false);
      setContinueToSimulation(true);
    } else {
      setOpenShoppingCart(false);
      setOpenShoppingCart(true);
    }
  };

  const getFactoringRequests = async () => {
    if (profile) {
      return await getFactoringRequestsReceivedFiltered(dispatch, id as string);
    } else {
      return await getFactoringRequestsReceived(dispatch);
    }
  };

  const setUp = async () => {
    setLoading(true);
    setOpenShoppingCart(false);
    const factoringRequestsResponse = await getFactoringRequests();
    setFactoringRequests(factoringRequestsResponse || []);
    const factoringRequestsFiltered = factoringRequestsResponse.filter(
      (request: any) => {
        return filter.includes(request.status);
      },
    );
    setFilteredFactoringRequests(factoringRequestsFiltered || []);
    if (validatePermissions(user, 'businesses_read_all')) {
      if (
        dataHeadersDefault.find((x) => x.key === 'recipientName') === undefined
      ) {
        dataHeadersDefault.push({ key: 'recipientName', label: 'Ejecutivo' });
      }
    }
    setDataHeaders(dataHeadersDefault);

    setLoading(false);
  };

  useEffect(() => {
    setUp();
  }, []);

  const handleToSimulation = async () => {
    setLoading(true);
    try {
      setContinueToSimulation(true);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const buttonsData: CustomButtonProps[] = [
    {
      action: () => setOpenDocumentsSelector(true),
      name: 'Crear',
      color: 'primary',
      disabled: !validatePermissions(user, 'financing_factoring_create'),
    },
  ];

  const handleFilter = (values: string[]) => {
    if (values.includes('Todos') && !filter.includes('Todos')) {
      setFilteredFactoringRequests(factoringRequests);
      setFilter(['Todos']);
      return;
    } else {
      if (filter.includes('Todos')) {
        values = values.filter((value) => value !== 'Todos');
      }
      setFilter(values);
      const filtered = factoringRequests.filter((payout: any) => {
        return values.includes(payout.status);
      });
      setFilteredFactoringRequests(filtered);
    }
  };

  const Filter = (
    <FormControl fullWidth style={{ minWidth: '200px' }}>
      <InputLabel id="filter-label">Filtrar por estado</InputLabel>
      <Select
        labelId="filter-label"
        id="Filtrar por estado"
        fullWidth
        multiple
        value={filter}
        onChange={(e) => handleFilter(e.target.value as string[])}
        renderValue={(selected: any) => selected.join(', ')}
        label={'Filtrar por estado'}
      >
        <MenuItem value={'Todos'}>
          <Checkbox checked={filter.includes('Todos')} />
          <ListItemText primary="Todos" />
        </MenuItem>
        {statuses.map((status) => (
          <MenuItem value={status} key={status}>
            <Checkbox checked={filter.includes(status)} />
            <ListItemText primary={status} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );

  if (loading) return <CenteredCircularProgress />;

  return (
    <div>
      <HeaderWithActions
        title={
          profile
            ? 'Solicitudes'
            : 'Acá podrás ver todas tus solicitudes de factoring'
        }
        variant={profile ? 'h6' : 'body'}
        buttons={
          validateCompanyTypes(user, 'FinancingHub') && !profile
            ? buttonsData
            : []
        }
        item={Filter}
      />
      <EnhancedTable
        dataHeaders={dataHeaders}
        data={filteredFactoringRequests}
        rowsPerPageDefault={25}
        showActions
        actions={actions}
        exportToFile="xlsx"
      />
      {openShoppingCart && (
        <ShoppingDialogRecieved
          shoppingCartType={shoppingCartType}
          openShoppingCart={openShoppingCart}
          setOpenShoppingCart={setOpenShoppingCart}
          selectedRequest={openRequest}
          setSelectedRequest={setOpenRequest}
          setUp={setUp}
          setSuccessOpen={setSuccessOpen}
          setFailureOpen={setFailureOpen}
          setBackwards={setBackwards}
          resetShoppingCart={resetShoppingCart}
          handleOpenDeleteConfirmModal={handleOpenDeleteConfirmModal}
        />
      )}
      {openDocumentsSelector && (
        <DocumentSelector
          open={openDocumentsSelector}
          setOpen={setOpenDocumentsSelector}
          setSelectedDocuments={setSelectedDocuments}
          selectedDocuments={selectedDocuments}
          setNewOpenShoppingCart={setNewOpenShoppingCart}
          business={business}
          setBusiness={setBusiness}
          setDocumentAdder={setOpenDocumentAdder}
          setOpenInvoicesAdder={setOpenInvoicesAdder}
          setSubProductSelected={setSubProductSelected}
          subProductSelected={subProductSelected}
          setSubProduct={setSubProduct}
          backwards={backwards}
        />
      )}
      {openInvoicesAdder && (
        <AddInvoicesManually
          business={business}
          open={openInvoicesAdder}
          setOpen={setOpenInvoicesAdder}
          setOpenDocumentsSelector={setOpenDocumentsSelector}
          subProductSelected={subProductSelected}
          setSuccessOpen={setSuccessOpen}
        />
      )}
      {openDocumentAdder && (
        <AddDocumentsManually
          business={business}
          open={openDocumentAdder}
          setOpen={setOpenDocumentAdder}
          setOpenDocumentsSelector={setOpenDocumentsSelector}
          subProductSelected={subProductSelected}
          setSuccessOpen={setSuccessOpen}
        />
      )}
      {openNewShoppingCart && (
        <ShoppingCartFactoring
          business={business}
          setBackwards={setBackwards}
          backwards={backwards}
          selectedDocuments={selectedDocuments}
          setSelectedDocuments={setSelectedDocuments}
          setOpenShoppingcart={setNewOpenShoppingCart}
          openShoppingCart={openNewShoppingCart}
          setSuccess={setSuccess}
          setFailed={setFailed}
          exchangeDay={exchangeDay}
          setExchangeDay={setExchangeDay}
          setSuccesText={setSuccesText}
          setFailedText={setFailedText}
          setContinueToSimulation={handleToSimulation}
          setSelectedRequest={setSelectedRequest}
          subProductSelected={subProductSelected}
          subProduct={subProduct}
          setOpenDocumentsSelector={setOpenDocumentsSelector}
        />
      )}
      {continueToSimulation && (
        <ShoppingDialogRecieved
          setBackwards={setBackwards}
          backwards={backwards}
          openShoppingCart={continueToSimulation}
          setOpenShoppingCart={setContinueToSimulation}
          openDatesSelection={openNewShoppingCart}
          setOpenDatesSelection={setNewOpenShoppingCart}
          setSelectedDocuments={setSelectedDocuments}
          selectedRequest={selectedRequest}
          setSelectedRequest={setSelectedRequest}
          newRequest
          setSuccessOpen={setSuccessOpen}
          setFailureOpen={setFailureOpen}
          setUp={setUp}
          executiveCreating
          shoppingCartType="Revisada"
          businessId={business.id}
          subProduct={subProduct}
          setSubProduct={setSubProduct}
          resetShoppingCart={resetShoppingCart}
        />
      )}
      {openDeleteConfirm && (
        <FactoringRequestDeleteConfirmationModal
          open={openDeleteConfirm}
          setOpen={setOpenDeleteConfirm}
          requestId={requestIdOnDelete}
          setUp={setUp}
          setSuccessOpen={setSuccessOpen}
          setFailureOpen={setFailureOpen}
        />
      )}
      <CustomAlert
        setOpen={setSuccess}
        open={success}
        title="Exito"
        text={successText}
        severity="success"
      />
      <CustomAlert
        setOpen={setFailed}
        open={failed}
        title="Oops"
        text={failedMessage}
        severity="error"
      />
    </div>
  );
};

export default FactoringRequestsRecieved;

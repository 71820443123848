import React, { useState, useEffect, useContext } from 'react';
import { useDispatch } from 'react-redux';
import { createUser } from '../../../../../../lib/api';
import CenteredCircularProgress from '../../../../../../lib/designSystem/CircularProgress';
import { Dialog, DialogTitle, DialogContent, DialogActions, TextField, Alert, AlertTitle, Typography, FormControl, InputLabel, Select, MenuItem, SelectChangeEvent, Input, Checkbox, ListItemText } from '@mui/material';
import CustomButton from '../../../../../../lib/designSystem/Button';
import CustomAlert from '../../../../../../lib/designSystem/Alert';
import { validateParams } from '../../../../../../lib/functions/utils';
import { nullUserState } from '../..';
import HeaderWithActions from '../../../../../../lib/designSystem/HeaderWithActions';


interface User {
  id?: number;
  name: string;
  email?: string;
  permissionPolicy: string;
  password?: string
  permissionPolicyCode?: number
}
const CreateUserDialog = (props: any) => {
  const {
    handleCloseDialog,
    fetchUsers,
    setFailedMessage,
    setLoading,
    loading,
    roles,
    openAddDialog,
    setSuccessText,
    setSuccess,
  } = props
  const dispatch = useDispatch();
  const [failed, setFailed] =  useState(false)
  const [formErrorMessage, setFormErrorMessage] = useState('');
  const [userRoles, setUserRoles] = useState<any[]>([])
  const [selectedUser, setSelectedUser] = useState<User>(nullUserState);
  const handleSaveAddDialog = async() => {
    setLoading(true)
    if (!validateParams(selectedUser, [ 'email'], setFormErrorMessage)) return setLoading(false)
    const rolesIds = userRoles.map((roleName :any) => roles.find((role :any) => role.name === roleName).id)
    if (!rolesIds.length) {
      setLoading(false)
      return setFormErrorMessage('Debes seleccionar al menos un rol')
    }
    console.log('rolesIds', rolesIds)
    try {
      await createUser(
        dispatch,
        {
          email: selectedUser.email,
          rolesIds
        }
      )
    } catch (error) {
      setLoading(false)
      return alert('Email ya existe')
    }
    setSelectedUser(nullUserState)
    handleCloseDialog()
    await fetchUsers()
    setSuccess(true)
    setSuccessText('Se ha agregado el usuario. Ahora debes esperar a que confirme su invitación.')
  }
  

  const handleInputChange = (e: any) => {
    setSelectedUser(prev => ({ ...prev, [e.target.name]: e.target.value }));
  }

  const handleInputRolesChange = (event: any) => {
    const {
      target: { value },
    } = event;
    setUserRoles(
      value
    );
  }

  if (loading) return <CenteredCircularProgress />

  return (
    <div>
      <Dialog fullWidth open={openAddDialog} onClose={() => handleCloseDialog()}>
        <DialogTitle><HeaderWithActions title='Crear Usuario' type='dialogHeader'/></DialogTitle>
        <DialogContent > 
          <Typography variant="body1">Acá podrás invitar usuarios de tu empresa a ingresar a Pruff. Ellos tendrán que confirmar la invitación y crear su contraseña.</Typography>
          <TextField margin="dense" name='email' label="Email" fullWidth value={selectedUser.email} onChange={handleInputChange}/>
          <FormControl  style={{marginTop: 20}}fullWidth>
                <InputLabel id="demo-mutiple-checkbox-label">Permisos</InputLabel>
                <Select
                  labelId="demo-mutiple-checkbox-label"
                  id="demo-mutiple-checkbox"
                  multiple
                  value={userRoles}
                  name='rolesNames'
                  onChange={handleInputRolesChange}
                  input={<Input />}
                  renderValue={(selected) => selected.join(', ')}
                >
                  {roles.map((role: any) => (
                    <MenuItem key={role.id} value={role.name}>
                      <Checkbox checked={userRoles.indexOf(role.name) > -1} />
                      <ListItemText primary={role.name} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
          <Typography variant="body1">{formErrorMessage}</Typography>
        </DialogContent>
        <DialogActions>
          <CustomButton color='primary'  onClick={handleSaveAddDialog}>Invitar</CustomButton>
          <CustomButton onClick={() => handleCloseDialog()} color="secondary">
            Salir
          </CustomButton>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default CreateUserDialog;
import { DialogContent, Grid, Typography } from '@mui/material';
import React, { useState, useEffect } from 'react';
import HeaderWithActions from '../../../../../../../../../lib/designSystem/HeaderWithActions';
import EnhancedTable from '../../../../../../../../../lib/designSystem/Table';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import TextField from '@mui/material/TextField';
import MultiUploader from '../../../../../../../../../lib/designSystem/MultiUploader';
import {
  executeDecisionEngine,
  getCompanySelectedDecisionEngine,
  getSelectedDecisionEngine,
} from '../../../../../../../../../lib/api';
import { useDispatch, useSelector } from 'react-redux';
import CustomButton from '../../../../../../../../../lib/designSystem/Button';
import CenteredCircularProgress from '../../../../../../../../../lib/designSystem/CircularProgress';
import { CheckCircle } from '@mui/icons-material';
import { validateCompanyTypes } from '../../../../../../../../../lib/functions/utils';
import { AuthState } from '../../../../../../../../../services/redux/authSlice';
import { all } from 'axios';

const Step2 = (props: any) => {
  const { requestInvoices, setDisabledSimulator } = props;
  const [selctedEngine, setSelectedEngine] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const [uploadedFileName, setUploadedFileName] = useState<string | null>(null);
  const [evaluated, setEvaluated] = useState(false);
  const [successEvaluation, setSuccessEvaluation] = useState(false);
  const dispatch = useDispatch();
  const user = useSelector((state: { auth: AuthState }) => state.auth.user);
  const [invoicesForEvaluation, setInvoicesForEvaluation] =
    useState<any[]>(requestInvoices);

  const getSelectedEngine = async () => {
    setLoading(true);
    try {
      let response;
      if (validateCompanyTypes(user, 'Accountant')) {
        // response = await getCompanySelectedDecisionEngine(dispatch, {
        //   clientId: user?.clientId,
        // });
        setDisabledSimulator(false);
      } else {
        response = await getSelectedDecisionEngine(dispatch, {
          revisionObjective: 'invoice',
        });
      }
      console.log('response', response);
      if (!response) {
        setDisabledSimulator(false);
      }
      setSelectedEngine(response);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  // const checkEvaluation = (invoices: any) => {
  //   console.log('invoicesForEvaluation', invoices);
  //   const decision = invoices.filter(
  //     (invoice: any) => invoice.decision === 'Rechazado',
  //   );
  //   const approved = invoices.filter(
  //     (invoice: any) => invoice.decision === 'Aprobado',
  //   );
  //   if (approved.length === invoices.length) {
  //     setReviewed(true);
  //   }
  //   if (decision.length === 0) {
  //     setSuccessEvaluation(true);
  //     setDisabledSimulator(false);
  //   }
  //   setEvaluated(true);
  // };

  // const executeDE = async () => {
  //   setLoading(true);
  //   try {
  //     const response = await executeDecisionEngine(dispatch, {
  //       decisionEngineId: selctedEngine.id,
  //       metadata: {
  //         invoices: requestInvoices,
  //         clientId: user?.clientId,
  //       },
  //     });
  //     setInvoicesForEvaluation(response);
  //     setInvoicesForEvaluation(response);
  //     checkEvaluation(response);
  //     setLoading(false);
  //   } catch (error) {
  //     console.log(error);
  //     setLoading(false);
  //   }
  // };

  useEffect(() => {
    setDisabledSimulator(false);
    // getSelectedEngine();
  }, []);

  if (loading) return <CenteredCircularProgress type="dialog" />;
  return (
    <DialogContent>
      <Typography variant="body1">
        Estas son los documenyos junto a sus fechas de pago.
      </Typography>
      <div style={{ padding: '20px 0px' }}>
        <EnhancedTable
          dataHeaders={[
            { key: 'folio', label: 'Folio' },
            { key: 'stakeholderName', label: 'Nombre receptor' },
            { key: 'amount', label: 'Monto', type: 'money' },
            { key: 'issuedDate', label: 'Fecha emisión', type: 'date' },
            { key: 'paymentDate', label: 'Fecha de pago', type: 'date' },
            ...(evaluated
              ? [{ key: 'decision', label: 'Decisión', type: 'decision' }]
              : []),
          ]}
          data={invoicesForEvaluation}
          rowsPerPageDefault={5}
          finalSumRow
        />
      </div>
      {/* {selctedEngine && (
        <Grid container alignItems={'center'}>
          <Grid item xs={8}>
            <Typography variant="body1">
              <b>Importante</b>
            </Typography>
            <Typography variant="body1">
              {user?.express
                ? 'Para cotizar, tu operación debe ser preevaluada por nuestro motor de decisión.  Esto no significa que tu operación está aprobada, sino que tus facturas están listas para ser cotizadas.'
                : 'Hemos detectado que tu cuenta posee un motor de decisión seleccionado, por lo que las facturas deben ser evaluadas por este motor antes de enviar la cotización.'}
            </Typography>
          </Grid>
          <Grid item xs={4} textAlign={'right'}>
            <CustomButton
              disabled={evaluated}
              onClick={executeDE}
              color="primary"
            >
              Evaluar
            </CustomButton>
          </Grid>
        </Grid>
      )} */}
      {evaluated && (
        <div style={{ padding: '20px 0px' }}>
          {successEvaluation ? (
            <div style={{ alignItems: 'center', display: 'flex' }}>
              <Typography variant="body1" color="success">
                Todas las facturas han sido pre-aprobadas por el motor de
                decisión.
              </Typography>
              <CheckCircle style={{ color: '#3bc14a' }} />
            </div>
          ) : (
            <Typography variant="body1" color="error">
              Algunas facturas han sido rechazadas por el motor de decisión.
            </Typography>
          )}
        </div>
      )}
    </DialogContent>
  );
};

export default Step2;

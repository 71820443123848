import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import EnrolationForm from './pages/PublicLandings/EnrolationForm';
import LoginForm from './pages/LoginForm';
import LoginFormExpress from './pages/LoginForm/Express/LogInExpress';
import SigninExpress from './pages/LoginForm/Express/SignInExpress';
import ConfirmUserRegistration from './pages/PublicLandings/ConfirmUseRegistration';
import PasswordRecoveryExpress from './pages/LoginForm/Express/PasswordRecovery';
import PasswordRecoveryLanding from './pages/PublicLandings/PasswordRecovery';
import ConfirmQuote from './pages/PublicLandings/ConfirmQuote';
import ConfirmUserRegistrationAdmin from './pages/PublicLandings/ConfirmUseRegistrationAdmin';
import PasswordRecoveryMain from './pages/LoginForm/Main/PasswordRecovery';
import PaymentLink from './pages/PublicLandings/PaymentLink';

import InvoiceInformation from './pages/PublicLandings/PaymentLink/components/InvoiceInformation';
import ExpressLayout from './layouts/ExpressLayout';

const AppRoutes: React.FC = () => {
  return (
    <Router>
      <Routes>
        <Route path="/submitCredentials/:id" element={<EnrolationForm />} />
        <Route
          path="/express/:clientId"
          element={
            <ExpressLayout>
              <LoginFormExpress />
            </ExpressLayout>
          }
        />
        <Route
          path="/express/:clientId/sign-in"
          element={
            <ExpressLayout>
              <SigninExpress />
            </ExpressLayout>
          }
        />
        <Route
          path="/express/:clientId/password-recovery"
          element={
            <ExpressLayout>
              <PasswordRecoveryExpress />
            </ExpressLayout>
          }
        />
        <Route
          path="/express/:clientId/*"
          element={
            <ExpressLayout>
              <LoginFormExpress />
            </ExpressLayout>
          }
        />
        <Route path="/password-recovery" element={<PasswordRecoveryMain />} />

        <Route
          path="/confirmRegistration/:code"
          element={<ConfirmUserRegistration />}
        />
        <Route
          path="/confirmRegistration/createPassword/:code"
          element={<ConfirmUserRegistrationAdmin />}
        />
        <Route
          path="/passwordRecovery/:code"
          element={<PasswordRecoveryLanding />}
        />
        <Route path="/factoring/:code" element={<ConfirmQuote />} />
        <Route
          path="/payment/:encryptedCompanyId/:encryptedBusinessIdentifier"
          element={<PaymentLink />}
        />
        <Route
          path="/payment/:encryptedCompanyId/:encryptedBusinessIdentifier/:invoiceId"
          element={<InvoiceInformation />}
        />
        <Route path="/*" element={<LoginForm />} />
      </Routes>
    </Router>
  );
};

export default AppRoutes;

import React from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom';
import BusinessInvoices from './pages/BusinessProfile/SII/Components/BusinessInvoices';
import { RootState } from './services/redux/store';
import { useSelector } from 'react-redux';
import AutoEnrollment from './pages/AutoEnrollment';
import { Typography } from '@mui/material';
import TopBar from './layouts/ExpressLayout';
import FactoringRequestsSent from './pages/Finance/Components/Financing/Factoring/components/Sent';

const AppRoutes: React.FC = () => {
  const auth = useSelector((state: RootState) => state.auth);

  const ShortLayout = (props: any) => {
    const { children } = props;
    return (
      <div>
        <TopBar>{children}</TopBar>
      </div>
    );
  };
  if (!auth.user?.clientId)
    return (
      <Typography variant="h1">
        No se ha encontrado el cliente. Reinicia tu navegador
      </Typography>
    );

  if (!auth.user?.autoEnrrolled) {
    return (
      <Router>
        <Routes>
          <Route
            path="/express/:clientId/autoEnrollment"
            element={<AutoEnrollment />}
          />
          <Route
            path="*"
            element={
              <Navigate
                to={`/express/${auth.user?.clientId}/autoEnrollment`}
                replace
              />
            }
          />
        </Routes>
      </Router>
    );
  }

  return (
    <div>
      <Router>
        <Routes>
          <Route
            path="/express/:clientId/factoring/invoices"
            element={
              <ShortLayout>
                <BusinessInvoices />
              </ShortLayout>
            }
          />
          <Route
            path="/express/:clientId/factoring/requests"
            element={
              <ShortLayout>
                <FactoringRequestsSent />
              </ShortLayout>
            }
          />
          <Route
            path="*"
            element={
              <Navigate
                to={`/express/${auth.user?.clientId}/factoring/invoices`}
                replace
              />
            }
          />
        </Routes>
      </Router>
    </div>
  );
};

export default AppRoutes;

import React, { useState } from 'react';
import { Dialog, DialogActions } from '@mui/material';
import EnhancedTab from '../../../lib/designSystem/TabSimulation';
import CustomButton from '../../../lib/designSystem/Button';
import SettingsCertificate from './components/Certificate';
import CustomAlert from '../../../lib/designSystem/Alert';
import SettingsVission from './components/Vission';
import BankAccounts from '../../BusinessProfile/Profile/components/BankAccounts';

const SettingsDialog = (props: any) => {
  const { open, setOpen } = props;
  const [startValue, setStartValue] = useState(0);
  const [openAlertSuccess, setOpenAlertSuccess] = useState('');
  const [openAlertFailed, setOpenAlertFailed] = useState('');

  const tabs = [
    {
      index: 0,
      label: 'Visión',
      component: (
        <SettingsVission
          setSuccess={setOpenAlertSuccess}
          setFailure={setOpenAlertFailed}
        />
      ),
    },
    {
      index: 1,
      label: 'Certificados',
      component: <SettingsCertificate />,
    },
    {
      id: 2,
      label: 'Banco',
      component: <BankAccounts />,
      path: 'bankAccounts',
    },
  ];
  return (
    <Dialog maxWidth="lg" fullWidth open={open} onClose={() => setOpen(false)}>
      <div style={{ padding: 20, minHeight: 200 }}>
        <EnhancedTab
          title={`Configuración`}
          startValue={startValue}
          tabs={tabs}
        />
      </div>
      <DialogActions>
        <CustomButton color="secondary" onClick={() => setOpen(false)}>
          {' '}
          Salir{' '}
        </CustomButton>
      </DialogActions>
      <CustomAlert
        open={openAlertSuccess.length}
        title="Éxito"
        text={openAlertSuccess}
        setOpen={setOpenAlertSuccess}
      />
      <CustomAlert
        open={openAlertFailed.length}
        title="Error"
        text={openAlertFailed}
        setOpen={setOpenAlertFailed}
      />
    </Dialog>
  );
};

export default SettingsDialog;

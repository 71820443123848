import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Users from './Components/Assiginated';
import EnhancedTab from '../../lib/designSystem/TabSimulation';
import { useNavigate } from 'react-router-dom';
import { validatePermissions } from '../../lib/functions/utils';
import { useSelector } from 'react-redux';
import { AuthState } from '../../services/redux/authSlice';
import FinancialStatementsSettings from './Components/Pending';
import FinancialStatementsPending from './Components/Pending';
import UsagesSummary from '../Settings/Components/FinancialStatements/Usage';
import AccountsSettings from '../Settings/Components/FinancialStatements/AccountsSettings';
import FinancialStatementsDone from './Components/Done';
import CenteredCircularProgress from '../../lib/designSystem/CircularProgress';

const companyTabs = [
  {
    label: 'Métricas',
    component: <UsagesSummary />,
    path: 'summary',
    index: 0,
  },
  {
    label: 'Pendientes',
    component: <FinancialStatementsPending />,
    path: 'pending',
    index: 1,
  },
  {
    label: 'Asignados',
    component: <FinancialStatementsDone />,
    path: 'done',
    index: 2,
  },
  {
    label: 'Configuración',
    component: <AccountsSettings />,
    path: 'settings',
    index: 3,
  },
];

const FinancialStatementsEmptyer: React.FC = () => {
  const { page } = useParams<{ page: string }>();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [compnayTabs, setCompanyTabs] = useState<any>(companyTabs);
  const [startValue, setStartValue] = useState(0);

  const handleNavigation = (index: number) => {
    navigate(`/financialStatements/${compnayTabs[index].path}`);
  };
  const user = useSelector((state: { auth: AuthState }) => state.auth.user);
  console.log(page);

  const setUp = async () => {
    setLoading(true);

    let newCompanyTabs = [...companyTabs];

    if (!validatePermissions(user, 'financial_states_update_configuration')) {
      // Remove the 'Configuración' tab if the user doesn't have the required permission
      newCompanyTabs = newCompanyTabs.filter(
        (tab) => ![0, 3].includes(tab.index),
      );
    }

    setCompanyTabs(newCompanyTabs); // Update the state with the new array

    let startValueDefault = newCompanyTabs.findIndex(
      (tab) => tab.path === page,
    );
    if (startValueDefault === -1) startValueDefault = 0;

    setStartValue(startValueDefault);
    setLoading(false);
  };

  useEffect(() => {
    setUp();
  }, []);

  if (loading) return <CenteredCircularProgress type="layout" />;

  return (
    <div>
      <EnhancedTab
        title="Mis balances"
        startValue={startValue}
        tabs={compnayTabs}
        onTabChange={handleNavigation}
      />
    </div>
  );
};

export default FinancialStatementsEmptyer;

import React, { CSSProperties, ReactNode } from 'react';
import { Button } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useSelector } from 'react-redux';
import { ClientConfigState } from '../../../services/redux/clientConfigSlice';

type ButtonColor =
  | 'inherit'
  | 'primary'
  | 'secondary'
  | 'transparent'
  | 'success'
  | 'error'
  | 'info'
  | 'warning';

interface CustomButtonProps {
  label?: string;
  onClick?: (e?: any) => void | Promise<void>;
  color?: ButtonColor;
  style?: CSSProperties;
  size?: 'small' | 'medium' | 'large';
  key?: string | number;
  children?: ReactNode;
  fullWidth?: boolean;
  disabled?: boolean;
  variant?: 'text' | 'outlined' | 'contained';
  startIcon?: ReactNode;
  component?: React.ElementType;
}

const disabledButtonStyles: CSSProperties = {
  opacity: 0.5,
  filter: 'grayscale(100%)',
  cursor: 'not-allowed',
};

const baseButtonStyles: CSSProperties = {
  display: 'inline-flex',
  padding: '6px 16px',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '8px',
  flexShrink: 0,
  borderRadius: '20px',
};

const buttonColors = {
  primary: {
    color: 'white',
    border: '1px solid #0F23DF',
    background: 'linear-gradient(60deg, #0F23DF 0.2%, #0F23DF 99.99%)',
  },
  secondary: {
    color: '#0F23DF',
    border: '1px solid #0F23DF',
    background: '#ffffff',
  },
  error: {
    color: '#ffffff',
    border: '1px solid #D62839',
    background: '#D62839',
  },
  warning: {
    color: '#ffffff',
    border: '1px solid #E8C547',
    background: '#E8C547',
  },
  success: {
    color: '#ffffff',
    border: '1px solid #2ECC71',
    background: '#2ECC71',
  },
  transparent: {
    color: '#0F23DF',
    border: '1px solid #0F23DF',
    background: 'transparent',
  },
};

const StyledButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'btnColor',
})<{ btnColor: keyof typeof buttonColors; customPrimaryColor?: string }>(
  ({ btnColor, customPrimaryColor }) => ({
    ...baseButtonStyles,
    ...(btnColor === 'primary'
      ? {
          color: 'white',
          border: `1px solid ${customPrimaryColor || '#0F23DF'}`,
          background: `linear-gradient(60deg, ${
            customPrimaryColor || '#0F23DF'
          } 0.2%, ${customPrimaryColor || '#0F23DF'} 99.99%)`,
        }
      : btnColor === 'secondary'
        ? {
            color: customPrimaryColor || '#0F23DF',
            border: `1px solid ${customPrimaryColor || '#0F23DF'}`,
            background: '#ffffff',
          }
        : buttonColors[btnColor]),
    '&:disabled': {
      ...disabledButtonStyles,
      color:
        btnColor === 'secondary'
          ? customPrimaryColor
          : buttonColors[btnColor].color,
    },
  }),
);

const CustomButton: React.FC<CustomButtonProps> = (props) => {
  const { color = 'primary', children, ...otherProps } = props;

  // Get expressConfig from Redux
  const expressConfig = useSelector(
    (state: { clientConfiguration: ClientConfigState }) =>
      state.clientConfiguration.configs,
  );

  // Get custom primary color from expressConfig if available
  const customPrimaryColor = expressConfig?.pruffConnect_styles_mainColor;

  return (
    <StyledButton
      btnColor={color === 'inherit' || color === 'info' ? 'primary' : color}
      customPrimaryColor={
        color === 'primary' || color === 'secondary'
          ? customPrimaryColor
          : undefined
      }
      {...otherProps}
    >
      {children}
    </StyledButton>
  );
};

export default CustomButton;

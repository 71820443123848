import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import CenteredCircularProgress from '../../lib/designSystem/CircularProgress';
import { AuthState } from '../../services/redux/authSlice';
import DashboardADC from './components/DashboardADC';
import EnhancedTab from '../../lib/designSystem/TabSimulation';
import {
  validateCompanyTypes,
  validatePermissions,
} from '../../lib/functions/utils';
import InvoicesNotConfirmed from './components/common/InvoicesNotConfirmed';
import SiiResponsabilities from './components/common/SIIResponsabilities';
import TGRGrouped from './components/common/TGRGrouped';
import LastBaknrruptcyPosts from './components/common/LastBaknrruptcyPosts';
import { setInitialTab } from '../../lib/functions/setInitialTab';
import Prospects from './components/common/Prospects';
import AllInvoices from './components/common/AllInvoices';
import LastInvoices from './components/common/LastInvoices';
import MyWallet from './components/Factoring/Wallet';

interface Tabs {
  mainPage: string;
  label: string;
  component: any;
}

const Home: React.FC = () => {
  const { page } = useParams<{
    id: string;
    businessId: string;
    page: string;
  }>();
  const [loading, setLoading] = useState(false);
  const user = useSelector((state: { auth: AuthState }) => state.auth.user);
  const [companyTabs, setCompanyTabs] = useState<Tabs[]>([]);
  const [startValue, setStartValue] = useState<any>(null);

  useEffect(() => {}, []);

  const adcTabs = [
    { label: 'Dashboard', component: <DashboardADC />, mainPage: 'dashboard' },
    {
      label: 'Responsabilidades SII',
      component: <SiiResponsabilities />,
      mainPage: 'siiResponsabilities',
    },
    {
      label: 'Facturas por confirmar',
      component: <InvoicesNotConfirmed />,
      mainPage: 'notConfirmed',
    },
    { label: 'TGR', component: <TGRGrouped />, mainPage: 'tgr' },
    {
      label: 'Boletín Concursal',
      component: <LastBaknrruptcyPosts />,
      mainPage: 'bankruptcy',
    },
  ];

  const factoringTabs: any = {
    dashboard: {
      label: 'Cartera',
      component: <MyWallet />,
      mainPage: 'wallet',
    },
    invoices: {
      label: 'Facturas',
      component: (
        <div>
          <LastInvoices />
          <AllInvoices />
        </div>
      ),
      mainPage: 'lastInvoices',
    },
    tgr: { label: 'TGR', component: <TGRGrouped />, mainPage: 'tgr' },
    prospects: {
      label: 'Prospectos',
      component: <Prospects />,
      mainPage: 'prospects',
    },
    bankruptcy: {
      label: 'Boletín Concursal',
      component: <LastBaknrruptcyPosts />,
      mainPage: 'bankruptcy',
    },
  };

  const navigate = useNavigate();

  const handleNavigation = (index: number) => {
    navigate(`/home/${companyTabs[index].mainPage}`);
  };

  const setUpHome = async () => {
    setLoading(true);
    let newCompanyTabs: any = [];
    if (validateCompanyTypes(user, 'Accountant')) {
      newCompanyTabs = adcTabs;
    }
    if (
      validateCompanyTypes(user, 'FactoringSII') ||
      validateCompanyTypes(user, 'FactoringQuoting') ||
      validateCompanyTypes(user, 'FactoringFS')
    ) {
      if (validatePermissions(user, 'sii_read')) {
        newCompanyTabs.push(factoringTabs.dashboard);
        newCompanyTabs.push(factoringTabs.invoices);
      }
      if (validatePermissions(user, 'tgr_read')) {
        newCompanyTabs.push(factoringTabs.tgr);
      }
      if (validatePermissions(user, 'prospects_read')) {
        newCompanyTabs.push(factoringTabs.prospects);
      }
      newCompanyTabs.push(factoringTabs.bankruptcy);
    }
    setInitialTab(setCompanyTabs, setStartValue, page, newCompanyTabs, 'home');
    setLoading(false);
  };

  useEffect(() => {
    setUpHome();
  }, []);

  if (loading) return <CenteredCircularProgress />;

  return (
    <div>
      <EnhancedTab
        title={`Hola ${user?.name}!`}
        startValue={startValue}
        tabs={companyTabs}
        onTabChange={handleNavigation}
      />
    </div>
  );
};

export default Home;

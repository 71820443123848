import { requestHandler } from '../../api';

export const getActiveMoneyReports = async (dispatch: any) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/report/activeMoney`,
    dispatch,
  });
  if (response) return response.data;
};

export const getCurseBookReport = async (
  dispatch: any,
  startDate: string,
  endDate: string,
) => {
  const response = await requestHandler({
    method: 'post',
    url: `internal/report/curseBook`,
    data: { startDate, endDate },
    dispatch,
  });
  if (response) return response.data;
};

export const getIncomeBookReport = async (
  dispatch: any,
  startDate: string,
  endDate: string,
) => {
  const response = await requestHandler({
    method: 'post',
    url: `internal/report/incomeBook`,
    data: { startDate, endDate },
    dispatch,
  });
  if (response) return response.data;
};

export const getInvoicingBookReport = async (
  dispatch: any,
  startDate: string,
  endDate: string,
) => {
  const response = await requestHandler({
    method: 'post',
    url: `internal/report/invoicingBook`,
    data: { startDate, endDate },
    dispatch,
  });
  if (response) return response.data;
};

import React, { useEffect } from 'react';
import { Divider, IconButton, TextField, Typography } from '@mui/material';
import {
  downloadFileFromFactoringRequests,
  downloadFilesFromRequest,
  getFilesFromRequest,
  updateFactoringFileName,
  updateFileNameFromRequest,
  uploadFilesFromRequest,
} from '../../../../../../lib/api';
import { useDispatch } from 'react-redux';
import GetAppIcon from '@mui/icons-material/GetApp';
import CenteredCircularProgress from '../../../../../../lib/designSystem/CircularProgress';
import HeaderWithActions, {
  CustomButtonProps,
} from '../../../../../../lib/designSystem/HeaderWithActions';
import MultiUploader from '../../../../../../lib/designSystem/MultiUploader';
import { Cancel, Edit, Save } from '@mui/icons-material';
import { useParams } from 'react-router-dom';

const FileItem = (props: any) => {
  const { files, file, handleDownload, index, changeFileName } = props;
  const [editMode, setEditMode] = React.useState(false);
  const [newName, setNewName] = React.useState(file);
  return (
    <div>
      <div key={file}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          {editMode ? (
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Nuevo nombre"
              type="text"
              fullWidth
              value={newName}
              onChange={(e) => setNewName(e.target.value)}
            />
          ) : (
            <Typography variant="subtitle1" gutterBottom component="div">
              - {file}
            </Typography>
          )}
          <div style={{ display: 'flex' }}>
            {editMode && (
              <IconButton>
                <Save onClick={() => changeFileName(file, newName)} />
              </IconButton>
            )}
            {editMode && (
              <IconButton onClick={() => setEditMode(false)}>
                <Cancel />
              </IconButton>
            )}
            {!editMode && (
              <IconButton onClick={() => setEditMode(file)}>
                <Edit />
              </IconButton>
            )}
            {!editMode && (
              <IconButton onClick={() => handleDownload(file)}>
                <GetAppIcon />
              </IconButton>
            )}
          </div>
        </div>

        {index !== files.length - 1 && <Divider />}
      </div>
    </div>
  );
};

const FilesDialog = (props: any) => {
  const { openRequest, setSuccessOpen, setFailureOpen, requestType } = props;

  const dispatch = useDispatch();
  const [loading, setLoading] = React.useState(false);
  const [files, setFiles] = React.useState([]);
  const [addFilesMode, setAddFilesMode] = React.useState(false);
  const [filesAdded, setFilesAdded] = React.useState<any>([]);

  const handleDownload = async (fileName: any) => {
    setLoading(true);
    try {
      const data = {
        requestId: openRequest.id,
        fileName: fileName,
        requestType,
      };
      const response = await downloadFilesFromRequest(dispatch, data);
      if (response) {
        window.open(response);
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const changeFileName = async (oldName: string, newName: string) => {
    setLoading(true);
    try {
      const data = {
        companyId: openRequest.companyReceiverId,
        oldName,
        newName,
        requestType,
        requestId: openRequest.id,
      };
      await updateFileNameFromRequest(dispatch, data);
      await setUpFiles();
      setSuccessOpen('Se ha modificado el nombre de tu archivo con éxito');
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const uploadFiles = async () => {
    setLoading(true);
    try {
      const formData = new FormData();
      filesAdded.forEach((file: any) => formData.append('files', file));
      await uploadFilesFromRequest(dispatch, formData, {
        requestId: openRequest.id,
        requestType,
      });
      await setUpFiles();
      setAddFilesMode(false);
      setSuccessOpen('Se han subido tus archivos con éxito');
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const setUpFiles = async () => {
    setLoading(true);
    console.log(requestType);
    try {
      const response = await getFilesFromRequest(dispatch, {
        requestId: openRequest.id,
        requestType,
      });
      setFiles(response);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    setUpFiles();
  }, [openRequest]);

  const buttonsData: CustomButtonProps[] = [
    {
      action: () => setAddFilesMode(true),
      name: 'Agregar archivos',
      color: 'primary',
    },
  ];

  const addButtonsData: CustomButtonProps[] = [
    {
      action: () => setAddFilesMode(false),
      name: 'Cancelar',
      color: 'secondary',
    },
    {
      action: () => uploadFiles(),
      name: 'Guardar',
      disabled: filesAdded.length === 0,
      color: 'primary',
    },
  ];

  const handleChangeFile = (event: any) => {
    const newFiles = Array.from(event.target.files);
    setFilesAdded(newFiles); // Pass the file list to the parent component
  };

  return (
    <div style={{ minHeight: 200 }}>
      {loading ? (
        <CenteredCircularProgress type="dialog" />
      ) : (
        <div>
          <HeaderWithActions
            title={
              addFilesMode
                ? 'A continuación puedes subir archivos que te hagan falta, no olvides guardar'
                : 'Aquí se encuentran todos los archivos de la solicitud.'
            }
            variant="body1"
            buttons={addFilesMode ? addButtonsData : buttonsData}
          />
          {addFilesMode ? (
            <div>
              <div style={{ padding: '28px 0px' }}>
                <MultiUploader
                  buttonText="Agregar"
                  handleChange={handleChangeFile}
                  uploadedFileNames={filesAdded.map((x: any) => x.name)}
                  textLeft="Archivos subidos:"
                />
              </div>
            </div>
          ) : (
            <Typography padding={2} paragraph>
              {files.length === 0 && (
                <Typography> No hay archivos subidos. </Typography>
              )}
              {files.map((file: any, index: number) => (
                <FileItem
                  key={index}
                  file={file}
                  files={files}
                  changeFileName={changeFileName}
                  handleDownload={handleDownload}
                />
              ))}
            </Typography>
          )}
        </div>
      )}
    </div>
  );
};

export default FilesDialog;

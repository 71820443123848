// LoginForm.tsx
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { authenticate } from '../../../services/redux/authSlice'; // Import the action
import {
  authenticateUser,
  confirmUserRegistrationCode,
  getUserProfile,
} from '../../../lib/api';
import Avatar from '@mui/material/Avatar';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Container from '@mui/material/Container';
import CenteredCircularProgress from '../../../lib/designSystem/CircularProgress';
import { useSelector } from 'react-redux';
import { RootState } from '../../../services/redux/store';
import HeaderWithActions from '../../../lib/designSystem/HeaderWithActions';
import { Divider, IconButton, Paper, Theme, Typography } from '@mui/material';
import EnhancedBox from '../../../lib/designSystem/BigBox';
import { makeStyles, createStyles } from '@mui/styles';
import CopyIcon from '@mui/icons-material/FileCopy'; // You can choose hanother appropriate icon
import { useParams } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    avatarSection: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%', // This will ensure the div takes the full height of its parent container
    },
    avatar: {
      width: '180px !important',
      height: '180px !important',
    },
    userInfoSection: {
      padding: '32px',
      textAlign: 'center',
    },
    pairSection: {
      padding: '10px',
    },
  }),
);

const ConfirmUserRegistration = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const auth = useSelector((state: RootState) => state.auth);
  const { code } = useParams<{ code: string }>();
  const [error, setError] = useState(false);
  const [clientId, setClientId] = useState('');
  const confirmCodeUser = async () => {
    try {
      setLoading(true);
      const userResponse = await confirmUserRegistrationCode({ code });
      console.log(userResponse);
      if (!userResponse.success) {
        setError(true);
      }
      setTimeout(() => {
        window.location.href = `/express/${userResponse.clientId}`;
      }, 2000);
      setClientId(userResponse.clientId);
    } catch (error) {
      setError(true);
    }

    setLoading(false);
  };
  useEffect(() => {
    confirmCodeUser();
  }, []);

  if (loading) return <CenteredCircularProgress />;

  if (error) {
    return (
      <div style={{ margin: 'auto', maxWidth: '40%', padding: 40 }}>
        <Typography variant="h4">Error</Typography>
        <Typography variant="h6">
          El código ingresado no tiene validez
        </Typography>
      </div>
    );
  }

  return (
    <div>
      {/* <HeaderWithActions variant='h4' subHeader buttons={[]} title='Confirmación de registro' /> */}
      <EnhancedBox>
        <Typography variant="h5">¡Bienvenido a Pruff!</Typography>
        <Typography variant="h6">Tu registro ha sido confirmado</Typography>
        <Typography variant="h6">
          Ya puedes{' '}
          {clientId && clientId.length ? (
            <a href={`/express/${clientId}`}>iniciar sesión</a>
          ) : (
            'iniciar sesión'
          )}
        </Typography>
      </EnhancedBox>
    </div>
  );
};

export default ConfirmUserRegistration;

import React, { useEffect, useState } from 'react';

import {
  Typography,
  Grid,
  TextField,
  InputAdornment,
  CircularProgress,
  IconButton,
} from '@mui/material';

import { useDispatch } from 'react-redux';
import { getPayInConciliationSuggestions } from '../../../../../../../../../../lib/api';
import {
  formatDate,
  formatMoney,
} from '../../../../../../../../../../lib/functions/utils';
import { Info, SettingsBackupRestore } from '@mui/icons-material';
import { CustomTooltip } from '../../../../../../../../../../lib/designSystem/TooltipTable';
import { LightTooltip } from '../../../../../../../../../../lib/designSystem/WhiteTooltip';

const PaymentConciliationAssginationSuggested = (props: any) => {
  const { payIn, setDocumentsSuggested, documentsSuggested } = props;

  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const setUp = async () => {
    setLoading(true);
    try {
      const response = await getPayInConciliationSuggestions(dispatch, {
        id: payIn.id,
      });
      setDocumentsSuggested(response.documentsProcessed);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    setUp();
  }, []);

  const handleSelectAmount = (value: any, document: any) => {
    const amount = value.split('.').join('').split('$').join('');
    if (isNaN(amount)) return;
    const newInvoice = { ...document, amountToConciliate: Number(amount) };
    const newInvoices = documentsSuggested.map((doc: any) =>
      doc.id === document.id ? newInvoice : doc,
    );
    setDocumentsSuggested(newInvoices);
  };

  return (
    <div>
      <div
        style={{
          padding: '10px 0px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Typography variant="h6" padding="10px 0px" fontWeight={600}>
          Conciliaciones sugeridas
        </Typography>
        <Typography variant="body1" fontWeight={600}>
          Total a conciliar:{' '}
          {formatMoney(
            documentsSuggested.reduce(
              (acc: any, inv: any) => acc + (inv.amountToConciliate || 0),
              0,
            ),
          )}
        </Typography>
      </div>
      {loading && <CircularProgress />}
      {!loading && (
        <div>
          {documentsSuggested.length ? (
            <Grid container spacing={2}>
              <Grid item xs={1}>
                <Typography variant="body1" fontWeight={600}>
                  Folio
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <Typography variant="body1" fontWeight={600} textAlign="right">
                  Monto neto
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Grid container spacing={2}>
                  <Grid item xs={2}>
                    <Typography
                      variant="body1"
                      fontWeight={600}
                      textAlign="right"
                    >
                      Mora total
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography
                      variant="body1"
                      fontWeight={600}
                      textAlign="right"
                    >
                      Monto total
                    </Typography>
                  </Grid>

                  <Grid item xs={2}>
                    <Typography
                      variant="body1"
                      fontWeight={600}
                      textAlign="right"
                    >
                      Abonado Doc.
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography
                      variant="body1"
                      fontWeight={600}
                      textAlign="right"
                    >
                      Abonado mora
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography
                      variant="body1"
                      fontWeight={600}
                      textAlign="right"
                    >
                      Abonado total
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography
                      variant="body1"
                      fontWeight={600}
                      textAlign="right"
                    >
                      Deuda
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item xs={2}>
                <Typography variant="body1" fontWeight={600} textAlign="right">
                  Monto a conciliar
                </Typography>
              </Grid>
            </Grid>
          ) : (
            <Typography variant="body1">No hay facturas sugeridas</Typography>
          )}
          {documentsSuggested.map((document: any) => {
            const tooltipTitleValues = [
              {
                name: 'Monto',
                value: formatMoney(document.amount),
              },
              {
                name: 'Fuente',
                value: document.type,
              },
              {
                name: 'Deudor',
                value: document.stakeholderName,
              },
              {
                name: 'Rut deudor',
                value: document.stakeholderIdentifier,
              },
              {
                name: 'Estado',
                value: document.status,
              },
              {
                name: 'Fecha de pago',
                value: formatDate(document.paymentDate),
              },
              {
                name: 'Días vencida',
                value: document.dueDays,
              },
              {
                name: 'Anticipo',
                value: `${document.advance}%`,
              },
            ];
            const remainingAmount =
              payIn.businessOriginIdentifier === document.businessIdentifier
                ? document.totalRemainingNet
                : document.totalRemaining;
            const documentAmount =
              payIn.businessOriginIdentifier === document.businessIdentifier
                ? document.amountNet
                : document.amount;
            return (
              <Grid
                key={document.folio}
                container
                spacing={2}
                rowSpacing={4}
                alignItems="center"
              >
                <Grid item xs={1}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <LightTooltip
                      title={CustomTooltip(
                        tooltipTitleValues,
                        'Concepto',
                        'Valor',
                      )}
                    >
                      <Info
                        style={{
                          fontSize: 16,
                          cursor: 'pointer',
                        }}
                      />
                    </LightTooltip>
                    <Typography variant="body1" marginLeft={1}>
                      {document.folio}
                    </Typography>
                  </div>
                </Grid>
                <Grid item xs={1}>
                  <Typography variant="body1" textAlign="right">
                    {formatMoney(documentAmount)}
                  </Typography>
                </Grid>

                <Grid item xs={8}>
                  <Grid container spacing={2}>
                    <Grid item xs={2}>
                      <Typography variant="body1" textAlign="right">
                        {formatMoney(
                          document.arrearAmount + document.arrearConciliation,
                        )}
                      </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography variant="body1" textAlign="right">
                        {formatMoney(
                          documentAmount +
                            document.arrearAmount +
                            document.arrearConciliation,
                        )}
                      </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography variant="body1" textAlign="right">
                        {formatMoney(document.documentConciliation)}
                      </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography variant="body1" textAlign="right">
                        {formatMoney(document.arrearConciliation)}
                      </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography variant="body1" textAlign="right">
                        {formatMoney(document.amountConciliated)}
                      </Typography>
                    </Grid>

                    <Grid item xs={2}>
                      <Typography variant="body1" textAlign="right">
                        {formatMoney(remainingAmount)}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={2} textAlign="right">
                  <TextField
                    value={formatMoney(document.amountToConciliate)}
                    fullWidth
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <IconButton
                            color="primary"
                            style={{ cursor: 'pointer' }}
                            disabled={
                              formatMoney(remainingAmount) ===
                              formatMoney(document.amountToConciliate)
                            }
                            onClick={() =>
                              handleSelectAmount(
                                formatMoney(remainingAmount),
                                document,
                              )
                            }
                          >
                            <SettingsBackupRestore />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    inputProps={{
                      style: { textAlign: 'right' },
                    }}
                    onChange={(e) =>
                      handleSelectAmount(e.target.value, document)
                    }
                  />
                </Grid>
              </Grid>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default PaymentConciliationAssginationSuggested;

// LoginForm.tsx
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { authenticate } from '../../../../services/redux/authSlice';  // Import the action
import { authenticateUser, getAutoEnrollmentStatus, sendPasswordResetEmail } from '../../../../lib/api';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import CenteredCircularProgress from '../../../../lib/designSystem/CircularProgress';
import CustomButton from '../../../../lib/designSystem/Button';
import { Box, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import { Copyright } from '../../../PublicLandings/EnrolationForm';
import CustomAlert from '../../../../lib/designSystem/Alert';

const PasswordRecoveryExpress = () => {
    const { clientId } = useParams<{ clientId: string}>();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoding] = useState(false);
    const [failureText, setFailureText] = useState('')
    const [success, setSuccess] = useState(false)

    const dispatch = useDispatch();

    const handleSubmit = async (e: React.FormEvent): Promise<void> => {
      e.preventDefault();
      setLoding(true)
      const response = await sendPasswordResetEmail({email, clientId });
      console.log(response)
      if (response.success) {
        setSuccess(true)
        
      } else {
        setFailureText(response.message)
      }
      setLoding(false)
  };
    if (loading) return <CenteredCircularProgress type='dialog' />
    return (
      <div style={{ backgroundColor: '#F8FAFC', height: '100vh'}}>
      <div style={{margin: 'auto', maxWidth: '40%', padding: 40}}>
        <Typography variant='h4'>Recuperar contraseña </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} >
            <TextField
              fullWidth
              label="Email"
              variant="outlined"
              id="email"
              value={email}
              onChange={e => setEmail(e.target.value)}
            />
          </Grid>
        </Grid>
        <Box sx={{mt: 2}}>
          <CustomButton
            onClick={(e) => handleSubmit(e)}
            fullWidth
            color='primary'
            variant="contained"
          >
            Enviar link de recuperación
          </CustomButton>
        </Box>
        {failureText.length ?   <Box sx={{mt: 2}}>
          <Typography color='error'>{failureText}</Typography>
        </Box> : null}
        <Box sx={{mt: 2}}>
          <Typography variant='body1'>¿Ya tienes una cuenta? <a href={`/express/${clientId}`}>Inicia sesión</a></Typography>
        </Box>
     </div>
     <CustomAlert
      open={success}
      setOpen={setSuccess}
      severity='success'
      text='Se te envió un correo con un link para recuperar tu contraseña'
      onClose={() => window.location.href = `/express/${clientId}`}
      />
     <Copyright />
     </div>)
};

export default PasswordRecoveryExpress;

import * as React from 'react';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { CircularProgress } from '@mui/material';
import {
  formatDate,
  formatMoney,
} from '../../../../../../../../../lib/functions/utils';
import { getFactoringRequestSentWithQuotesSummary } from '../../../../../../../../../lib/api';
import { useDispatch, useSelector } from 'react-redux';
import ReceiptIcon from '@mui/icons-material/Receipt';
import FolderIcon from '@mui/icons-material/Folder';

import { makeStyles, createStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';
import { cellFormat } from '../../../../../../../../../lib/designSystem/Table/cellFormat';
import { AuthState } from '../../../../../../../../../services/redux/authSlice';

interface FactoringRequest {
  id: number;
  businessName: string;
  amount: number;
  createdAt: Date;
  status: string;
}

function Row(props: {
  row: FactoringRequest;
  handleDetails: any;
  handleOpenFiles: any;
}) {
  const classes = useStyles();
  const { row, handleDetails, handleOpenFiles } = props;
  const [open, setOpen] = React.useState(false);
  const [loadingQuotes, setLoadingQuotes] = React.useState(true);
  const [quotes, setQuotes] = React.useState<any>([]);
  const user = useSelector((state: { auth: AuthState }) => state.auth.user);
  const dispatch = useDispatch();

  const handleOpen = async (id: number) => {
    if (open) return setOpen(false);

    setLoadingQuotes(true);
    setOpen(true);
    try {
      const factoringRequestsResponse =
        await getFactoringRequestSentWithQuotesSummary(dispatch, { id });
      setQuotes(factoringRequestsResponse.quotes || []);
    } catch (error) {}
    setLoadingQuotes(false);
  };

  return (
    <React.Fragment>
      <TableRow>
        <TableCell style={cellStyle}>{row.id}</TableCell>
        <TableCell style={cellStyle}>{row.businessName}</TableCell>
        <TableCell style={cellStyle} align="right">
          {formatMoney(row.amount)}
        </TableCell>
        <TableCell style={cellStyle} align="right">
          {formatDate(row.createdAt, 'large')}
        </TableCell>
        <TableCell style={cellStyle} align="right">
          {cellFormat(row.status, 'status')}
        </TableCell>
        <TableCell style={cellStyle} align="right">
          {/* <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => handleOpen(row.id)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton> */}
          <IconButton
            onClick={() =>
              handleDetails({
                requestId: row.id,
              })
            }
          >
            <ReceiptIcon />
          </IconButton>
        </TableCell>
      </TableRow>
      {open && (
        <TableRow>
          <TableCell style={cellStyle} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ margin: '4px 16px' }}>
                <Typography
                  fontWeight="bold"
                  variant="h6"
                  gutterBottom
                  component="div"
                >
                  Cotizaciones
                </Typography>
                {loadingQuotes ? (
                  <CircularProgress />
                ) : (
                  <Table
                    className={classes.table}
                    size="small"
                    aria-label="purchases"
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell style={cellStyle}>ID</TableCell>
                        <TableCell style={cellStyle}>Turno</TableCell>
                        <TableCell style={cellStyle}>Ejecutivo</TableCell>
                        {!user?.express && (
                          <TableCell style={cellStyle}>Financiera</TableCell>
                        )}
                        <TableCell style={cellStyle}>Estado</TableCell>
                        <TableCell style={cellStyle} align="right">
                          Tasa (%)
                        </TableCell>
                        <TableCell style={cellStyle} align="right">
                          Avance (%)
                        </TableCell>
                        <TableCell style={cellStyle} align="right">
                          Costo Operación
                        </TableCell>
                        <TableCell style={cellStyle} align="right">
                          Monto a depositar
                        </TableCell>
                        <TableCell style={cellStyle} align="right"></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody className={classes.tableBody}>
                      {quotes.map((quote: any, index: number) => (
                        <TableRow
                          key={quote.recipientName}
                          className={
                            index === quotes.length - 1
                              ? classes.noBottomBorder
                              : ''
                          }
                        >
                          <TableCell
                            style={cellStyle}
                            component="th"
                            scope="row"
                          >
                            {quote.id}
                          </TableCell>
                          <TableCell style={cellStyle}>
                            {cellFormat(quote.turn, 'turn')}
                          </TableCell>
                          <TableCell
                            style={cellStyle}
                            component="th"
                            scope="row"
                          >
                            {quote.recipientName}
                          </TableCell>
                          {!user?.express && (
                            <TableCell
                              style={cellStyle}
                              component="th"
                              scope="row"
                            >
                              {quote.recipientCompany}
                            </TableCell>
                          )}

                          <TableCell style={cellStyle}>
                            {cellFormat(quote.status, 'status')}
                          </TableCell>
                          <TableCell style={cellStyle} align="right">
                            {quote.rate}
                          </TableCell>
                          <TableCell style={cellStyle} align="right">
                            {quote.advance}
                          </TableCell>
                          <TableCell style={cellStyle} align="right">
                            {formatMoney(quote.operationCost)}
                          </TableCell>
                          <TableCell style={cellStyle} align="right">
                            {formatMoney(quote.amountToDeposit)}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                )}
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </React.Fragment>
  );
}
const cellStyle: any = {
  maxWidth: '150px',
  overflow: 'hidden', // Ensure the content doesn't overflow.
  textOverflow: 'ellipsis', // If the text overflows, it will end with "..."
  whiteSpace: 'nowrap', // Ensure the text doesn't wrap to the next line.
  padding: '8px 12px',
  margin: '0px',
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {
      width: '100%', // ensure the table fits its container
    },
    tableContainer: {
      border: '1px solid #e0e0e0',
      borderRadius: '10px',
      maxWidth: '100%',
    },
    tableBody: {
      backgroundColor: '#ffffff',
    },
    noBottomBorder: {
      '&:last-child td, &:last-child th': {
        border: 0,
      },
    },
  }),
);

export default function RequestsTable(props: any) {
  const { factoringRequests, handleDetails, handleOpenFiles } = props;
  const classes = useStyles();

  return (
    <TableContainer className={classes.tableContainer}>
      <Table className={classes.table} style={{ borderRadius: '10px' }}>
        <TableHead style={{ background: '#efefef' }}>
          <TableRow>
            <TableCell style={cellStyle}>ID</TableCell>
            <TableCell style={cellStyle}>Cuenta</TableCell>
            <TableCell style={cellStyle} align="right">
              Monto operación
            </TableCell>
            <TableCell style={cellStyle} align="right">
              Fecha de creación
            </TableCell>
            <TableCell style={cellStyle} align="right">
              Estado
            </TableCell>
            <TableCell style={cellStyle} />
          </TableRow>
        </TableHead>
        <TableBody className={classes.tableBody}>
          {factoringRequests.map((row: any) => (
            <Row
              key={row.name}
              row={row}
              handleDetails={handleDetails}
              handleOpenFiles={handleOpenFiles}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

import React, { useEffect, useState } from 'react';
import HeaderWithActions from '../../../../../../lib/designSystem/HeaderWithActions';
import EnhancedTable from '../../../../../../lib/designSystem/Table';
import { useDispatch } from 'react-redux';
import { Download } from '@mui/icons-material';
import ExcelJS from 'exceljs';
import { Container } from '@mui/material';
import moment from 'moment';
import dayjs from 'dayjs';
import { DateSearcher } from '../common/DateSearcher';
import CenteredCircularProgress from '../../../../../../lib/designSystem/CircularProgress';
import { getCurseBookReport } from '../../../../../../lib/api/reports';

const CurseBook: React.FC = () => {
  const [data, setData] = useState([]);
  const [startDate, setStartDate] = useState(
    dayjs().subtract(1, 'month').subtract(4, 'hour'),
  );
  const [endDate, setEndDate] = useState(
    dayjs().endOf('day').subtract(4, 'hour'),
  );
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  const exportToExcel = async () => {
    const plainData = data.map((item: any) => {
      return [
        item.operationDate,
        item.operationId,
        item.businessIdentifier,
        item.businessName,
        item.amount,
        item.priceDifference,
        item.commissionAmount,
        item.receivableAmount,
        item.documentsAmount,
        item.remittedAmount,
        item.documentType,
        item.advancePercentage,
        item.interestRate,
        item.arrearRate,
        item.executive,
      ];
    });
    const headers = [
      'Fecha operación',
      'Número operación',
      'RUT Cliente',
      'Nombre Cliente',
      'Monto operación',
      'Diferencia de precio',
      'Monto comsión',
      'Aplica CxC',
      'Aplica documentos',
      'Total giro',
      'Tipo documento',
      'Porcentaje anticipado',
      'Tasa negociada',
      'Tasa mora',
      'Ejecutivo',
    ];
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Sheet1');

    // Add data to worksheet
    [headers, ...plainData].forEach((rowData, rowIndex) => {
      console.log(rowData);
      const row = worksheet.addRow(rowData);
      if (rowIndex === 0) {
        // Make the first row bold, visible, and add grey background color
        row.eachCell((cell) => {
          cell.font = { bold: true };
          cell.alignment = { vertical: 'middle', horizontal: 'center' };
          worksheet.getColumn(cell.col).width = 20;
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'FFDDDDDD' }, // Grey background color
          };
        });
      }
    });
    // Save workbook
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
    const url = URL.createObjectURL(blob);

    const a = document.createElement('a');
    a.href = url;
    a.download = 'data.xlsx';
    a.click();

    URL.revokeObjectURL(url);
  };

  const setUp = async () => {
    setLoading(true);
    try {
      const response = await getCurseBookReport(
        dispatch,
        startDate.toString(),
        endDate.toString(),
      );
      setData(response);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };
  useEffect(() => {
    setUp();
  }, []);
  if (loading) return <CenteredCircularProgress type="layout" />;
  return (
    <div>
      <HeaderWithActions
        customTitle={
          <DateSearcher
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
            setUp={setUp}
          />
        }
        variant="body1"
        buttons={[
          {
            action: () => exportToExcel(),
            name: 'Descargar XSLX',
            color: 'primary',
            startIcon: <Download />,
          },
        ]}
      />
      <Container style={{ padding: 0, margin: 0 }}>
        <EnhancedTable
          dataHeaders={[
            { key: 'operationDate', label: 'Fecha operación', type: 'date' },
            { key: 'operationId', label: 'Número operación', type: 'report' },
            { key: 'businessIdentifier', label: 'RUT cliente' },
            { key: 'businessName', label: 'Nombre cliente' },
            { key: 'amount', label: 'Monto operación', type: 'money' },
            {
              key: 'priceDifference',
              label: 'Diferencia de precio',
              type: 'money',
            },
            { key: 'commissionAmount', label: 'Monto comisión', type: 'money' },
            { key: 'receivableAmount', label: 'Aplica CxC', type: 'money' },
            {
              key: 'documentsAmount',
              label: 'Aplica documentos',
              type: 'money',
            },
            { key: 'remittedAmount', label: 'Total giro', type: 'money' },
            { key: 'documentType', label: 'Tipo documento' },
            { key: 'advancePercentage', label: 'Porcentaje anticipado' },
            { key: 'interestRate', label: 'Tasa negociada' },
            { key: 'arrearRate', label: 'Tasa mora' },
            { key: 'executive', label: 'Ejecutivo' },
          ]}
          data={data}
          actions={[]}
          showActions
          rowsPerPageDefault={100}
        />
      </Container>
    </div>
  );
};

export default CurseBook;

// LoginForm.tsx
import React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Login from './Validator';
import Logo from '../../../assets/img/pruff9.svg';
import { Link, Typography } from '@mui/material';

export function Copyright(props: any) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {'Powered by'}
      <div style={{ padding: '10px 15px 10px' }}>
        <img width={90} src={Logo} />
      </div>
      <Link color="inherit" href="https://pruff.com/">
        Pruff
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const EnrolationForm = () => {
  return (
    <div>
      <Grid container>
        <Grid
          item
          xs={0}
          md={5}
          style={{
            background: 'linear-gradient(to bottom, #0F23DF, #0F23DF, #0F23DF)',
            opacity: 0.8,
            height: '100vh',
            display: 'flex',
          }}
        >
          {/* Content goes here */}
        </Grid>
        <Grid item xs={12} md={7}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              height: '100vh',
              justifyContent: 'space-between',
            }}
          >
            <Container component="main">
              <Box>
                <Login />
              </Box>
            </Container>
            <Copyright />
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default EnrolationForm;

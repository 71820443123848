import React from 'react';
import {
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Tooltip,
  Typography,
} from '@mui/material';
import CustomButton from '../../../../../../lib/designSystem/Button';
import { formatDate } from '../../../../../../lib/functions/utils';

const colorFullDecision = (text: string) => {
  switch (text) {
    case 'Aprobado':
      // return <Chip label={text} color="success" />;
      return (
        <Typography style={{ color: 'green', fontWeight: 'bold' }}>
          {text}
        </Typography>
      );
    case 'Rechazado':
      // return <Chip label={text} color="error" />;
      return (
        <Typography style={{ color: 'red', fontWeight: 'bold' }}>
          {text}
        </Typography>
      );
    default:
      return (
        <Typography style={{ color: '#E8C547', fontWeight: 'bold' }}>
          {text}
        </Typography>
      );
    // return (
    //   <Chip
    //     label={text}
    //     style={{ backgroundColor: '#E8C547', color: 'white' }}
    //   />
    // );
  }
};

const RevisionStoryDetails = (props: any) => {
  const { revisionStory, index, engineType } = props;
  return (
    <div>
      <Grid container spacing={2} alignItems={'center'}>
        <Grid item xs={12} md={2}>
          <Typography>{index}</Typography>
        </Grid>
        <Grid item xs={12} md={3}>
          <Tooltip title={revisionStory.description}>
            <div style={{ padding: '5px' }}>
              <Typography>{revisionStory.node}</Typography>
            </div>
          </Tooltip>
        </Grid>
        {engineType !== 'Árbol' ? (
          <Grid item xs={12} md={3}>
            {colorFullDecision(revisionStory.resultDecision)}
          </Grid>
        ) : (
          <Grid item xs={2} />
        )}
        <Grid item xs={12} md={engineType !== 'Árbol' ? 4 : 5}>
          <Typography>{revisionStory.resultText}</Typography>
        </Grid>
      </Grid>
    </div>
  );
};

const EvaluationDetails = (props: any) => {
  const { evaluation, open, setOpen } = props;

  return (
    <Dialog maxWidth="lg" fullWidth open={open} onClose={() => setOpen(false)}>
      <DialogTitle>Detalles de la evaluación</DialogTitle>
      <DialogContent>
        <div style={{ padding: '0px 0px 10px' }}>
          <Typography variant="body1" fontWeight="bold" margin="8px 0px">
            Información general
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} md={4}>
              <Typography>
                <b>Cliente:</b>
              </Typography>
              <Typography>{evaluation.businessName}</Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography>
                <b>Resultado:</b>
              </Typography>
              <Typography>{colorFullDecision(evaluation.nodeName)}</Typography>
            </Grid>
            <Grid item xs={12} md={3}>
              <Typography>
                <b>Descripción:</b>
              </Typography>
              <Typography>{evaluation.nodeDescription}</Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography>
                <b>Nombre motor:</b>
              </Typography>
              <Typography>{evaluation.engineName}</Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography>
                <b>Tipo motor:</b>
              </Typography>
              <Typography>{evaluation.engineType}</Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography>
                <b>Fecha de evaluación:</b>
              </Typography>
              <Typography>{formatDate(evaluation.date, 'large')}</Typography>
            </Grid>
          </Grid>
        </div>

        <Divider />

        <Typography variant="body1" fontWeight="bold" margin="8px 0px">
          {' '}
          Informe de la evaluación
        </Typography>

        <div>
          <Grid container spacing={2}>
            <Grid item xs={12} md={2}>
              <Typography>
                <b>Nodo</b>
              </Typography>
            </Grid>
            <Grid item xs={12} md={3}>
              <Typography>
                <b>Decisión:</b>
              </Typography>
            </Grid>
            {evaluation.engineType !== 'Árbol' ? (
              <Grid item xs={12} md={3}>
                <Typography>
                  <b>Resultado:</b>
                </Typography>
              </Grid>
            ) : (
              <Grid item xs={2} />
            )}
            <Grid item xs={12} md={evaluation.engineType !== 'Árbol' ? 4 : 5}>
              <Typography>
                <b>Detalles:</b>
              </Typography>
            </Grid>
          </Grid>
          {evaluation.revisionStory.map((revisionStory: any, index: number) => (
            <RevisionStoryDetails
              key={index}
              index={index + 1}
              revisionStory={revisionStory}
              engineType={evaluation.engineType}
            />
          ))}
        </div>
      </DialogContent>
      <DialogActions>
        <CustomButton color="secondary" onClick={() => setOpen(false)}>
          {' '}
          Salir{' '}
        </CustomButton>
      </DialogActions>
    </Dialog>
  );
};

export default EvaluationDetails;

import {
  Box,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import React from 'react';
import HeaderWithActions from '../../../../../../../../lib/designSystem/HeaderWithActions';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import PriceCheckIcon from '@mui/icons-material/PriceCheck';
import CustomButton from '../../../../../../../../lib/designSystem/Button';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import UnsubscribeIcon from '@mui/icons-material/Unsubscribe';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import { validatePermissions } from '../../../../../../../../lib/functions/utils';
import { useSelector } from 'react-redux';
import { AuthState } from '../../../../../../../../services/redux/authSlice';

interface ManagementProps {
  managements: {
    id: number;
    description: string;
    type: string;
    createdAt: string;
    invoiceId: string;
    updatedAt: string;
  }[];
  open: boolean;
  setOpen: any;
  setOpenModal: any;
  folio: number;
}

const codesWithIcons = [
  'CALL',
  'EMAIL',
  'CLOSED',
  'auto-unenrollment',
  'auto-enrollment',
];

const Management: React.FC<ManagementProps> = (props) => {
  const user = useSelector((state: { auth: AuthState }) => state.auth.user);
  const managements = props.managements.sort((a, b) => {
    return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
  });
  const transformDate = (date: string) => {
    const dateObj = new Date(date);
    return `${dateObj.getDate()}/${dateObj.getMonth()}/${dateObj.getFullYear()} - ${dateObj.getHours()}:${dateObj.getMinutes().toString().padStart(2, '0')}`;
  };
  return (
    <div>
      <Drawer
        anchor="right"
        open={props.open}
        onClose={() => {
          props.setOpen(close);
        }}
      >
        <Box sx={{ width: 350, backgroundColor: '#F8FAFC', height: '100%' }}>
          <div style={{ paddingLeft: '23px' }}>
            <HeaderWithActions
              type="pageHeader"
              title={`Gestiones realizadas para # ${props.folio}`}
              variant="h4"
            />
          </div>
          <div style={{ paddingLeft: '23px' }}>
            <CustomButton
              color="primary"
              onClick={() => {
                props.setOpen(false);
                props.setOpenModal(true);
              }}
              disabled={!validatePermissions(user, 'collections_create')}
            >
              {' '}
              Nueva gestión{' '}
            </CustomButton>
          </div>
          <List>
            {managements.length === 0 && (
              <ListItem>
                <ListItemText primary="No hay gestiones realizadas" />
              </ListItem>
            )}
            {managements.map((item) => (
              <div key={item.id}>
                <ListItem>
                  <ListItemIcon>
                    {item.type === 'CALL' && <PhoneIcon />}
                    {item.type === 'EMAIL' && <EmailIcon />}
                    {item.type === 'CLOSED' && <PriceCheckIcon />}
                    {item.type === 'CLOSED' && <PriceCheckIcon />}
                    {item.type.includes('auto-unenrollment') && (
                      <UnsubscribeIcon />
                    )}
                    {item.type.includes('auto-enrollment') && (
                      <MarkEmailReadIcon />
                    )}
                    {!codesWithIcons.includes(item.type) && <TaskAltIcon />}
                  </ListItemIcon>
                  <ListItemText
                    primary={`${item.type} - ${transformDate(item.createdAt)}`}
                    secondary={item.description}
                  />
                </ListItem>
                <Divider variant="inset" component="li" />
              </div>
            ))}
          </List>
        </Box>
      </Drawer>
    </div>
  );
};

export default Management;

import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Dialog, DialogContent, DialogActions, Divider } from '@mui/material';
import CustomButton from '../../../../../../../../lib/designSystem/Button';
import CenteredCircularProgress from '../../../../../../../../lib/designSystem/CircularProgress';
import { createConciliationBatch } from '../../../../../../../../lib/api';
import PaymentConciliationSummary from './SummaryContainer';
import PaymentConciliationAssgination from './Conciliate';
import FNCDialog from './FNC';
import {
  validateCompanyTypes,
  validatePermissions,
} from '../../../../../../../../lib/functions/utils';
import { AuthState } from '../../../../../../../../services/redux/authSlice';

const PaymentConciliationDialog = (props: any) => {
  const {
    open,
    setOpen,
    payIn,
    setPayIn,
    setSuccessOpen,
    setFailureOpen,
    setUp,
  } = props;
  const user = useSelector((state: { auth: AuthState }) => state.auth.user);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [openFNC, setOpenFNC] = useState(false);
  const [factoringDocumentsSuggested, setFactoringDocumentsSuggested] =
    useState([]);
  const generateConciliationSavePayload = () => {
    console.log(factoringDocumentsSuggested);
    return factoringDocumentsSuggested
      .map((doc: any) => ({
        documentId: doc.id,
        amount: doc.amountToConciliate,
        payInId: payIn.id,
      }))
      .filter((doc: any) => doc.amount > 0);
  };

  const submitPayIn = async () => {
    try {
      setLoading(true);
      const payload = generateConciliationSavePayload();
      console.log(payload);
      await createConciliationBatch(dispatch, payload);
      await setUp();
      setOpen(false);
      setSuccessOpen('Pago conciliado correctamente');
    } catch (error) {
      console.log(error);
      setFailureOpen('Error al crear la conciliación');
    }
    setLoading(false);
  };

  const amountToConciliate = factoringDocumentsSuggested.reduce(
    (acc: number, doc: any) => acc + (Number(doc.amountToConciliate) || 0),
    0,
  );

  const disabled =
    payIn.amount - payIn.amountConciliated - amountToConciliate < 0;
  console.log(
    disabled,
    payIn.amount,
    payIn.amountConciliated,
    amountToConciliate,
  );
  useEffect(() => {
    // setUpAccounts();
  }, []);

  return (
    <div>
      <Dialog
        maxWidth="lg"
        fullWidth
        open={open}
        onClose={() => setOpen(false)}
      >
        {loading ? (
          <div style={{ minHeight: 200 }}>
            <CenteredCircularProgress type="dialog" />
          </div>
        ) : (
          <DialogContent>
            <PaymentConciliationSummary
              payIn={payIn}
              bigSetup={setUp}
              setOpen={setOpen}
            />
            <Divider />

            <PaymentConciliationAssgination
              payIn={payIn}
              setPayIn={setPayIn}
              setOpen={setOpen}
              bigSetup={setUp}
              setDocumentsSuggested={setFactoringDocumentsSuggested}
              documentsSuggested={factoringDocumentsSuggested}
            />
          </DialogContent>
        )}
        <DialogActions>
          <CustomButton onClick={() => setOpen(false)} color="secondary">
            Salir
          </CustomButton>
          {validateCompanyTypes(user, 'FinancingHub') && (
            <CustomButton
              onClick={() => setOpenFNC(true)}
              color="success"
              disabled={
                disabled || !validatePermissions(user, 'financing_fnc_new')
              }
            >
              FNC
            </CustomButton>
          )}
          <CustomButton
            onClick={() => submitPayIn()}
            color="primary"
            disabled={disabled}
          >
            Guardar
          </CustomButton>
        </DialogActions>
        <FNCDialog
          open={openFNC}
          setBigOpen={setOpen}
          setOpen={setOpenFNC}
          setUp={setUp}
          payIn={payIn}
          setPayIn={setPayIn}
          setSuccessOpen={setSuccessOpen}
        />
      </Dialog>
    </div>
  );
};

export default PaymentConciliationDialog;

// DialogActionsWrapper.jsx
import React from 'react';
import { Typography } from '@mui/material';
import CustomButton from '../../../../../../../../../../../../../lib/designSystem/Button';
import { validatePermissions } from '../../../../../../../../../../../../../lib/functions/utils';
import { useSelector } from 'react-redux';
import { AuthState } from '../../../../../../../../../../../../../services/redux/authSlice';

const DialogActionsWrapper = ({
  onClose,
  actions,
  handleOpenDeleteConfirmModal,
}: any) => {
  const user = useSelector((state: { auth: AuthState }) => state.auth.user);

  const canDeleteRequest = () => {
    if (validatePermissions(user, 'financing_factoring_delete')) return true;

    return false;
  };
  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      {canDeleteRequest() && (
        <Typography
          color="error"
          variant="body1"
          fontWeight="bold"
          style={{ cursor: 'pointer' }}
          onClick={handleOpenDeleteConfirmModal}
        >
          Eliminar
        </Typography>
      )}
      <div style={{ display: 'flex', gap: '10px', marginLeft: 'auto' }}>
        <CustomButton onClick={onClose} color="secondary">
          Salir
        </CustomButton>
        {actions}
      </div>
    </div>
  );
};
export default DialogActionsWrapper;

import React, { useEffect, useState } from 'react';
import EnhancedTable from '../../../../../../lib/designSystem/Table';
import HeaderWithActions, {
  CustomButtonProps,
} from '../../../../../../lib/designSystem/HeaderWithActions';
import { getCompanyPayIns } from '../../../../../../lib/api';
import { useDispatch, useSelector } from 'react-redux';
import CustomAlert from '../../../../../../lib/designSystem/Alert';
import CenteredCircularProgress from '../../../../../../lib/designSystem/CircularProgress';
import Payment from './components/Payment';
import BankAccountDialog from '../../../../../../lib/common/BankAccountDialog';
import PaymentConciliationDialog from './components/Conciliation';
import {
  formatDate,
  validatePermissions,
} from '../../../../../../lib/functions/utils';
import { AuthState } from '../../../../../../services/redux/authSlice';

const headers: { key: string; label: string; type?: any }[] = [
  { key: 'bankAccountOriginName', label: 'Banco Origen' },
  { key: 'businessOriginName', label: 'Cuenta Origen' },
  { key: 'bankAccountDestinationName', label: 'Banco Destino' },
  { key: 'businessDestinationName', label: 'Cuenta Destino' },
  { key: 'amount', label: 'Monto', type: 'money' },
  { key: 'amountConciliated', label: 'Monto conciliado', type: 'money' },
  { key: 'status', label: 'Estado' },
  { key: 'createdAt', label: 'Fecha de creación', type: 'date' },
];

const PayIns: React.FC = () => {
  const user = useSelector((state: { auth: AuthState }) => state.auth.user);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [openPaymentModal, setOpenPaymentModal] = useState(false);
  const [openBankAccountModal, setOpenBankAccountModal] = useState(false);
  const [payIn, setPayIn] = useState<any>({});
  const [payIns, setPayIns] = useState<any[]>([]);
  const [selectedBankAccount, setSelectedBankAccount] = useState<any>({});
  const [success, setSuccess] = useState(false);
  const [successText, setSuccessText] = useState('');
  const [failure, setFailure] = useState(false);
  const [failureText, setFailureText] = useState('');
  const [openConciliationDialog, setOpenConciliationDialog] = useState(false);

  const setSuccessOpen = (text: string) => {
    setSuccessText(text);
    setSuccess(true);
  };

  const setFailureOpen = (text: string) => {
    setFailureText(text);
    setFailure(true);
  };

  const handleConciliate = (id: number) => {
    const payIn = payIns.find((x) => x.id === id);
    if (payIn) {
      setPayIn(payIn);
      setOpenConciliationDialog(true);
    }
  };

  const handleNewPayment = () => {
    const newPayment = {
      amount: 0,
      bankAccountOriginId: 0,
      bankAccountDestinationId: 0,
      createdAt: formatDate(new Date()),
    };
    setPayIn(newPayment);
    setOpenPaymentModal(true);
  };

  const canShowConciliate = () => {
    if (validatePermissions(user, 'financing_payIn_conciliate')) return true;

    return false;
  };

  const actions = [
    {
      action: handleConciliate,
      actionLabel: 'Conciliación',
      canShow: canShowConciliate,
    },
  ];

  const fetchPayIns = async () => {
    setLoading(true);
    try {
      const payInsResponse = await getCompanyPayIns(dispatch);
      setPayIns(payInsResponse);
    } catch (error) {
      setFailureOpen('No se pudieron obtener los pagos');
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchPayIns();
  }, []);

  const buttons: CustomButtonProps[] = [
    {
      name: 'Nuevo pago',
      action: () => handleNewPayment(),
      color: 'primary',
      disabled: !validatePermissions(user, 'financing_payIn_new'),
    },
  ];

  if (loading) return <CenteredCircularProgress type="layout" />;

  return (
    <div>
      <HeaderWithActions
        title="Acá podrás ver todos los pagos recibidos a tus cuentas registradas."
        variant="body1"
        buttons={buttons}
      />
      <EnhancedTable
        dataHeaders={headers}
        data={payIns}
        rowsPerPageDefault={100}
        actions={actions}
        showActions
        exportToFile="xlsx"
      />
      {openPaymentModal && (
        <Payment
          open={openPaymentModal}
          setOpen={setOpenPaymentModal}
          payIn={payIn}
          setPayIn={setPayIn}
          setOpenBankAccountModal={setOpenBankAccountModal}
          setSuccessOpen={setSuccessOpen}
          setFailureOpen={setFailureOpen}
          setUp={fetchPayIns}
        />
      )}
      {openBankAccountModal && (
        <BankAccountDialog
          open={openBankAccountModal}
          setOpen={setOpenBankAccountModal}
          selectedBankAccount={selectedBankAccount}
          setSelectedBankAccount={setSelectedBankAccount}
          handleSuccessExit={() => setOpenPaymentModal(true)}
          setSuccess={setSuccessOpen}
          setFailure={setFailureOpen}
        />
      )}
      {openConciliationDialog && (
        <PaymentConciliationDialog
          open={openConciliationDialog}
          setOpen={setOpenConciliationDialog}
          payIn={payIn}
          setPayIn={setPayIn}
          setSuccessOpen={setSuccessOpen}
          setFailureOpen={setFailureOpen}
          setUp={fetchPayIns}
        />
      )}
      <CustomAlert
        open={success}
        title="Éxito"
        onClose={() => {
          setSuccess(false);
        }}
        setOpen={setSuccess}
        text={successText}
        severity="success"
        size="small"
        variant="outlined"
      />
      <CustomAlert
        open={failure}
        title="Error"
        onClose={() => {
          setFailure(false);
        }}
        setOpen={setFailure}
        text={failureText}
        severity="error"
        size="small"
        variant="outlined"
      />
    </div>
  );
};

export default PayIns;

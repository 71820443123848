import { DialogActions, DialogContent, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import CustomButton from '../../../../../../../../lib/designSystem/Button';
import EnhancedTable from '../../../../../../../../lib/designSystem/Table';
import {
  createPaymentRequest,
  getBankAccountsForNominee,
  getDocumentAvailablesForPaymentRequest,
} from '../../../../../../../../lib/api';
import { useDispatch } from 'react-redux';
import CenteredCircularProgress from '../../../../../../../../lib/designSystem/CircularProgress';
import GenerateNominee from '../../../../../Financing/Factoring/components/Recieved/components/ShoppingDialog/components/PaymentModule/components/generateNominee';

const nomineeHeaders = [
  { key: 'bankAccountOriginName', label: 'Banco origen' },
  {
    key: 'bankName',
    label: 'Banco destino',
  },
  { key: 'accountNumber', label: 'N° Cuenta' },
  { key: 'accountType', label: 'Tipo de cuenta' },
  { key: 'holderName', label: 'Titular' },
  { key: 'holderIdentifier', label: 'Rut' },
  { key: 'amount', label: 'Monto', type: 'money' },
  { key: 'executedAt', label: 'Ejecutada', type: 'date' },
];

const PayoutDocuments = (props: any) => {
  const { setOpen, setSuccessOpen, setFailureOpen, setUp } = props;
  const [loading, setLoading] = useState(false);
  const [selectedDocuments, setSelectedDocuments] = useState<any>([]);
  const [documents, setDocuments] = useState([]);
  const [documentsConfirmed, setDocumentsConfirmed] = useState(false);
  const [destinyAccounts, setDestinyAccounts] = useState([]);
  const [originAccounts, setOriginAccounts] = useState([]);
  const [payments, setPayments] = useState([]);
  const [selectedRequest, setSelectedRequest] = useState<any>(null);
  const [step, setStep] = useState(1);

  const dispatch = useDispatch();

  const setUpDocuments = async () => {
    setLoading(true);
    try {
      const response = await getDocumentAvailablesForPaymentRequest(dispatch);
      setDocuments(response);
    } catch (error) {
      alert('Error al cargar los documentos');
    }
    setLoading(false);
  };

  useEffect(() => {
    setUpDocuments();
  }, []);

  const getStakeholderData = () => {
    if (selectedDocuments.length) {
      const document: any = documents.find(
        (invoice: any) => invoice.id === selectedDocuments[0],
      );
      if (document) {
        return {
          businessName: document.businessName,
          businessIdentifier: document.businessIdentifier,
        };
      }
    }
    return { businessName: '', businessIdentifier: '' };
  };

  const { businessIdentifier, businessName } = selectedDocuments.length
    ? getStakeholderData()
    : {
        businessIdentifier: '',
        businessName: '',
      };

  const configurePaymentRequest = () => {
    const documentsFiltered = documents.filter((doc: any) => {
      return selectedDocuments.includes(doc.id);
    });
    return {
      businessName,
      amount: documentsFiltered.reduce(
        (acc: number, doc: any) => acc + doc.amount,
        0,
      ),
      documents: documentsFiltered,
    };
  };
  const setUpConfigurePayment = async () => {
    setLoading(true);
    const request = configurePaymentRequest();
    setSelectedRequest(request);
    try {
      const accounts = await getBankAccountsForNominee(
        dispatch,
        businessIdentifier,
      );
      console.log(accounts);
      if (accounts) {
        setDestinyAccounts(accounts.businessBankAccounts);
        setOriginAccounts(accounts.ownBankAccounts);
        setDocumentsConfirmed(true);
      }
    } catch (error) {
      setFailureOpen('Error al obtener las cuentas');
    }
    setLoading(false);
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      await createPaymentRequest(dispatch, {
        payments,
        documents: selectedDocuments,
      });
      await setUp();
      setSuccessOpen('Pago configurado con éxito');
    } catch (error) {
      setFailureOpen('Error al configurar el pago');
    }
    setLoading(false);
    setOpen(false);
  };

  const stepChecker = async () => {
    switch (step) {
      case 1:
        await setUpConfigurePayment();
        return setStep(2);
      case 2:
        if (!payments.length) {
          return setStep(3);
        }
        return await handleSubmit();
    }
  };

  const disabledCheck = () => {
    if (selectedDocuments.length === 0) return true;

    if (payments.length === 0 && documentsConfirmed) return true;

    return false;
  };

  const cantSelectRow = (row: any) => {
    if (businessIdentifier && row.businessIdentifier !== businessIdentifier)
      return true;
    return false;
  };

  if (loading) return <CenteredCircularProgress type="dialog" />;

  return (
    <div>
      <DialogContent>
        {step === 1 && (
          <EnhancedTable
            data={documents}
            dataHeaders={[
              { key: 'folio', label: 'folio' },
              { key: 'businessName', label: 'Emisor' },
              { key: 'amount', label: 'monto', type: 'money' },
              { key: 'issuedDate', label: 'Fecha emisión', type: 'date' },
            ]}
            selected={selectedDocuments}
            setSelected={setSelectedDocuments}
            rowsPerPageDefault={5}
            cantSelectRow={cantSelectRow}
          />
        )}
        {step === 2 && !payments.length ? (
          <div>
            <GenerateNominee
              availableAccounts={destinyAccounts}
              originAccounts={originAccounts}
              selectedRequest={selectedRequest}
              setPayments={setPayments}
              amount={selectedRequest.amount}
            />
          </div>
        ) : null}
        {step === 2 && payments.length ? (
          <div>
            <Typography variant="body1" margin={'4px'}>
              Seleccione la configuración de pago
            </Typography>
            <EnhancedTable dataHeaders={nomineeHeaders} data={payments} />
          </div>
        ) : null}
      </DialogContent>
      <DialogActions>
        <CustomButton
          color="secondary"
          onClick={() =>
            documentsConfirmed ? setDocumentsConfirmed(false) : setOpen(false)
          }
        >
          {' '}
          Cancelar{' '}
        </CustomButton>
        <CustomButton
          color="primary"
          onClick={() => stepChecker()}
          disabled={disabledCheck()}
        >
          {' '}
          {step === 1 ? 'Siguiente' : 'Finalizar'}{' '}
        </CustomButton>
      </DialogActions>
    </div>
  );
};

export default PayoutDocuments;

import {
  Checkbox,
  DialogContent,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
} from '@mui/material';
import React from 'react';
import MultiUploader from '../../../../../../../../../lib/designSystem/MultiUploader';
import {
  handleSelectChange,
  renderSelectedNames,
} from '../../../../../../../../../lib/functions/utils';
import { useParams } from 'react-router-dom';

const Step3 = (props: any) => {
  const {
    executivesForms,
    setExecutivesForms,
    handleChangeFile,
    files,
    executives,
  } = props;

  const { id } = useParams<{ id: string }>();

  return (
    <DialogContent>
      {id && (
        <div style={{ padding: 10 }}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">
              Ejecutivos para cotizar
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={executivesForms.executives}
              multiple
              label="Clientes disponibles"
              onChange={(e) =>
                handleSelectChange(e, setExecutivesForms, executivesForms)
              }
              name="executives"
              renderValue={(selected) =>
                renderSelectedNames(selected, executives)
              }
            >
              {executives.map((x: any) => (
                <MenuItem key={x.id} value={x.id}>
                  <Checkbox
                    checked={executivesForms.executives.indexOf(x.id) > -1}
                  />
                  <ListItemText primary={`${x.name} (${x.email})`} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      )}
      <div style={{ padding: 10 }}>
        <MultiUploader
          buttonText="Agregar"
          handleChange={handleChangeFile}
          uploadedFileNames={files.map((x: any) => x.name)}
          textLeft="Puedes agregar archivos adicionales si lo necesitas"
        />
      </div>
    </DialogContent>
  );
};

export default Step3;

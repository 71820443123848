import React, { useEffect, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
  TextField,
  IconButton,
} from '@mui/material';
import { makeStyles, createStyles } from '@mui/styles';
import {
  formatDate,
  formatMoney,
  formatMoneyBack,
  validateCompanyTypes,
} from '../../../../../../../../../../../lib/functions/utils';
import { Add, Delete, ExpandLess, ExpandMore } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import { AuthState } from '../../../../../../../../../../../services/redux/authSlice';
import {
  getLiberationDate,
  getRealDaysToCount,
  isDateAWeekDay,
  calculateDays,
} from '../../../../../../../../../../../lib/functions/dates';

const useStyles = makeStyles(() =>
  createStyles({
    table: {
      width: '100%', // ensure the table fits its container
    },
    tableCell: {
      padding: '4px !important',
      maxWidth: '200px',
      maxHeight: '10px',
      overflow: 'hidden', // Ensure the content doesn't overflow.
      whiteSpace: 'nowrap', // Ensure the text doesn't wrap to the next line.
    },
    tableContainer: {
      border: '1px solid #e0e0e0',
      borderRadius: '10px',
    },
    tableBody: {
      backgroundColor: '#ffffff',
    },
    selectFullWidth: {
      width: '100%',
    },
    selectSmall: {
      width: '200px', // or any other small width
    },
  }),
);

const firstCellStyle: any = {
  maxWidth: '150px',
  overflow: 'hidden', // Ensure the content doesn't overflow.
  textOverflow: 'ellipsis', // If the text overflows, it will end with "..."
  whiteSpace: 'nowrap', // Ensure the text doesn't wrap to the next line.
  padding: '4px 16px',
};

const DocumentsTable = (props: any) => {
  const {
    selectedDocuments,
    setSelectedDocuments,
    exchangeDay,
    tempPaymentDateRows,
    setTempPaymentDateRows,
    setTempSubDocumentPaymentDateRows,
    tempSubDocumentPaymentDateRows,
    backwards,
    canForcePaymentDate,
    minimumDays,
  } = props;
  const classes = useStyles();

  const dateValidatorsSubIndex = (
    date: Date,
    rowIndex: number,
    subIndex: number,
  ) => {
    if (canForcePaymentDate) return true;

    const actualDate =
      selectedDocuments[rowIndex].subDocuments[subIndex].dueDate;
    const tempPaymentCopy = [...tempSubDocumentPaymentDateRows];
    if (!isDateAWeekDay(date)) {
      alert('La fecha de vencimiento debe ser un día hábil');
      tempPaymentCopy[rowIndex][subIndex] = formatDate(actualDate);
      setTempSubDocumentPaymentDateRows(tempPaymentCopy);
      return false;
    }
    const minimumDate = new Date();
    minimumDate.setDate(minimumDate.getDate() + minimumDays - 1);
    if (date < minimumDate) {
      alert(
        `La fecha de vencimiento no puede ser menor a ${minimumDays}  días a partir de la fecha actual`,
      );
      tempPaymentCopy[rowIndex][subIndex] = formatDate(actualDate);
      setTempSubDocumentPaymentDateRows(tempPaymentCopy);
      return false;
    }
    const days = calculateDays(
      new Date(selectedDocuments[rowIndex].subDocuments[subIndex].issuedDate),
      new Date(date),
    );
    if (days <= 0) {
      alert(
        'La fecha de vencimiento no puede ser menor a la fecha de liberación',
      );
      return false;
    }
    return days;
  };

  const dateValidators = (date: Date, rowIndex: number) => {
    if (canForcePaymentDate) return true;

    const actualDate = selectedDocuments[rowIndex].paymentDate;
    const tempPaymentCopy = [...tempPaymentDateRows];
    if (!isDateAWeekDay(date)) {
      alert('La fecha de vencimiento debe ser un día hábil');
      tempPaymentCopy[rowIndex] = formatDate(actualDate);
      setTempPaymentDateRows(tempPaymentCopy);
      return false;
    }
    const minimumDate = new Date();
    minimumDate.setDate(minimumDate.getDate() + minimumDays - 1);
    if (date < minimumDate) {
      alert(
        `La fecha de vencimiento no puede ser menor a ${minimumDays}  días a partir de la fecha actual`,
      );
      tempPaymentCopy[rowIndex] = formatDate(actualDate);
      setTempPaymentDateRows(tempPaymentCopy);
      return false;
    }
    const days = calculateDays(
      new Date(selectedDocuments[rowIndex].issuedDate),
      new Date(date),
    );
    if (days <= 0) {
      alert(
        'La fecha de vencimiento no puede ser menor a la fecha de liberación',
      );
      return false;
    }
    return days;
  };

  const handleChangeDateNormal = (value: any, index: number) => {
    console.log(value);
    const date = new Date(value);
    console.log(date);
    console.log(formatDate(date));

    const days = dateValidators(date, index);
    if (!days) return;
    const newSelectedDocuments = selectedDocuments.map((doc: any, i: any) => {
      if (i === index) {
        return {
          ...doc,
          dueDate: date?.toISOString(),
          paymentDate: date,
          daysToCount: getRealDaysToCount(date),
        };
      }
      return doc;
    });
    console.log(newSelectedDocuments);

    setSelectedDocuments(newSelectedDocuments);
  };

  const handleChangeDateExchange = (value: any, index: number) => {
    const date = new Date(value);
    const liberationDate = getLiberationDate(date);
    const daysToCount = getRealDaysToCount(liberationDate);
    const newSelectedDocuments = selectedDocuments.map((doc: any, i: any) => {
      if (i === index) {
        return {
          ...doc,
          dueDate: date?.toISOString(),
          paymentDate: liberationDate,
          daysToCount,
        };
      }
      return doc;
    });
    console.log(newSelectedDocuments);
    setSelectedDocuments(newSelectedDocuments);
  };
  const handleChangeSubDocumentDateNormal = (
    value: any,
    rowIndex: number,
    subIndex: number,
  ) => {
    const date = new Date(value);
    console.log(date);
    const days = dateValidatorsSubIndex(date, rowIndex, subIndex);
    if (!days) return;
    const newSelectedDocuments = selectedDocuments.map((doc: any, i: any) => {
      if (i === rowIndex) {
        return {
          ...doc,
          subDocuments: doc.subDocuments.map((subDoc: any, j: any) => {
            if (j === subIndex) {
              return {
                ...subDoc,
                dueDate: date?.toISOString(),
                paymentDate: date,
                daysToCount: getRealDaysToCount(date),
              };
            }
            return subDoc;
          }),
        };
      }
      return doc;
    });
    setSelectedDocuments(newSelectedDocuments);
  };

  const handleChangeSubDocumentDateExchange = (
    value: any,
    rowIndex: number,
    subIndex: number,
  ) => {
    const date = new Date(value);
    const liberationDate = getLiberationDate(date);
    const newSelectedDocuments = selectedDocuments.map((doc: any, i: any) => {
      if (i === rowIndex) {
        return {
          ...doc,
          subDocuments: doc.subDocuments.map((subDoc: any, j: any) => {
            if (j === subIndex) {
              const daysToCount = getRealDaysToCount(liberationDate);
              return {
                ...subDoc,
                dueDate: date?.toISOString(),
                paymentDate: liberationDate,
                daysToCount,
              };
            }
            return subDoc;
          }),
        };
      }
      return doc;
    });
    setSelectedDocuments(newSelectedDocuments);
  };

  // Handlers for opening and closing the tooltip.
  const [expandedRows, setExpandedRows] = useState<any>({});

  const toggleRowExpansion = (rowIndex: number) => {
    // Toggle the expanded state for the row with the given rowIndex
    setExpandedRows({
      ...expandedRows,
      [rowIndex]: !expandedRows[rowIndex],
    });
  };

  const addSubDocument = (rowIndex: number) => {
    // Add a sub-document to the row with the given rowIndex
    const newSelectedDocuments = selectedDocuments.map((doc: any, i: any) => {
      if (i === rowIndex) {
        const tempSubdocuments = [...tempSubDocumentPaymentDateRows];
        tempSubdocuments[rowIndex].push(null);
        setTempSubDocumentPaymentDateRows(tempSubdocuments);
        return {
          ...doc,
          subDocuments: [
            ...(doc.subDocuments ? doc.subDocuments : []),
            {
              ...selectedDocuments[rowIndex],
              id: null,
              fatherId: selectedDocuments[rowIndex].id,
              folio: null,
              amount: 0,
            },
          ],
        };
      }
      return doc;
    });
    setSelectedDocuments(newSelectedDocuments);
    if (!expandedRows[rowIndex]) toggleRowExpansion(rowIndex);
  };

  const handleChangeSubDocument = (
    value: any,
    name: any,
    rowIndex: number,
    subIndex: number,
  ) => {
    const newSelectedDocuments = selectedDocuments.map((doc: any, i: any) => {
      if (i === rowIndex) {
        return {
          ...doc,
          subDocuments: doc.subDocuments.map((subDoc: any, j: any) => {
            if (j === subIndex) {
              return {
                ...subDoc,
                [name]: name === 'amount' ? formatMoneyBack(value) : value,
              };
            }
            return subDoc;
          }),
        };
      }
      return doc;
    });
    setSelectedDocuments(newSelectedDocuments);
  };

  const deleteSubDocument = (rowIndex: number, subIndex: number) => {
    const newSelectedDocuments = selectedDocuments.map((doc: any, i: any) => {
      if (i === rowIndex) {
        const tempSubdocuments = [...tempSubDocumentPaymentDateRows];
        tempSubdocuments[rowIndex].splice(subIndex, 1);
        setTempSubDocumentPaymentDateRows(tempSubdocuments);
        return {
          ...doc,
          subDocuments: doc.subDocuments.filter(
            (subDoc: any, j: any) => j !== subIndex,
          ),
        };
      }
      return doc;
    });
    setSelectedDocuments(newSelectedDocuments);
  };
  const user = useSelector((state: { auth: AuthState }) => state.auth.user);

  useEffect(() => {
    tempPaymentDateRows.forEach((date: any, index: any) => {
      if (date === null) {
        setTempPaymentDateRows((prevState: any) => {
          const newState = [...prevState];
          newState[index] = ''; // O cualquier valor inicial que desees
          return newState;
        });
      }
    });
    tempSubDocumentPaymentDateRows.forEach((subDoc: any, index: any) => {
      subDoc.forEach((date: any, subIndex: any) => {
        if (date === null) {
          setTempSubDocumentPaymentDateRows((prevState: any) => {
            const newState = [...prevState];
            newState[index][subIndex] = ''; // O cualquier valor inicial que desees
            return newState;
          });
        }
      });
    });
  }, [tempPaymentDateRows, setTempPaymentDateRows]);

  const handleBackwardsValue = () => {
    if (exchangeDay) {
      setTempPaymentDateRows(
        selectedDocuments.map((doc: any) => formatDate(doc.dueDate)),
      );
      setTempSubDocumentPaymentDateRows(
        selectedDocuments.map((doc: any) =>
          doc.subDocuments
            ? doc.subDocuments.map((subDoc: any) => formatDate(subDoc.dueDate))
            : [],
        ),
      );
    } else {
      setTempPaymentDateRows(
        selectedDocuments.map((doc: any) => formatDate(doc.paymentDate)),
      );
      setTempSubDocumentPaymentDateRows(
        selectedDocuments.map((doc: any) =>
          doc.subDocuments
            ? doc.subDocuments.map((subDoc: any) =>
                formatDate(subDoc.paymentDate),
              )
            : [],
        ),
      );
    }
  };

  useEffect(() => {
    if (backwards) {
      handleBackwardsValue();
    }
  }, []);

  return (
    <TableContainer className={classes.tableContainer}>
      <Table className={classes.table}>
        <TableHead style={{ background: '#efefef' }}>
          {validateCompanyTypes(user, 'FinancingHub') && (
            <TableCell style={firstCellStyle}>Sub documentos</TableCell>
          )}
          <TableCell style={firstCellStyle}>Folio</TableCell>
          <TableCell
            className={classes.tableCell}
            style={{ textAlign: 'right' }}
          >
            Nombre deudor
          </TableCell>
          <TableCell
            className={classes.tableCell}
            style={{ textAlign: 'right' }}
          >
            Monto total
          </TableCell>
          {!user?.express && (
            <TableCell
              className={classes.tableCell}
              style={{ textAlign: 'right' }}
            >
              Monto disp.
            </TableCell>
          )}
          <TableCell
            className={classes.tableCell}
            style={{ textAlign: 'right' }}
          >
            Fecha emisión
          </TableCell>
          <TableCell
            className={classes.tableCell}
            style={{ textAlign: 'right' }}
          >
            Fecha vencimiento
          </TableCell>
          {exchangeDay && (
            <TableCell
              className={classes.tableCell}
              style={{ textAlign: 'right' }}
            >
              Fecha canje
            </TableCell>
          )}
          <TableCell
            className={classes.tableCell}
            style={{ textAlign: 'center' }}
          >
            Días
          </TableCell>
        </TableHead>
        <TableBody className={classes.tableBody}>
          {selectedDocuments.flatMap((row: any, rowIndex: number) => {
            let subDocumentRows = [];
            const isExpanded = !!expandedRows[rowIndex];
            const hasSubDocuments =
              row.subDocuments && row.subDocuments.length > 0;
            const parentRow = (
              <TableRow key={rowIndex}>
                {validateCompanyTypes(user, 'FinancingHub') && (
                  <TableCell style={firstCellStyle}>
                    <IconButton onClick={() => addSubDocument(rowIndex)}>
                      <Add />
                    </IconButton>
                    {row.subDocuments &&
                      row.subDocuments.length > 0 &&
                      row.subDocuments.length}{' '}
                    <IconButton
                      onClick={() => toggleRowExpansion(rowIndex)}
                      aria-label="expand row"
                      size="small"
                    >
                      {row.subDocuments && row.subDocuments.length > 0 ? (
                        expandedRows[rowIndex] ? (
                          <ExpandLess />
                        ) : (
                          <ExpandMore />
                        )
                      ) : null}
                    </IconButton>
                  </TableCell>
                )}

                <TableCell style={firstCellStyle}>{row.folio}</TableCell>
                <TableCell
                  className={classes.tableCell}
                  style={{ textAlign: 'right' }}
                >
                  {row.stakeholderName}
                </TableCell>
                <TableCell
                  className={classes.tableCell}
                  style={{ textAlign: 'right' }}
                >
                  {formatMoney(row.amount)}
                </TableCell>
                {!user?.express && (
                  <TableCell
                    className={classes.tableCell}
                    style={{ textAlign: 'right' }}
                  >
                    {formatMoney(row.amountAvailable)}
                  </TableCell>
                )}
                <TableCell
                  className={classes.tableCell}
                  style={{ textAlign: 'right' }}
                >
                  {formatDate(row.issuedDate, 'dd-mm-yyyy')}
                </TableCell>
                <TableCell
                  className={classes.tableCell}
                  style={{ textAlign: 'right' }}
                >
                  {hasSubDocuments ? null : (
                    <TextField
                      value={
                        exchangeDay
                          ? row.dueDate
                            ? formatDate(row.dueDate)
                            : null
                          : tempPaymentDateRows[rowIndex]
                      }
                      type="date"
                      onChange={
                        exchangeDay
                          ? (e) => {
                              handleChangeDateExchange(
                                e.target.value,
                                rowIndex,
                              );
                            }
                          : (e) =>
                              setTempPaymentDateRows(
                                tempPaymentDateRows.map((value: any, i: any) =>
                                  i === rowIndex ? e.target.value : value,
                                ),
                              )
                      }
                      onBlur={() =>
                        exchangeDay
                          ? null
                          : handleChangeDateNormal(
                              tempPaymentDateRows[rowIndex],
                              rowIndex,
                            )
                      }
                    />
                  )}
                </TableCell>
                {exchangeDay && (
                  <TableCell
                    className={classes.tableCell}
                    style={{ textAlign: 'right' }}
                  >
                    {hasSubDocuments
                      ? null
                      : formatDate(row.paymentDate, 'dd-mm-yyyy')}
                  </TableCell>
                )}
                <TableCell
                  className={classes.tableCell}
                  style={{ textAlign: 'center' }}
                >
                  {row.daysToCount}
                </TableCell>
              </TableRow>
            );

            if (isExpanded && hasSubDocuments) {
              subDocumentRows = row.subDocuments.map(
                (subDoc: any, subIndex: number) => (
                  <TableRow key={`sub-${rowIndex}-${subIndex}`}>
                    {/* Add an offset or empty cell if you want to visually indent sub-documents */}
                    <TableCell>
                      <IconButton
                        onClick={() => deleteSubDocument(rowIndex, subIndex)}
                      >
                        <Delete />
                      </IconButton>
                    </TableCell>
                    <TableCell
                      className={classes.tableCell}
                      style={{ textAlign: 'right' }}
                    >
                      {/* Assuming subDoc.detail exists; adjust according to your data structure */}
                      <TextField
                        value={subDoc.folio}
                        type="number"
                        label="Folio"
                        onChange={(e) =>
                          handleChangeSubDocument(
                            e.target.value,
                            'folio',
                            rowIndex,
                            subIndex,
                          )
                        }
                      />
                    </TableCell>
                    <TableCell
                      className={classes.tableCell}
                      style={{ textAlign: 'right' }}
                    >
                      {subDoc.stakeholderName}{' '}
                    </TableCell>
                    <TableCell style={{ textAlign: 'right' }}> - </TableCell>

                    <TableCell
                      className={classes.tableCell}
                      style={{ textAlign: 'right' }}
                    >
                      {' '}
                      <TextField
                        value={formatMoney(subDoc.amount)}
                        inputProps={{ style: { textAlign: 'right' } }}
                        label="Monto"
                        onChange={(e) =>
                          handleChangeSubDocument(
                            e.target.value,
                            'amount',
                            rowIndex,
                            subIndex,
                          )
                        }
                      />
                    </TableCell>
                    <TableCell
                      className={classes.tableCell}
                      style={{ textAlign: 'right' }}
                    >
                      {formatDate(subDoc.issuedDate)}
                    </TableCell>
                    <TableCell
                      className={classes.tableCell}
                      style={{ textAlign: 'right' }}
                    >
                      <TextField
                        value={
                          exchangeDay
                            ? subDoc.dueDate
                              ? subDoc.dueDate.split('T')[0]
                              : null
                            : tempSubDocumentPaymentDateRows[rowIndex][subIndex]
                        }
                        type="date"
                        onBlur={() =>
                          exchangeDay
                            ? null
                            : handleChangeSubDocumentDateNormal(
                                tempSubDocumentPaymentDateRows[rowIndex][
                                  subIndex
                                ],
                                rowIndex,
                                subIndex,
                              )
                        }
                        onChange={(e) =>
                          exchangeDay
                            ? handleChangeSubDocumentDateExchange(
                                e.target.value,
                                rowIndex,
                                subIndex,
                              )
                            : setTempSubDocumentPaymentDateRows(
                                tempSubDocumentPaymentDateRows.map(
                                  (value: any, i: any) =>
                                    i === rowIndex
                                      ? value.map((val: any, j: any) =>
                                          j === subIndex ? e.target.value : val,
                                        )
                                      : value,
                                ),
                              )
                        }
                      />
                    </TableCell>
                    {exchangeDay && (
                      <TableCell
                        className={classes.tableCell}
                        style={{ textAlign: 'right' }}
                      >
                        {formatDate(subDoc.paymentDate, 'dd-mm-yyyy')}
                      </TableCell>
                    )}
                  </TableRow>
                ),
              );
            }
            // Combine the parent row with its sub-document rows
            return [parentRow, ...subDocumentRows];
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default DocumentsTable;

import {
  Grid,
  TextField,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import CustomButton from '../../../../../../../../lib/designSystem/Button';
import { useDispatch } from 'react-redux';
import { postCollectionManagement } from '../../../../../../../../lib/api';
import {
  getCollectionManagementOptionsByStateId,
  getCollectionManagementStates,
} from '../../../../../../../../lib/api/collectionManagementStates';
import CenteredCircularProgress from '../../../../../../../../lib/designSystem/CircularProgress';

interface propsType {
  openModal: boolean;
  setOpenModal: any;
  invoiceId: string;
  setOpenAlert: any;
  setInvoiceId: any;
  folioNumber: number;
  setFolioNumber: any;
  companyId: number;
  setCompanyId: any;
}

interface CollectionState {
  collectionManagementOptions: any[];
  companyId: number;
  createdAt: string;
  id: number;
  name: string;
  updatedAt: string;
}

const NewManagement: React.FC<propsType> = (props: propsType) => {
  const [option, setOption] = useState('');
  const [description, setDescription] = useState('');
  const [checkFields, setCheckField] = useState(false);
  const [options, setOptions] = useState([]);
  const [disabled, setDisabled] = useState(true);
  const [stateId, setStateId] = useState<number>(0);
  const [stateName, setStateName] = useState('');
  const [stateOptions, setStateOptions] = useState<CollectionState[]>([]);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const checkInputFields = () => {
    if (description === '' || option === '') {
      return true;
    }
    return false;
  };

  const getOptions = async (selectedStateId: number) => {
    setLoading(true);
    const result = await getCollectionManagementOptionsByStateId(
      dispatch,
      selectedStateId,
    );
    setOptions(result);
    setLoading(false);
  };

  const getStatesOptions = async () => {
    const result = await getCollectionManagementStates(dispatch);
    setStateOptions(result);
  };

  const handleSelectState = async (value: number) => {
    setDisabled(true);
    setStateId(value);
    const selectedState = stateOptions.find((element) => element.id === value);
    setStateName(selectedState ? selectedState.name : '');
    await getOptions(value);
    setDisabled(false);
  };

  useEffect(() => {
    setDisabled(true);
    setStateId(0);
    setStateName('');
    setOption('');
    setDescription('');
    setCheckField(false);
    getStatesOptions();
    setDisabled(false);
  }, [props.openModal]);

  const handleSend = async () => {
    setCheckField(checkInputFields());
    if (checkInputFields()) return;
    await postCollectionManagement(dispatch, {
      description: description,
      type: option,
      documentId: props.invoiceId,
      companyId: props.companyId,
      state: stateName,
    });
    props.setOpenModal(false);
    props.setOpenAlert(true);
    location.reload();
  };

  const handleClose = () => {
    props.setInvoiceId({});
    props.setFolioNumber(0);
    props.setOpenModal(false);
  };

  if (loading) return <CenteredCircularProgress type="dialog" />;

  return (
    <Dialog open={props.openModal} onClose={() => handleClose()}>
      <DialogTitle>
        {' '}
        Crear nueva gestión - Folio #{props.folioNumber}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Para crear una nueva gestión debes completar la siguiente información
        </DialogContentText>
        <Grid container spacing={2} style={{ paddingTop: '2%' }}>
          <Grid item xs={6}>
            <TextField
              error={checkFields && option === ''}
              select
              value={stateId}
              label="Estado gestión"
              variant="outlined"
              fullWidth
              onChange={(e) =>
                handleSelectState(e.target.value as unknown as number)
              }
              disabled={disabled}
            >
              {stateOptions.map((element: CollectionState) => {
                return (
                  <MenuItem key={element.id} value={element.id}>
                    {element.name}
                  </MenuItem>
                );
              })}
            </TextField>
          </Grid>
          <Grid item xs={6}>
            <TextField
              error={checkFields && option === ''}
              select
              value={option}
              label="Tipo de gestión"
              variant="outlined"
              fullWidth
              onChange={(e) => setOption(e.target.value)}
              disabled={disabled}
            >
              {options.map((element: { name: string }) => {
                return (
                  <MenuItem key={element.name} value={element.name}>
                    {element.name}
                  </MenuItem>
                );
              })}
            </TextField>
          </Grid>
        </Grid>
        <div style={{ paddingTop: '2%' }}>
          <TextField
            error={checkFields && description === ''}
            id="outlined-basic"
            label="Observación"
            variant="outlined"
            fullWidth
            multiline
            onChange={(e) => setDescription(e.target.value)}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <CustomButton color="secondary" onClick={() => handleClose()}>
          {' '}
          Cancelar{' '}
        </CustomButton>
        <CustomButton color="primary" onClick={() => handleSend()}>
          {' '}
          Crear
        </CustomButton>
      </DialogActions>
    </Dialog>
  );
};

export default NewManagement;

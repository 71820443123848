import { requestHandler } from '../../api';

export const toggleUser = async (
  dispatch: any,
  userId: number,
  active: boolean,
) => {
  const response = await requestHandler({
    method: 'patch',
    url: `internal/users/${userId}/toggle`,
    dispatch,
    data: { active },
  });
  return response;
};

export const assignSubordinate = async (
  dispatch: any,
  userId: number,
  subordinates: number[],
) => {
  const response = await requestHandler({
    method: 'post',
    url: `internal/users/${userId}/subordinate/`,
    dispatch,
    data: { subordinates },
  });
  return response;
};

export const getSubordinates = async (dispatch: any, userId: number) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/users/${userId}/subordinate/`,
    dispatch,
  });
  return response;
};

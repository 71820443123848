// LoginForm.tsx
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { authenticate } from '../../services/redux/authSlice'; // Import the action
import { authenticateUser } from '../../lib/api';
import Avatar from '@mui/material/Avatar';
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Container from '@mui/material/Container';
import Login from './Main/Login/index';
import CenteredCircularProgress from '../../lib/designSystem/CircularProgress';
import Logo from '../../assets/img/pruff9.svg';

const containerStyle = {
  // backgroundImage: 'url(https://grupohimmel.cl/wp-content/uploads/2022/10/Monte-Financiero.jpg)',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  height: '100vh', // Adjust the height as needed
  backgroundColor:
    'linear-gradient(90deg, #2970FF 0.2%, #0040C1 99.99%) !important',
};

const LoginForm = () => {
  const [loading, setLoading] = useState(false);

  return (
    <div>
      <Grid container>
        <Grid
          item
          xs={0}
          md={5}
          style={{
            background: 'linear-gradient(to bottom, #0F23DF, #0F23DF, #0F23DF)',
            opacity: 0.8,
            height: '100vh',
            display: 'flex',
          }}
        >
          {/* Content goes here */}
        </Grid>
        <Grid item xs={12} md={7}>
          {loading ? (
            <CenteredCircularProgress type="login" />
          ) : (
            <Grid>
              <Grid>
                <Container component="main">
                  <CssBaseline />
                  <Box
                    sx={{
                      marginTop: 8,
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                    }}
                  >
                    <div style={{ padding: '80px 15px 40px' }}>
                      <img width={230} src={Logo} />
                    </div>
                    <Box>
                      <Login />
                    </Box>
                  </Box>
                </Container>
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default LoginForm;

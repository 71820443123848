const months: { [key: string]: number } = {
  Enero: 1,
  Febrero: 2,
  Marzo: 3,
  Abril: 4,
  Mayo: 5,
  Junio: 6,
  Julio: 7,
  Agosto: 8,
  Septiembre: 9,
  Octubre: 10,
  Noviembre: 11,
  Diciembre: 12,
};

const orderedMonths = [
  'Enero',
  'Febrero',
  'Marzo',
  'Abril',
  'Mayo',
  'Junio',
  'Julio',
  'Agosto',
  'Septiembre',
  'Octubre',
  'Noviembre',
  'Diciembre',
];

function capitalize(str: string): string {
  if (str.length === 0) {
    return str;
  }
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
}

function isCurrentYear(month: number, year: number): boolean {
  if (year === new Date().getFullYear()) {
    return true;
  } else {
    if (
      year === new Date().getFullYear() - 1 &&
      month > new Date().getMonth() + 1
    ) {
      return true;
    }
    return false;
  }
}

export const chartCalculator = (
  data: any,
  identifiers: string[],
  all?: boolean,
) => {
  const result: { [key: string]: any } = data.dataPayers;
  const finalResult: { [key: string]: any } = {};
  for (const [key, value] of Object.entries(result)) {
    if (
      key === 'Últimos 3M' ||
      key === 'Últimos 6M' ||
      key === 'Últimos 12M' ||
      key === 'Últimos 24M'
    ) {
      continue;
    }
    const month = capitalize(key.split('-')[0]);
    const year = parseInt(key.split('-')[1]);
    if (!finalResult[month]) {
      finalResult[month] = {
        'Compras 12-24M': 0,
        'Compras últimos 12M': 0,
        'Ventas 12-24M': 0,
        'Ventas últimos 12M': 0,
        'NC/Reclamo 12-24M': 0,
        'NC/Reclamo últimos 12M': 0,
      };
    }
    for (const entry of value) {
      if (identifiers.includes(entry.identifier) || all) {
        if (isCurrentYear(months[month], year)) {
          finalResult[month]['Compras últimos 12M'] += entry.buyAmount;
          finalResult[month]['Ventas últimos 12M'] += entry.salesAmount;
          finalResult[month]['NC/Reclamo últimos 12M'] +=
            entry.salesCreditNoteAmount;
        } else {
          finalResult[month]['Compras 12-24M'] += entry.buyAmount;
          finalResult[month]['Ventas 12-24M'] += entry.salesAmount;
          finalResult[month]['NC/Reclamo 12-24M'] +=
            entry.salesCreditNoteAmount;
        }
      }
    }
  }
  // finalResilt to an array
  const finalResultArray: any[] = Object.entries(finalResult).map(
    ([key, value]) => {
      return {
        name: key,
        ...value,
      };
    },
  );

  const currentMonth = new Date().getMonth() + 1;
  const currentMonthIndex = orderedMonths.indexOf(
    orderedMonths[currentMonth - 1],
  );
  const currentMonthsOrder = orderedMonths.slice(currentMonthIndex + 1);
  const previousMonthsOrder = orderedMonths.slice(0, currentMonthIndex + 1);
  const orderedMonthsArray = currentMonthsOrder.concat(previousMonthsOrder);
  finalResultArray.sort((a, b) => {
    return (
      orderedMonthsArray.indexOf(a.name) - orderedMonthsArray.indexOf(b.name)
    );
  });
  return finalResultArray;
  /*
  const filteredData = data.filter((item: any) => {
    return filter.includes('gola');
  });
  */
};

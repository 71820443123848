import React, { useEffect, useState } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination,
  IconButton,
  Menu,
  MenuItem,
  TableSortLabel,
  Box,
  TableContainer,
  Tooltip,
  Checkbox,
  Typography,
  TextField,
  Badge,
  CircularProgress,
  Skeleton,
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Theme } from '@mui/material/styles';
import { makeStyles, createStyles } from '@mui/styles';
import { formatMoney } from '../../functions/utils';
import { visuallyHidden } from '@mui/utils';
import { cellFormat } from '../Table/cellFormat';
import { CancelOutlined, FilterList } from '@mui/icons-material';
import CustomButton from '../Button';
import DataExporter from '../DataExporter';

type RowData = {
  [key: string]: any;
};

type Action = {
  action: (number: any) => void;
  actionLabel: string;
  canShow?: any;
  notifications?: boolean;
};

export type Header = {
  label: string;
  key: string;
  type?: string;
  canNull?: boolean;
  handleChange?: any;
  handleAction?: any;
  icon?: any;
  onClick?: any;
  keyDisabled?: string;
};

interface EnhancedTableProps {
  data: RowData[];
  setData: any;
  actions?: Action[];
  caption?: string;
  dataHeaders: Header[];
  tableSize?: 'small' | 'medium';
  showActions?: boolean;
  finalSumRow?: boolean;
  filters?: {
    key: string;
    label: string;
    options?: string[];
    startDate?: any;
    endDate?: any;
    setStartDate?: any;
    setEndDate?: any;
    type?: string;
  }[];
  loading?: boolean;
  rowsPerPageDefault?: number;
  openFilters?: any;
  setOpenFilters?: any;
  selected?: any;
  setSelected?: any;
  cantSelectRow?: any;
  updateValues?: any;
  completeTable?: boolean;
  handleClickRow?: any;
  canSelectAll?: boolean;
  filterOptions?: any;
  buttons?: any;
  filterButton?: boolean;
  exportToFile?: 'csv' | 'xlsx';
  searchData?: any;
  pagination: {
    total: number;
    page: number;
    pageSize: number;
    totalPages: number;
  };
  setPagination: any;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {
      width: '100%', // Asegura que la tabla se ajuste a su contenedor
      tableLayout: 'fixed', // Permite que las columnas se ajusten automáticamente
    },
    tableContainer: {
      border: '1px solid #e0e0e0',
      borderRadius: '10px',
      maxWidth: '100%',
      overflowX: 'auto', // Permite el desplazamiento horizontal si es necesario
      display: 'block', // Asegura que el contenedor maneje correctamente el overflow
    },
    tableBody: {
      backgroundColor: '#ffffff',
    },
  }),
);
type Order = 'asc' | 'desc';

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  // Check if a[orderBy] is null and b[orderBy] is not
  if (a[orderBy] === null && b[orderBy] !== null) {
    return 1; // a is less than b
  }

  // Check if b[orderBy] is null and a[orderBy] is not
  if (b[orderBy] === null && a[orderBy] !== null) {
    return -1; // a is greater than b
  }

  // If both are null or both are non-null, perform the usual comparison
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }

  return 0;
}

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key,
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string },
) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(
  array: readonly T[],
  comparator: (a: T, b: T) => number,
) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}
interface EnhancedTableHead {
  onRequestSort: (event: React.MouseEvent<unknown>, property: any) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
  headCells: any;
  showActions: any;
  selected?: any;
  handleSelectAllClick?: any;
  selectedAll?: any;
  setSelectedAll?: any;
}

function EnhancedTableHead(props: EnhancedTableHead) {
  const {
    headCells,
    order,
    orderBy,
    rowCount,
    onRequestSort,
    showActions,
    selected,
    handleSelectAllClick,
    selectedAll,
    setSelectedAll,
  } = props;
  const createSortHandler =
    (property: any) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead style={{ background: '#efefef' }}>
      <TableRow>
        {selected && !handleSelectAllClick && (
          <TableCell align="right"></TableCell>
        )}
        {selected && handleSelectAllClick && (
          <TableCell align="left">
            <Checkbox
              style={{ padding: 0, marginLeft: '-3px' }}
              color="primary"
              checked={selectedAll}
              onClick={(e) => handleSelectAllClick(e)}
            />
          </TableCell>
        )}
        {headCells.map((headCell: any) => (
          <TableCell
            key={headCell.key}
            align={headCell.numeric ? 'right' : 'left'}
            style={{ padding: '8px 12px' }}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.key}
              direction={orderBy === headCell.key ? order : 'asc'}
              onClick={createSortHandler(headCell.key)}
            >
              {headCell.label}
              {orderBy === headCell.key ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        {showActions ? <TableCell align="right"></TableCell> : null}
      </TableRow>
    </TableHead>
  );
}

const EnhacedTableBody = (props: any) => {
  const {
    visibleRows,
    dataHeaders,
    selected,
    cantSelectRow,
    handleClickRow,
    actions,
    finalSumRow,
    handleClick,
    anchorEls,
    openMenus,
    completeTable,
    pagination,
    tableSize,
    openTooltipIndex,
    setOpenTooltipIndex,
    classes,
    firstCellStyle,
    data,
    setOpenMenus,
    setAnchorEls,
    setSelected,
    updateValues,
  } = props;

  const handleClickSelected = (id: number, row: any) => {
    if (cantSelectRow && cantSelectRow(row)) return;

    const selectedIndex = selected.indexOf(id);
    let newSelected: readonly number[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    if (updateValues) {
      updateValues(newSelected);
    }
    setSelected(newSelected);
  };
  const isSelected = (id: number) => selected?.indexOf(id) !== -1;
  const [selectedRow, setSelectedRow] = useState<any>([]);

  const handleSelectRow = (id: number | string) => {
    if (selectedRow === id) {
      return setSelectedRow(null);
    }
    setSelectedRow(id);
  };

  const handleClose = (index: number) => {
    // Create a copy of the openMenus array and set the clicked menu to close
    const newOpenMenus = [...openMenus];
    newOpenMenus[index] = false;

    setOpenMenus(newOpenMenus);
    setAnchorEls(anchorEls.map(() => null)); // Reset all anchorEls to null
  };

  function calculateSums(
    data: RowData[],
    headers: Header[],
  ): { [key: string]: any } {
    const sums: { [key: string]: any } = {};

    headers.forEach((header, index) => {
      sums[header.key] = data.reduce((acc, row) => {
        if (typeof row[header.key] === 'number') {
          return acc + row[header.key];
        }
        return acc;
      }, 0);
    });

    return sums;
  }

  const sums = calculateSums(data, dataHeaders);

  return (
    <TableBody className={classes.tableBody}>
      {visibleRows().map((row: any, index: any) => {
        const isItemSelected = isSelected(row.id as number);
        const haveHandleChange = dataHeaders.some((x: any) => x.handleChange);
        return (
          <TableRow
            key={index}
            style={{
              ...(handleClickRow ? { cursor: 'pointer' } : {}),
              ...(selectedRow === row.id ? { backgroundColor: '#808080' } : {}),
            }}
            onClick={() => {
              if (selected && !haveHandleChange) {
                handleClickSelected(row.id as number, row);
              }
              if (handleClickRow) {
                handleClickRow(row.id);
                handleSelectRow(row.id);
              }
            }}
          >
            {selected && (
              <TableCell padding="checkbox">
                {cantSelectRow && cantSelectRow(row) ? (
                  <CancelOutlined
                    color="disabled"
                    style={{ padding: '6px 10px' }}
                  />
                ) : (
                  <Checkbox
                    color="primary"
                    checked={isItemSelected}
                    onClick={() => handleClickSelected(row.id as number, row)}
                  />
                )}
              </TableCell>
            )}
            {dataHeaders.map((x: any, rowIndex: any) => {
              return (
                <Tooltip
                  key={rowIndex}
                  title={!x.type ? row[x.key] : ''}
                  open={openTooltipIndex === `${index}-${rowIndex}`}
                  onClose={() => setOpenTooltipIndex(null)}
                  PopperProps={{
                    style: { maxWidth: '1000px', whiteSpace: 'normal' }, // This allows the tooltip to expand as needed
                  }}
                >
                  <TableCell
                    style={{
                      ...firstCellStyle,
                      ...(x.onClick ? { cursor: 'pointer' } : {}),
                    }}
                    onMouseEnter={() => {
                      setOpenTooltipIndex(`${index}-${rowIndex}`);
                    }}
                    onMouseLeave={() => setOpenTooltipIndex(null)} // Close the tooltip when the mouse leaves
                    onClick={() => x.onClick && x.onClick(x.key, row.id)}
                  >
                    {x.handleAction ? (
                      <IconButton
                        disabled={
                          x.keyDisabled
                            ? row[x.keyDisabled]
                              ? false
                              : true
                            : false
                        }
                        onClick={() => x.handleAction(row.id)}
                      >
                        {x.icon}
                      </IconButton>
                    ) : null}
                    {x.handleChange ? (
                      <TextField
                        value={
                          x.type === 'money'
                            ? formatMoney(row[x.key] as number)
                            : row[x.key]
                        }
                        id={String(row.id)}
                        name={String(row.id)}
                        onChange={(e) => x.handleChange(e)}
                      />
                    ) : (
                      cellFormat(
                        row[x.key],
                        row.rowType ? row.rowType : x.type,
                        x.canNull,
                      )
                    )}
                  </TableCell>
                </Tooltip>
              );
            })}
            {actions.length ? (
              <TableCell
                style={firstCellStyle}
                key={index}
                size={tableSize}
                align="right"
              >
                <IconButton onClick={(event) => handleClick(index, event)}>
                  <Badge badgeContent={row.notifications} color="error">
                    <MoreVertIcon style={{ color: 'black' }} />
                  </Badge>
                </IconButton>
                <Menu
                  anchorEl={anchorEls[index]}
                  open={openMenus[index]}
                  onClose={() => handleClose(index)}
                  onClick={() => handleClose(index)}
                >
                  {actions.map((x: any, index: any) => {
                    if (x.canShow) {
                      if (x.canShow(row.id)) {
                        return (
                          <MenuItem
                            key={index}
                            onClick={() => {
                              x.action(row.id as number);
                            }}
                          >
                            <Badge
                              badgeContent={
                                x.notifications ? row.notifications : null
                              }
                              color="error"
                            >
                              {x.actionLabel}{' '}
                            </Badge>
                          </MenuItem>
                        );
                      }
                    } else {
                      return (
                        <MenuItem
                          key={index}
                          onClick={() => x.action(row.id as number)}
                        >
                          {x.actionLabel}{' '}
                        </MenuItem>
                      );
                    }
                  })}
                </Menu>
              </TableCell>
            ) : null}
          </TableRow>
        );
      })}
      {completeTable &&
        Array.from({
          length: pagination.pageSize - visibleRows().length,
        }).map((_, index) => (
          <TableRow key={`empty-${index}`}>
            {selected && <TableCell />}{' '}
            {/* Include this if you have a checkbox column */}
            {dataHeaders.map((_: any, cellIndex: any) => (
              <TableCell
                key={`empty-cell-${cellIndex}`}
                style={{ border: 0 }}
              />
            ))}
            {actions.length ? <TableCell style={{ border: 0 }} /> : null}{' '}
            {/* Include this if you have an actions column */}
          </TableRow>
        ))}
      {finalSumRow && (
        <TableRow style={{ borderTop: '1.5px solid' }}>
          <TableCell size={tableSize}>
            <b>Total</b>
          </TableCell>
          {selected && <TableCell size={tableSize}>-</TableCell>}
          {dataHeaders.slice(1).map((header: any, index: any) => (
            <TableCell key={index} size={tableSize}>
              {typeof sums[header.key] === 'number' && header.type === 'money'
                ? formatMoney(sums[header.key])
                : ''}
            </TableCell>
          ))}
          {actions.length ? <TableCell align="right"></TableCell> : null}{' '}
          {/* empty cell if there are actions */}
        </TableRow>
      )}
    </TableBody>
  );
};

const LoadingTableBody = (props: any) => {
  const { pagination, dataHeaders } = props;

  return (
    <TableBody>
      {[...Array(pagination.pageSize)].map((_, index) => (
        <TableRow key={`loading-${index}`}>
          {dataHeaders.map((_: any, cellIndex: any) => (
            <TableCell key={`loading-cell-${cellIndex}`}>
              <Skeleton animation="wave" variant="text" height={9} />
            </TableCell>
          ))}
        </TableRow>
      ))}
    </TableBody>
  );
};

const TableWithPagination: React.FC<EnhancedTableProps> = ({
  data,
  setData,
  caption,
  tableSize = 'medium',
  dataHeaders = [],
  actions = [],
  finalSumRow = false,
  filters = [],
  rowsPerPageDefault = 5,
  loading,
  selected,
  setSelected,
  cantSelectRow,
  // setOpenFilters,
  updateValues,
  completeTable,
  handleClickRow,
  canSelectAll,
  buttons,
  exportToFile,
  searchData,
  pagination,
  setPagination,
  // filterOptions,
}) => {
  const [openMenus, setOpenMenus] = React.useState(data.map(() => false));
  const [anchorEls, setAnchorEls] = React.useState<(HTMLElement | null)[]>(
    data.map(() => null),
  );
  const [dataAvailable, setDataAvailable] = useState(data);
  const classes = useStyles();
  const [order, setOrder] = React.useState<any>('');
  const [orderBy, setOrderBy] = React.useState<any>('');
  const [openTooltipIndex, setOpenTooltipIndex] = useState<string | null>(null);
  const [selectedAll, setSelectedAll] = useState(false);

  const firstCellStyle: any = {
    maxWidth: '150px',
    overflow: 'hidden', // Ensure the content doesn't overflow.
    textOverflow: 'ellipsis', // If the text overflows, it will end with "..."
    whiteSpace: 'nowrap', // Ensure the text doesn't wrap to the next line.
    padding: '4px 12px',
    margin: '0px',
  };

  const handleClick = (
    index: number,
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    // Create a copy of the openMenus array and set the clicked menu to open
    const newOpenMenus = [...openMenus];
    newOpenMenus[index] = true;

    // Use optional chaining to safely access event.currentTarget
    const anchorEl = event.currentTarget;

    setOpenMenus(newOpenMenus);
    setAnchorEls((prevAnchorEls: any) => {
      // Create a copy of the anchorEls array and set the anchorEl for the clicked menu
      const newAnchorEls: HTMLElement[] | null[] = [...prevAnchorEls];
      newAnchorEls[index] = anchorEl;
      return newAnchorEls;
    });
  };

  const handleChangePage = async (event: unknown, newPage: number) => {
    try {
      const newDataObject = await searchData({
        page: newPage + 1,
        pageSize: pagination.pageSize,
      });
      setPagination(newDataObject.pagination);
      setData(newDataObject.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleChangeRowsPerPage = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const newPageSize = parseInt(event.target.value, 10);
    try {
      const newDataObject = await searchData({
        page: 1,
        pageSize: newPageSize,
      });
      setPagination(newDataObject.pagination);
      setData(newDataObject.data);
    } catch (error) {
      console.log(error);
    }
  };

  const visibleRowsCache = React.useMemo(
    () =>
      stableSort(dataAvailable, getComparator(order, orderBy)).slice(
        pagination.page * pagination.pageSize,
        pagination.page * pagination.pageSize + pagination.pageSize,
      ),
    [dataAvailable, order, orderBy, pagination.page, pagination.pageSize],
  );

  const visibleRows = () =>
    stableSort(dataAvailable, getComparator(order, orderBy));

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: any,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };
  useEffect(() => {
    setDataAvailable(data);
  }, [data]);

  const handleSelectAllClick = (event: any) => {
    if (event.target.checked) {
      const newSelected = (
        filters.length ? visibleRows() : visibleRowsCache
      ).map((n) => n.id);
      setSelected(newSelected);
      setSelectedAll(true);
      return;
    }
    setSelected([]);
    setSelectedAll(false);
  };

  return (
    <div>
      <div
        style={
          caption
            ? {
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: filters.length ? '0px 0px' : '20px 0px',
              }
            : {
                display: 'flex',
                justifyContent: 'flex-end',
                marginBottom: '0px',
              }
        }
      >
        {caption && <Typography variant="body1">{caption}</Typography>}
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            paddingBottom: buttons?.length ? 20 : 0,
          }}
        >
          {buttons?.map((x: any, index: any) => (
            <>
              {x.customButton && <>{x.customButton}</>}
              {!x.customButton && (
                <div key={index} style={{ paddingLeft: '10px' }}>
                  <CustomButton
                    variant="contained"
                    color={x.color}
                    onClick={x.action}
                  >
                    {x.name}
                  </CustomButton>
                </div>
              )}
            </>
          ))}
        </div>
      </div>
      <TableContainer
        className={classes.tableContainer}
        style={{ width: '100%' }}
      >
        <Table className={classes.table} style={{ borderRadius: '10px' }}>
          <EnhancedTableHead
            headCells={dataHeaders}
            selected={selected}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            rowCount={dataAvailable.length}
            showActions={actions.length}
            handleSelectAllClick={canSelectAll ? handleSelectAllClick : null}
            selectedAll={selectedAll}
            setSelectedAll={setSelectedAll}
          />
          {loading ? (
            <LoadingTableBody
              pagination={pagination}
              dataHeaders={dataHeaders}
            />
          ) : (
            <EnhacedTableBody
              visibleRows={visibleRows}
              dataHeaders={dataHeaders}
              selected={selected}
              cantSelectRow={cantSelectRow}
              handleClickRow={handleClickRow}
              actions={actions}
              finalSumRow={finalSumRow}
              handleClick={handleClick}
              anchorEls={anchorEls}
              openMenus={openMenus}
              completeTable={completeTable}
              pagination={pagination}
              tableSize={tableSize}
              openTooltipIndex={openTooltipIndex}
              setOpenTooltipIndex={setOpenTooltipIndex}
              classes={classes}
              firstCellStyle={firstCellStyle}
              data={data}
              setOpenMenus={setOpenMenus}
              setAnchorEls={setAnchorEls}
              setSelected={setSelected}
              updateValues={updateValues}
            />
          )}
        </Table>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            background: '#efefef',
          }}
        >
          <div style={{ paddingLeft: '10px' }}>
            {exportToFile && (
              <DataExporter
                buttonColor="transparent"
                data={data}
                fileType={exportToFile}
                headers={dataHeaders}
              />
            )}
          </div>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 100, 250]}
            component="div"
            count={pagination.total}
            rowsPerPage={pagination.pageSize}
            page={pagination.page - 1}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage="Filas por página:" // This sets the label to Spanish
            labelDisplayedRows={({ from, to, count }) =>
              `${from}-${to} de ${count !== -1 ? count : `más de ${to}`}`
            }
          />
        </div>
      </TableContainer>
    </div>
  );
};

export default TableWithPagination;

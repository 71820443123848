import {
  DialogContent,
  Grid,
  PopperPlacementType,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import DocumentsTable from './components/DocumentsTable';
import PopperPayers from './components/PopperPayers';
import PopperUniqueDate from './components/PopperUniqueDate';
import { getCompanyConfig } from '../../../../../../../../../lib/api/company';
import { useDispatch } from 'react-redux';
import CenteredCircularProgress from '../../../../../../../../../lib/designSystem/CircularProgress';

const Step1 = (props: any) => {
  const {
    selectedDocuments,
    requestPayers,
    setRequestPayers,
    setSelectedDocuments,
    exchangeDay,
    setTempPaymentDateRows,
    tempPaymentDateRows,
    setTempSubDocumentPaymentDateRows,
    tempSubDocumentPaymentDateRows,
    backwards,
  } = props;
  const [loading, setLoading] = useState(false);
  const [canForcePaymentDate, setCanForcePaymentDate] = useState(false);
  const [minimumDays, setMinimumDays] = useState(15);
  const [showPayers, setShowPayers] = useState(false);
  const [showUniqueDate, setShowUniqueDate] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null,
  );
  const [placement, setPlacement] =
    React.useState<PopperPlacementType>('right');

  const handleClickPopper =
    (newPlacement: PopperPlacementType) =>
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
      setShowPayers((prev) => placement !== newPlacement || !prev);
      setPlacement(newPlacement);
    };
  const handleClickPopperUniqueDate =
    (newPlacement: PopperPlacementType) =>
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
      setShowUniqueDate((prev) => placement !== newPlacement || !prev);
      setPlacement(newPlacement);
    };

  const dispatch = useDispatch();

  const fetchDateRestrictionsConfig = async () => {
    try {
      const response = await getCompanyConfig(
        dispatch,
        'system_operations_forcePaymentDate',
      );
      setCanForcePaymentDate(Number(response) === 1);
      const responseMinimumDays = await getCompanyConfig(
        dispatch,
        'system_operations_minimunDays',
      );
      console.log(responseMinimumDays);
      if (responseMinimumDays) setMinimumDays(Number(responseMinimumDays));
    } catch (error) {
      console.error('Error fetching date restrictions config', error);
    }
  };

  useEffect(() => {
    fetchDateRestrictionsConfig();
  }, []);

  if (loading) return <CenteredCircularProgress type="dialog" />;
  return (
    <DialogContent>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography padding="4px 0px " variant="h6">
          Documentos seleccionados:
        </Typography>
        <div>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography
                padding="4px 0px"
                minWidth={160}
                variant="body1"
                fontWeight="bold"
                style={{ cursor: 'pointer' }}
                onClick={handleClickPopper('right-start')}
                color="primary"
              >
                {showPayers ? 'Ocultar' : 'Fecha por pagador'}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography
                padding="4px 0px 0px 10px"
                variant="body1"
                textAlign={'right' as any}
                fontWeight="bold"
                style={{ cursor: 'pointer' }}
                onClick={handleClickPopperUniqueDate('right-start')}
                color="primary"
              >
                Fecha única
              </Typography>
            </Grid>
          </Grid>
        </div>
        <PopperPayers
          selectedDocuments={selectedDocuments}
          requestPayers={requestPayers}
          setRequestPayers={setRequestPayers}
          setSelectedDocuments={setSelectedDocuments}
          showPayers={showPayers}
          setShowPayers={setShowPayers}
          anchorEl={anchorEl}
          handleClickPopper={handleClickPopper}
          placement={placement}
          setTempPaymentDateRows={setTempPaymentDateRows}
          setTempSubDocumentPaymentDateRows={setTempSubDocumentPaymentDateRows}
          canForcePaymentDate={canForcePaymentDate}
          minimumDays={minimumDays}
        />
        <PopperUniqueDate
          open={showUniqueDate}
          setOpen={setShowUniqueDate}
          selectedDocuments={selectedDocuments}
          setSelectedDocuments={setSelectedDocuments}
          exchangeDay={exchangeDay}
          anchorEl={anchorEl}
          handleClickPopper={handleClickPopper}
          placement={placement}
          setTempPaymentDateRows={setTempPaymentDateRows}
          setTempSubDocumentPaymentDateRows={setTempSubDocumentPaymentDateRows}
          canForcePaymentDate={canForcePaymentDate}
          minimumDays={minimumDays}
        />
      </div>
      <DocumentsTable
        selectedDocuments={selectedDocuments}
        requestPayers={requestPayers}
        setRequestPayers={setRequestPayers}
        setSelectedDocuments={setSelectedDocuments}
        exchangeDay={exchangeDay}
        setTempPaymentDateRows={setTempPaymentDateRows}
        tempPaymentDateRows={tempPaymentDateRows}
        setTempSubDocumentPaymentDateRows={setTempSubDocumentPaymentDateRows}
        tempSubDocumentPaymentDateRows={tempSubDocumentPaymentDateRows}
        backwards={backwards}
        canForcePaymentDate={canForcePaymentDate}
        minimumDays={minimumDays}
      />
    </DialogContent>
  );
};

export default Step1;

import React, { useEffect, useState } from 'react';
import CenteredCircularProgress from '../../../../../lib/designSystem/CircularProgress';
import {
  deleteCertificate,
  getCertificates,
  selectCertificate,
} from '../../../../../lib/api/express';
import { useDispatch, useSelector } from 'react-redux';
import { AuthState } from '../../../../../services/redux/authSlice';
import HeaderWithActions, {
  CustomButtonProps,
} from '../../../../../lib/designSystem/HeaderWithActions';
import { useParams } from 'react-router-dom';
import EnhancedTable from '../../../../../lib/designSystem/Table';
import AddCertificate from './components/AddCertificate';
import CustomAlert from '../../../../../lib/designSystem/Alert';

const SettingsCertificates = (props: any) => {
  const { mainPage, subPage, clientId } = useParams<{
    mainPage: string;
    subPage: string;
    clientId: string;
  }>();
  const [loading, setLoading] = useState<boolean>(false);
  const [certificates, setCertificates] = useState<any[]>([]);
  const [addCertificate, setAddCertificate] = useState<boolean>(false);
  const [newSelectionId, setNewSelectionId] = useState<number | null>(null);
  const [selectedCertificate, setSelectedCertificate] = useState<any | null>(
    null,
  );
  const [failed, setFailed] = useState<string>('');
  const [success, setSuccess] = useState<string>('');
  const dispatch = useDispatch();
  const user = useSelector((state: { auth: AuthState }) => state.auth.user);

  const setUp = async () => {
    setLoading(true);
    try {
      const certificatesResponse = await getCertificates(
        dispatch,
        clientId as string,
      );
      setCertificates(certificatesResponse);
      const selectedCertificateFromResponse = certificatesResponse.find(
        (certificate: any) => certificate.selected,
      );
      setSelectedCertificate(selectedCertificateFromResponse);
    } catch (error) {}
    setLoading(false);
  };

  const handleCreate = () => {
    setAddCertificate(true);
  };

  useEffect(() => {
    setUp();
  }, []);

  const buttons: CustomButtonProps[] = [
    {
      name: 'Agregar',
      action: handleCreate,
      color: 'primary',
    },
  ];

  const handleSelect = async (id: number) => {
    setNewSelectionId(id);
    setLoading(true);
    try {
      const response = await selectCertificate(
        dispatch,
        clientId as string,
        id,
      );
      setSuccess('Certificado seleccionado correctamente');
      await setUp();
    } catch (error) {
      setFailed('No se pudo seleccionar el certificado');
    }
  };

  const handleDelete = async (id: number) => {
    setNewSelectionId(id);
    setLoading(true);
    try {
      const response = await deleteCertificate(
        dispatch,
        clientId as string,
        id,
      );
      setSuccess('Certificado eliminado correctamente');
      await setUp();
    } catch (error) {
      setFailed('No se pudo eliminadar el certificado');
    }
  };

  const canShowSelect = (id: number) => {
    const certificate = certificates.find(
      (certificate) => certificate.id === id,
    );
    return !certificate?.selected;
  };

  const canShowDelete = (id: number) => {
    const certificate = certificates.find(
      (certificate) => certificate.id === id,
    );
    return !certificate?.selected;
  };

  const actions = [
    {
      action: handleSelect,
      actionLabel: 'Seleccionar',
      canShow: canShowSelect,
    },
    {
      action: handleDelete,
      actionLabel: 'Eliminar',
    },
  ];

  if (loading) return <CenteredCircularProgress type="dialog" />;

  return (
    <div>
      <HeaderWithActions
        variant="body1"
        title="Acá podrás ver los certificados de tu empresa, puedes seleccionar el favorito para hacer las cesiones"
        buttons={buttons}
      />
      <EnhancedTable
        data={certificates}
        dataHeaders={[
          { key: 'taxName', label: 'Nombre' },
          { key: 'taxIdentifier', label: 'Rut' },
          { key: 'valid', label: 'Válido', type: 'boolean-active' },
          { key: 'selected', label: 'Seleccionado', type: 'vission' },
        ]}
        actions={actions}
      />
      {addCertificate && (
        <AddCertificate
          open={addCertificate}
          setOpen={setAddCertificate}
          setFailed={setFailed}
          setSuccess={setSuccess}
          setUp={setUp}
        />
      )}
      <CustomAlert
        open={!!success}
        setOpen={setSuccess}
        severity="success"
        text={success}
        handleClose={() => setSuccess('')}
      />
    </div>
  );
};

export default SettingsCertificates;

import { requestHandler } from '../../api';

export const getSIIBusinessDetails = async (dispatch: any, id: string) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/business/${id}/sii/details`,
    dispatch,
  });
  if (response) return response.data;
};

export const getBusinessPartners = async (dispatch: any, id: string) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/business/${id}/sii/partners`,
    dispatch,
  });
  if (response) return response.data;
};

export const getBusinessAddresses = async (dispatch: any, id: string) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/business/${id}/sii/addresses`,
    dispatch,
  });
  if (response) return response.data;
};

export const getBusinessCreditNotes = async (
  dispatch: any,
  id: string,
  query: string,
) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/business/${id}/creditNotes?${query}`,
    dispatch,
  });
  if (response) return response.data;
};

export const getBusinessInvoices = async (
  dispatch: any,
  id: string,
  type: string,
  query: string,
) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/business/${id}/invoices?type=${type}&${query}`,
    dispatch,
  });
  if (response) return response.data;
};

export const getIvaFolderLink = async (dispatch: any, id: string) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/business/${id}/iva/folder`,
    dispatch,
  });
  if (response) return response.data;
};

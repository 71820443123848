import React, { useEffect, useState } from 'react';
import HeaderWithActions, {
  CustomButtonProps,
} from '../../../../../lib/designSystem/HeaderWithActions';
import EnhancedTable from '../../../../../lib/designSystem/Table';
import { useDispatch, useSelector } from 'react-redux';
import CustomAlert from '../../../../../lib/designSystem/Alert';
import CenteredCircularProgress from '../../../../../lib/designSystem/CircularProgress';
import { Autocomplete, TextField } from '@mui/material';
import { fetchCreditLineEvaluations } from '../../../../../lib/api/decisionEngine';
import NewEvaluation from '../common/NewEvaluation';
import EvaluationDetails from '../common/Details';
import { validatePermissions } from '../../../../../lib/functions/utils';
import { AuthState } from '../../../../../services/redux/authSlice';

const headers = [
  // { key: 'timestamp', label: 'Id' },
  { key: 'businessName', label: 'Cliente' },
  { key: 'date', label: 'Fecha', type: 'date-large' },
  { key: 'engineName', label: 'Nombre motor' },
  { key: 'nodeName', label: 'Resultado' },
];

const ExecutedCreditLineEvaluations: React.FC = () => {
  const user = useSelector((state: { auth: AuthState }) => state.auth.user);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [evaluations, setEvaluations] = useState([]);
  const [filteredEvaluations, setFilteredEvaluations] = useState([]);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openFailure, setOpenFailure] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [failureMessage, setFailureMessage] = useState('');
  const [openDetails, setOpenDetails] = useState(false);
  const [evaluation, setEvaluation] = useState<any>(null);

  const setSuccessOpen = (message: string) => {
    setSuccessMessage(message);
    setOpenSuccess(true);
  };

  const setFailureOpen = (message: string) => {
    setFailureMessage(message);
    setOpenFailure(true);
  };

  const handleCreateNewBusiness = async () => {
    setOpenModal(true);
  };

  const buttons: CustomButtonProps[] = [
    {
      name: 'Nueva evaluación',
      action: () => handleCreateNewBusiness(),
      color: 'primary',
      disabled: !validatePermissions(user, 'creditHubPro_evaluation_create'),
    },
  ];

  const setUp = async () => {
    try {
      setLoading(true);
      const evaluationsResponse = await fetchCreditLineEvaluations(dispatch);
      setEvaluations(evaluationsResponse);
      setFilteredEvaluations(evaluationsResponse);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    setUp();
  }, []);

  const handleDetails = (id: number) => {
    setEvaluation(evaluations.find((x: any) => x.id === id));
    setOpenDetails(true);
  };
  const canShowDetails = () => {
    console.log(
      validatePermissions(user, 'creditHubPro_evaluation_view_details'),
    );
    return validatePermissions(user, 'creditHubPro_evaluation_view_details');
  };

  const actions = [
    {
      action: handleDetails,
      actionLabel: 'Ver',
      canShow: canShowDetails,
    },
  ];

  const handleChange = (event: any, newValue: any) => {
    // console.log(event);
    if (!newValue) return setFilteredEvaluations(evaluations);
    console.log(newValue);
    const filtered = evaluations.filter(
      (x: any) => x.businessName === newValue,
    );
    setFilteredEvaluations(filtered);
  };

  const autoCompleteFilter = (
    <Autocomplete
      options={[...new Set(evaluations.map((x: any) => x.businessName))]}
      getOptionLabel={(option: any) => `${option}`}
      renderInput={(params) => (
        <TextField style={{ minWidth: 250 }} {...params} label="Filtrar" />
      )}
      onChange={handleChange}
      fullWidth
    />
  );

  if (loading) return <CenteredCircularProgress type="layout" />;

  return (
    <div>
      <HeaderWithActions
        type="subHeader"
        title="Todas las evaluaciones automáticas a clientes que has realizado. Tanto por línea de crédito como singulares."
        variant="body1"
        buttons={buttons}
        item={autoCompleteFilter}
      />

      <EnhancedTable
        dataHeaders={headers}
        data={filteredEvaluations}
        showActions
        rowsPerPageDefault={50}
        actions={actions}
        exportToFile="xlsx"
      />
      {openModal && (
        <NewEvaluation
          type="creditLine"
          open={openModal}
          setOpen={setOpenModal}
          setUp={setUp}
          setSuccessOpen={setSuccessOpen}
          setFailureOpen={setFailureOpen}
        />
      )}
      {openDetails &&
        validatePermissions(user, 'creditHubPro_evaluation_view_details') && (
          <EvaluationDetails
            evaluation={evaluation}
            open={openDetails}
            setOpen={setOpenDetails}
            setUp={setUp}
            setSuccessOpen={setSuccessOpen}
            setFailureOpen={setFailureOpen}
          />
        )}
      <CustomAlert
        open={openSuccess}
        setOpen={setOpenSuccess}
        severity="success"
        title="Éxito"
        text={successMessage}
        handleClose={() => setOpenSuccess(false)}
      />
      <CustomAlert
        open={openFailure}
        setOpen={setOpenFailure}
        severity="error"
        title="Error"
        text={failureMessage}
        handleClose={() => setOpenFailure(false)}
      />
    </div>
  );
};

export default ExecutedCreditLineEvaluations;

// LoginForm.tsx
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { authenticate } from '../../../../services/redux/authSlice'; // Import the action
import { authenticateUser } from '../../../../lib/api';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import CenteredCircularProgress from '../../../../lib/designSystem/CircularProgress';
import CustomButton from '../../../../lib/designSystem/Button';
import { Box, Typography } from '@mui/material';
import { DATA_VERSION } from '../../../../App';

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoding] = useState(false);

  const dispatch = useDispatch();

  const handleSubmit = async (e: React.FormEvent): Promise<void> => {
    e.preventDefault();
    setLoding(true);
    const response = await authenticateUser(username, password);
    console.log(response);
    if (response.success) {
      if (!response.active) {
        alert(
          'Debes activar tu cuenta con el link que se te envió al email. Si no has recibido el link, comunicate con soporte.',
        );
        return setLoding(false);
      }
      console.log(response);
      const userDetails = {
        email: response.email,
        name: response.name,
        companyName: response.companyName,
        companyId: response.companyId,
        permissionPolicy: response.permissionPolicy,
        id: response.id,
        roles: response.roles,
        companyTypes: response.companyTypes,
        dataVersion: DATA_VERSION,
      };
      dispatch(authenticate(userDetails));
      // window.location.href = ('/last-invoices');
    } else {
      alert('Credenciales inválidas');
    }
    setLoding(false);
  };
  if (loading) return <CenteredCircularProgress type="login" />;
  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Email"
            variant="outlined"
            id="email"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Contraseña"
            fullWidth
            type="password"
            variant="outlined"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            id="password"
            autoComplete="new-password"
          />
        </Grid>
      </Grid>

      <Box sx={{ mt: 2 }}>
        <CustomButton
          onClick={(e) => handleSubmit(e)}
          fullWidth
          color="primary"
          variant="contained"
        >
          Entrar
        </CustomButton>
        <Box sx={{ mt: 2 }} display="flex">
          <Typography></Typography>
          <Typography>
            <a style={{ textDecoration: 'none' }} href={`/password-recovery`}>
              Olvidé mi constraseña{' '}
            </a>
          </Typography>
        </Box>
      </Box>
    </div>
  );
};

export default Login;

// LoginForm.tsx
import React, { useState } from 'react';
import { registerUser } from '../../../../lib/api';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import CenteredCircularProgress from '../../../../lib/designSystem/CircularProgress';
import CustomButton from '../../../../lib/designSystem/Button';
import { Box, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import EnhancedBox from '../../../../lib/designSystem/BigBox';
import { Copyright } from '../../../PublicLandings/EnrolationForm';
import { validaRut } from '../../../../lib/functions/utils';
import CustomAlert from '../../../../lib/designSystem/Alert';

const SigninExpress = () => {
  const { clientId } = useParams<{ clientId: string }>();
  const [registerData, setRgisterData] = useState({
    email: '',
    password: '',
    checkPassword: '',
    name: '',
    identifier: '',
    companyName: '',
  });
  const [success, setSucces] = useState(false);

  const handleChange = (e: any) => {
    setRgisterData({ ...registerData, [e.target.id]: e.target.value });
  };

  const handleChangeIdentifier = (e: any) => {
    setRgisterData({
      ...registerData,
      identifier: e.target.value.toUpperCase(),
    });
  };

  const [loading, setLoding] = useState(false);

  const checkPassword = () => {
    if (registerData.password === registerData.checkPassword) {
      return true;
    }
    return false;
  };

  const checkPasswordLength = () => {
    if (registerData.password.length >= 12) {
      return true;
    }
    return false;
  };

  const checkParams = () => {
    if (
      registerData.email &&
      registerData.password &&
      registerData.checkPassword &&
      registerData.name &&
      registerData.identifier &&
      registerData.companyName
    ) {
      return true;
    }
    return false;
  };
  const validateRut = () => {
    const rutValid = validaRut(registerData.identifier);
    if (!rutValid) {
      return false;
    } else {
      return true;
    }
  };

  const handleSubmit = async (e: React.FormEvent): Promise<void> => {
    try {
      e.preventDefault();
      setLoding(true);
      const response = await registerUser({ ...registerData, clientId });
      if (!response.success) {
        alert(response.message);
        return setLoding(false);
      }
      setSucces(true);
      console.log(response);
    } catch (error) {
      alert('Hubo un error');
    }
    setLoding(false);
  };
  if (loading) return <CenteredCircularProgress type="dialog" />;
  return (
    <div style={{ backgroundColor: '#F8FAFC', height: '100vh' }}>
      <div style={{ margin: 'auto', maxWidth: '40%', padding: 40 }}>
        <EnhancedBox>
          <Typography variant="h4">Registrarse</Typography>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Nombre personal"
                variant="outlined"
                id="name"
                value={registerData.name}
                onChange={(e) => handleChange(e)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Email"
                variant="outlined"
                id="email"
                value={registerData.email}
                onChange={(e) => handleChange(e)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Nombre empresa"
                variant="outlined"
                id="companyName"
                value={registerData.companyName}
                onChange={(e) => handleChange(e)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Rut empresa (Sin puntos, con guión)"
                variant="outlined"
                id="identifier"
                value={registerData.identifier}
                onChange={(e) => handleChangeIdentifier(e)}
              />
              {validateRut() ? null : (
                <Typography variant="body1" color="error">
                  Rut inválido
                </Typography>
              )}
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Contraseña"
                fullWidth
                type="password"
                variant="outlined"
                value={registerData.password}
                onChange={(e) => handleChange(e)}
                id="password"
                autoComplete="new-password"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Repetir contraseña"
                fullWidth
                type="password"
                variant="outlined"
                value={registerData.checkPassword}
                onChange={(e) => handleChange(e)}
                id="checkPassword"
                autoComplete="new-password"
              />
              {checkPassword() ? null : (
                <Typography variant="body1" color="error">
                  Las contraseñas no coinciden
                </Typography>
              )}
              {checkPasswordLength() ? null : (
                <Typography variant="body1" color="error">
                  Las contraseñas debe ser mayor a 12 caracteres
                </Typography>
              )}
            </Grid>
          </Grid>
          <Box sx={{ mt: 2 }}>
            <CustomButton
              onClick={(e) => handleSubmit(e)}
              fullWidth
              color="primary"
              variant="contained"
              disabled={
                !checkParams() ||
                !checkPassword() ||
                !validateRut() ||
                !checkPasswordLength()
              }
            >
              Registrarse
            </CustomButton>
          </Box>
        </EnhancedBox>
      </div>
      <CustomAlert
        open={success}
        setOpen={setSucces}
        severity="success"
        text="Se te envió un email para activar tu cuenta"
        onClose={() => (window.location.href = `/express/${clientId}`)}
      />
      <Copyright />
    </div>
  );
};

export default SigninExpress;

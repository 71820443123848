import React, { useState, useEffect } from 'react';
import {
  analizeFinancialStatement,
  getPendingFinancialStatementsForAssignation,
  getUploadedSignedUrl,
} from '../../../../lib/api';
import { useDispatch, useSelector } from 'react-redux';
import CenteredCircularProgress from '../../../../lib/designSystem/CircularProgress';
import EnhancedTable from '../../../../lib/designSystem/Table';
import CustomAlert from '../../../../lib/designSystem/Alert';
import { validatePermissions } from '../../../../lib/functions/utils';
import { AuthState } from '../../../../services/redux/authSlice';

const FinancialStatementsPending = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [failed, setFailed] = useState(false);
  const [financialStatements, setFinancialStatements] = useState<any>([]);
  const user = useSelector((state: { auth: AuthState }) => state.auth.user);
  const [successMessage, setSuccessMessage] = useState('');
  const activateSuccess = (message: string) => {
    setSuccessMessage(message);
    setSuccess(true);
  };
  const fetchSetupData = async () => {
    setLoading(true);
    try {
      const financialStatementsResponse =
        await getPendingFinancialStatementsForAssignation(dispatch);
      console.log(financialStatementsResponse);
      setFinancialStatements(financialStatementsResponse);
    } catch (error) {
      alert('Error');
    }

    setLoading(false);
  };

  useEffect(() => {
    fetchSetupData();
  }, []);

  const canShowOpenAnalize = (id: number) => {
    if (!validatePermissions(user, 'financial_states_create_analisys'))
      return false;

    const financialStatement = financialStatements.find(
      (x: any) => x.id === id,
    );
    if (!financialStatement) return alert('Ha ocurrido un error');

    if (financialStatement.status === 'CREADO') return true;

    return false;
  };
  const canShowOpenAssignment = (id: number) => {
    if (!validatePermissions(user, 'financial_states_create_assignment'))
      return false;

    const financialStatement = financialStatements.find(
      (x: any) => x.id === id,
    );
    if (!financialStatement) return alert('Ha ocurrido un error');

    if (financialStatement.status === 'ANALIZADO') return true;

    return false;
  };

  const canShowEditAssignation = (id: number) => {
    const financialStatement = financialStatements.find(
      (x: any) => x.id === id,
    );
    if (!financialStatement) return alert('Ha ocurrido un error');

    if (!validatePermissions(user, 'financial_states_create_assignment'))
      return false;

    if (financialStatement.status === 'ASIGNADO') return true;

    return false;
  };

  const handleAnalyze = async (financialStatementId: number) => {
    try {
      setLoading(true);
      const id = getBusinessIdentifier(financialStatementId);
      if (!id) {
        alert('No se ha encontrado el id de la empresa');
        return;
      }
      const response = await analizeFinancialStatement(dispatch, id, {
        financialStatementId,
      });
      console.log('Analizing', response);
      activateSuccess(
        'Se está configurando tu balance. Dentro de algunos segundos ya estará disponible para su asignación.',
      );
      await fetchSetupData();
      // await fetchRelations()
    } catch (error) {
      console.log(error);
      setFailed(true);
    }
    setLoading(false);
  };

  const handleShowReal = async (financialStatementId: number) => {
    try {
      setLoading(true);
      const id = getBusinessIdentifier(financialStatementId);
      const response = await getUploadedSignedUrl(dispatch, id, {
        financialStatementId,
      });
      window.open(response);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };
  const handleAssignationEdit = async (financialStatementId: number) => {
    const id = getBusinessIdentifier(financialStatementId);
    window.location.href = `/financialStatements/business/${id}/financial/assginations/${financialStatementId}/edit`;
  };

  const getBusinessIdentifier = (financialStatementId: number) => {
    const fs = financialStatements.find(
      (x: any) => x.id === financialStatementId,
    );
    if (!fs) return alert('Ha ocurrido un error');
    return fs.businessIdentifier;
  };

  const actions: any = [
    { action: handleShowReal, actionLabel: 'Descargar' },
    {
      action: handleAnalyze,
      actionLabel: 'Analizar',
      canShow: canShowOpenAnalize,
    },
    {
      action: handleAssignationEdit,
      actionLabel: 'Asignar',
      canShow: canShowOpenAssignment,
    },
    {
      action: handleAssignationEdit,
      actionLabel: 'Editar asignación',
      canShow: canShowEditAssignation,
    },
    // {action: handleDelete, actionLabel: 'Borrar', canShow: canShowDeleteFS},
  ];

  if (loading) return <CenteredCircularProgress type="layout" />;

  return (
    <div>
      <EnhancedTable
        data={financialStatements}
        dataHeaders={[
          { key: 'businessName', label: 'Empresa' },
          { key: 'year', label: 'Año' },
          { key: 'fsState', label: 'Tipo' },
          { key: 'createdAt', label: 'Fecha subido', type: 'date-large' },
          {
            key: 'updatedAt',
            label: 'Última cambio de estado',
            type: 'date-large',
          },
          { key: 'uploaderName', label: 'Subido por' },
          { key: 'emptyerName', label: 'Vaciador' },
          { key: 'status', label: 'Estado', type: 'fs-status' },
        ]}
        actions={actions}
        rowsPerPageDefault={100}
        showActions
      />
      <CustomAlert
        setOpen={setSuccess}
        open={success}
        title="Exito"
        text={successMessage}
        severity="success"
      />
      <CustomAlert
        setOpen={setFailed}
        open={failed}
        title="Error"
        text={'Oops hubo un error'}
        severity="error"
      />
    </div>
  );
};

export default FinancialStatementsPending;

import { requestHandler } from '../../api';

export const fetchSuggestedCompanyConcilations = async (dispatch: any) => {
  const response = await requestHandler({
    method: 'get',
    url: `internal/conciliation/company/suggestedDocumentsConciliation`,
    dispatch,
  });
  if (response) return response.data;
};

export const reverseDocumentConciliation = async (
  dispatch: any,
  payInIdConciliation: number,
) => {
  const response = await requestHandler({
    method: 'delete',
    url: `internal/conciliation/payInConciliation/${payInIdConciliation}`,
    dispatch,
  });
  if (response) return response.data;
};

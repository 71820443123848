import React, { useEffect, useState } from 'react';
import CenteredCircularProgress from '../../../../../lib/designSystem/CircularProgress';
import {
  activateRelationBetweenReceiverAndReceiver,
  deactivateRelationBetweenReceiverAndReceiver,
  getStatusOfRelationBetweenClientAndReceiver,
} from '../../../../../lib/api/express';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Typography, Switch, FormControlLabel } from '@mui/material';

const SettingsVission = (props: any) => {
  const { clientId } = useParams<{
    mainPage: string;
    subPage: string;
    clientId: string;
  }>();
  const { setSuccess, setFailure } = props;
  const [loading, setLoading] = useState<boolean>(false);
  const [status, setStatus] = useState<boolean>(false);
  const dispatch = useDispatch();

  const setUp = async () => {
    setLoading(true);
    try {
      const vissionStatusResponse =
        await getStatusOfRelationBetweenClientAndReceiver(
          dispatch,
          clientId as string,
        );
      setStatus(vissionStatusResponse.active);
    } catch (error) {
      alert('No se pudo obtener la visibilidad');
    }
    setLoading(false);
  };

  const handleEnable = async () => {
    setLoading(true);
    try {
      await activateRelationBetweenReceiverAndReceiver(
        dispatch,
        clientId as string,
      );
      setSuccess('Visibilidad habilitada');
      await setUp();
    } catch (error) {
      setFailure('No se pudo habilitar la visibilidad');
    }
    setLoading(false);
  };

  const handleDisable = async () => {
    setLoading(true);
    try {
      await deactivateRelationBetweenReceiverAndReceiver(
        dispatch,
        clientId as string,
      );
      await setUp();
      setSuccess('Visibilidad deshabilitada');
    } catch (error) {
      setFailure('No se pudo deshabilitar la visibilidad');
    }
    setLoading(false);
  };

  useEffect(() => {
    setUp();
  }, []);

  const handleSwitchChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (event.target.checked) {
      await handleEnable();
    } else {
      await handleDisable();
    }
  };

  if (loading) return <CenteredCircularProgress type="dialog" />;

  return (
    <div>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <div>
          <Typography fontWeight="bold" variant="body1">
            Visibilidad del factoring
          </Typography>
          <Typography variant="body1">
            En esta sección puedes habilitar o deshabilitar la visibilidad de
            tus datos del SII y TGR para el factoring
          </Typography>
        </div>
        <FormControlLabel
          control={<Switch checked={status} onChange={handleSwitchChange} />}
          label=""
          labelPlacement="start"
        />
      </div>
    </div>
  );
};

export default SettingsVission;

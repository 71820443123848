import React, { useState, useEffect } from 'react';
import {
  getFinancialStatementsForAssignated,
  getUploadedSignedUrl,
} from '../../../../lib/api';
import { useDispatch, useSelector } from 'react-redux';
import CenteredCircularProgress from '../../../../lib/designSystem/CircularProgress';
import EnhancedTable from '../../../../lib/designSystem/Table';

import { validatePermissions } from '../../../../lib/functions/utils';
import { AuthState } from '../../../../services/redux/authSlice';

const FinancialStatementsDone = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [financialStatements, setFinancialStatements] = useState<any>([]);
  const user = useSelector((state: { auth: AuthState }) => state.auth.user);
  const fetchSetupData = async () => {
    setLoading(true);
    try {
      const financialStatementsResponse =
        await getFinancialStatementsForAssignated(dispatch);
      console.log(financialStatementsResponse);
      setFinancialStatements(financialStatementsResponse);
    } catch (error) {
      alert('Error');
    }

    setLoading(false);
  };

  useEffect(() => {
    fetchSetupData();
  }, []);

  const canShowEditAssignation = (id: number) => {
    const financialStatement = financialStatements.find(
      (x: any) => x.id === id,
    );
    if (!financialStatement) return alert('Ha ocurrido un error');

    if (!validatePermissions(user, 'financial_states_create_assignment'))
      return false;

    if (financialStatement.status === 'ASIGNADO') return true;

    return false;
  };

  const handleShowReal = async (financialStatementId: number) => {
    try {
      setLoading(true);
      const id = getBusinessIdentifier(financialStatementId);
      const response = await getUploadedSignedUrl(dispatch, id, {
        financialStatementId,
      });
      window.open(response);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };
  const handleAssignationEdit = async (financialStatementId: number) => {
    const id = getBusinessIdentifier(financialStatementId);
    window.location.href = `/financialStatements/business/${id}/financial/assginations/${financialStatementId}/edit`;
  };

  const getBusinessIdentifier = (financialStatementId: number) => {
    const fs = financialStatements.find(
      (x: any) => x.id === financialStatementId,
    );
    if (!fs) return alert('Ha ocurrido un error');
    return fs.businessIdentifier;
  };

  const handleOpenMetrics = async (financialStatementId: number) => {
    const id = getBusinessIdentifier(financialStatementId);
    window.location.href = `/financialStatements/business/${id}/financial/assginations/results`;
  };

  const actions: any = [
    { action: handleShowReal, actionLabel: 'Descargar' },
    {
      action: handleAssignationEdit,
      actionLabel: 'Editar asignación',
      canShow: canShowEditAssignation,
    },
    { action: handleOpenMetrics, actionLabel: 'Indicadores' },
  ];

  if (loading) return <CenteredCircularProgress type="layout" />;

  return (
    <div>
      <EnhancedTable
        data={financialStatements}
        dataHeaders={[
          { key: 'businessName', label: 'Empresa' },
          { key: 'year', label: 'Año' },
          { key: 'fsState', label: 'Tipo' },
          { key: 'createdAt', label: 'Fecha subido', type: 'date-large' },
          {
            key: 'updatedAt',
            label: 'Última actualización',
            type: 'date-large',
          },
          { key: 'uploaderName', label: 'Creador' },
          { key: 'emptyerName', label: 'Vaciador' },
          // {key: 'status', label: 'Estado', type: 'fs-status'},
        ]}
        actions={actions}
        showActions
        rowsPerPageDefault={100}
      />
    </div>
  );
};

export default FinancialStatementsDone;

import React, { useState, useEffect } from 'react';
import {
  Typography,
  Grid,
  FormControl,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  Divider,
} from '@mui/material';
import { makeStyles, createStyles } from '@mui/styles';
import EnhancedTable from '../../../../../lib/designSystem/Table';
import { useDispatch } from 'react-redux';
import { getBusinessCessionsSummary } from '../../../../../lib/api';
import { useParams } from 'react-router-dom';
import CenteredCircularProgress from '../../../../../lib/designSystem/CircularProgress';
import SimpleLineChart from '../BusinessSummary/Components/LineChart';
import { formatMoney } from '../../../../../lib/functions/utils';
import EnhacedBox from '../../../../../lib/designSystem/Box';
import { chartCalculator } from './utils/chartCalculator';

interface SummaryBoxProps {
  title: string;
  content: React.JSX.Element;
}

const useStyles = makeStyles(() =>
  createStyles({
    box: {
      border: '1px solid #e0e0e0',
      padding: '30px 0px',
      background: '#ffffff',
      borderRadius: '10px',
      width: '100%', // ensure the table fits its container
      tableLayout: 'fixed', // this can help with column overflow issues
    },
  }),
);

const SummaryBox: React.FC<SummaryBoxProps> = ({ content }) => {
  const classes = useStyles();
  return (
    <div className={classes.box}>
      {/* <Typography variant="h4">{title}</Typography> */}
      {content}
    </div>
  );
};

const CompnayFactoring: React.FC = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [cessionsData, setCessionsData] = useState<{
    historicData: any;
    dataPayers: any;
    dataFactorings: any;
  }>({
    historicData: [],
    dataPayers: { total: [] },
    dataFactorings: { total: [] },
  });
  const [selectedMonths, setSelectedMonths] = useState<string[]>([]);
  const [cessionsDataCesisonaries, setCessionsDataCesisonaries] = useState<any>(
    [],
  );
  const [cessionsDataPayers, setCessionsDataPayers] = useState<any>([]);
  const [cessionAverage, setCessionAverage] = useState(0);
  const [cessionTotalAmount, setCessionTotalAmount] = useState(0);
  const [cessionTotalCancelAmount, setCessionTotalCancelAmount] = useState(0);
  const [cessionTotalCreditNoteAmount, setCessionTotalCreditNoteAmount] =
    useState(0);
  const [selected, setSelected] = useState<any>([]);
  const [chartData, setChartData] = useState<any>([]);

  const { id } = useParams<{ id: string }>();

  const DetailsFilter = () => {
    const handleSelectChange = (event: any) => {
      const newSelectedMonths = event.target.value;
      console.log(newSelectedMonths);
      const allSelectedAlready =
        selectedMonths.includes('Últimos 24M') ||
        selectedMonths.includes('Últimos 12M');
      if (newSelectedMonths.length === 0) {
        return setSelectedMonths(['Últimos 24M']);
      }
      if (allSelectedAlready && newSelectedMonths.length > 1) {
        if (
          newSelectedMonths.includes('Últimos 24M') &&
          newSelectedMonths.includes('Últimos 12M')
        ) {
          return setSelectedMonths([newSelectedMonths.pop()]);
        }
        const newSelectedMonthsFiltered = newSelectedMonths.filter(
          (month: any) => month !== 'Últimos 24M' && month !== 'Últimos 12M',
        );
        return setSelectedMonths(newSelectedMonthsFiltered);
      }
      if (newSelectedMonths.includes('Últimos 24M')) {
        return setSelectedMonths(['Últimos 24M']);
      }

      if (newSelectedMonths.includes('Últimos 12M')) {
        return setSelectedMonths(['Últimos 12M']);
      }
      setSelectedMonths(newSelectedMonths);
    };

    const sortedKeysByDate = Object.keys(cessionsData.dataPayers);

    return (
      <div>
        <FormControl fullWidth>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            multiple
            value={selectedMonths}
            onChange={handleSelectChange}
            renderValue={(selected: any) => selected.join(', ')}
          >
            {sortedKeysByDate.map((data: any) => (
              <MenuItem key={data} value={data}>
                <Checkbox checked={selectedMonths.indexOf(data) > -1} />
                <ListItemText primary={data} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    );
  };

  const dataPayersToShow = (type: string) => {
    const data =
      type === 'factorings'
        ? cessionsData.dataFactorings
        : cessionsData.dataPayers;
    const allMonthPayers = selectedMonths.reduce((acc: any, month: any) => {
      return acc.concat(data[month]);
    }, []);
    if (allMonthPayers.length === 0) return;
    console.log(allMonthPayers);
    const mergedMonthsSumByIdentifier = allMonthPayers.reduce(
      (acc: any, payerObject: any) => {
        if (!acc[payerObject.identifier]) {
          acc[payerObject.identifier] = {
            id: payerObject.identifier,
            name: payerObject.name,
            identifier: payerObject.identifier,
            amount: 0,
            cancelAmount: 0,
            creditNoteAmount: 0,
          };
        }
        acc[payerObject.identifier].amount += payerObject.amount;
        acc[payerObject.identifier].cancelAmount += payerObject.cancelAmount;
        acc[payerObject.identifier].creditNoteAmount +=
          payerObject.creditNoteAmount;
        return acc;
      },
      {},
    );
    let newTotalAmount = 0;
    let newCancelAmount = 0;
    let newCreditNoteAmount = 0;
    console.log(mergedMonthsSumByIdentifier);
    Object.keys(mergedMonthsSumByIdentifier).forEach((key) => {
      const payer = mergedMonthsSumByIdentifier[key];
      payer.cancelPercentage = payer.cancelAmount / payer.amount;
      payer.creditNotePercentage = payer.creditNoteAmount / payer.amount;
      if (selected.includes(key) || selected.length === 0) {
        newTotalAmount += payer.amount;
        newCancelAmount += payer.cancelAmount;
        newCreditNoteAmount += payer.creditNoteAmount;
      }
    });
    const mergedMonthsSum: { amount: number }[] = Object.values(
      mergedMonthsSumByIdentifier,
    );
    const buySummary = mergedMonthsSum.reduce((acc: any, payer: any) => {
      if (selected.includes(payer.identifier) || selected.length === 0) {
        return acc + payer.amount;
      } else {
        return acc;
      }
    }, 0);
    console.log(mergedMonthsSum);
    if (type === 'factorings') {
      setCessionsDataCesisonaries(mergedMonthsSum);
    } else {
      setCessionsDataPayers(mergedMonthsSum);
    }
    let monthsToDivide = selectedMonths.length;
    if (selectedMonths[0] === 'Últimos 24M') monthsToDivide = 24;
    if (selectedMonths[0] === 'Últimos 12M') monthsToDivide = 12;
    setCessionAverage(buySummary / monthsToDivide);
    setCessionTotalAmount(newTotalAmount);
    setCessionTotalCancelAmount(newCancelAmount);
    setCessionTotalCreditNoteAmount(newCreditNoteAmount);
  };

  useEffect(() => {
    const fetchRelations = async () => {
      if (!id) return;
      const cessions = await getBusinessCessionsSummary(dispatch, id);
      console.log(cessions);
      setCessionsData(cessions || []);
      setSelectedMonths(['Últimos 24M']);
      setLoading(false);
    };
    fetchRelations();
  }, []);

  useEffect(() => {
    dataPayersToShow('factorings');
    dataPayersToShow('payers');
  }, [selectedMonths, selected]);

  useEffect(() => {
    setChartData(
      chartCalculator(cessionsData, selected, selected.length === 0),
    );
  }, [selected, cessionsData]);

  if (loading) return <CenteredCircularProgress />;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h6">Últimos 24 meses</Typography>
        <SummaryBox
          title={`Compras y ventas`}
          content={
            <SimpleLineChart
              xName="Factorizado"
              xKey="amount"
              aspect={4.0 / 0.6}
              data={chartData}
            />
          }
        />
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={9}>
        <Typography variant="h6">En detalle</Typography>
      </Grid>
      <Grid item xs={3}>
        <DetailsFilter />
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <EnhacedBox small margin="0px" centerText>
              <Typography variant="body1">
                <b>Promedio factorizado:</b>
              </Typography>
              <Typography variant="body1">
                {formatMoney(cessionAverage)}
              </Typography>
            </EnhacedBox>
          </Grid>
          <Grid item xs={3}>
            <EnhacedBox small margin="0px" centerText>
              <Typography variant="body1">
                <b>Monto factorizado:</b>
              </Typography>
              <Typography variant="body1">
                {formatMoney(cessionTotalAmount)}
              </Typography>
            </EnhacedBox>
          </Grid>
          <Grid item xs={3}>
            <EnhacedBox small margin="0px" centerText>
              <Typography variant="body1">
                <b>Monto reclamado:</b>
              </Typography>
              <Typography variant="body1">
                {formatMoney(cessionTotalCancelAmount)} (
                {(
                  (cessionTotalCancelAmount / cessionTotalAmount) *
                  100
                ).toFixed(2)}
                %)
              </Typography>
            </EnhacedBox>
          </Grid>
          <Grid item xs={3}>
            <EnhacedBox small margin="0px" centerText>
              <Typography variant="body1">
                <b>Monto notas de crédito:</b>
              </Typography>
              <Typography variant="body1">
                {formatMoney(cessionTotalCreditNoteAmount)} (
                {(
                  (cessionTotalCreditNoteAmount / cessionTotalAmount) *
                  100
                ).toFixed(2)}
                %)
              </Typography>
            </EnhacedBox>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Typography margin={0} variant="h5">
          Factorización por deudor
        </Typography>
        <EnhancedTable
          finalSumRow
          exportToFile="xlsx"
          tableSize="small"
          dataHeaders={[
            { key: 'name', label: 'Razon Social' },
            { key: 'identifier', label: 'Rut' },
            { key: 'amount', label: 'Monto', type: 'money' },
            { key: 'cancelPercentage', label: 'Reclamado', type: 'percentage' },
            { key: 'cancelAmount', label: 'Monto reclamado', type: 'money' },
            {
              key: 'creditNotePercentage',
              label: 'Notas de crédito',
              type: 'percentage',
            },
            {
              key: 'creditNoteAmount',
              label: 'Monto notas de crédito',
              type: 'money',
            },
          ]}
          data={cessionsDataPayers}
          selected={selected}
          setSelected={setSelected}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography margin={0} variant="h5">
          Factorización por factoring
        </Typography>
        <EnhancedTable
          finalSumRow
          exportToFile="xlsx"
          tableSize="small"
          dataHeaders={[
            { key: 'name', label: 'Razon Social' },
            { key: 'identifier', label: 'Rut' },
            { key: 'amount', label: 'Monto', type: 'money' },
            { key: 'cancelPercentage', label: 'Reclamado', type: 'percentage' },
            { key: 'cancelAmount', label: 'Monto reclamado', type: 'money' },
            {
              key: 'creditNotePercentage',
              label: 'Notas de crédito',
              type: 'percentage',
            },
            {
              key: 'creditNoteAmount',
              label: 'Monto notas de crédito',
              type: 'money',
            },
          ]}
          data={cessionsDataCesisonaries}
        />
      </Grid>
    </Grid>
  );
};

export default CompnayFactoring;

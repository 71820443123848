import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, TextField, Typography, DialogActions, Checkbox, FormControlLabel } from '@mui/material'
import { createBusiness, startEnrolation, validateCredentials } from '../../../../../../lib/api';
import { validaRut, validateParams } from '../../../../../../lib/functions/utils';
import CustomButton from '../../../../../../lib/designSystem/Button';
import { handleError } from '../../../../../../lib/functions/errors';

function ConnectBusiness(props: any) {
  const {
    setLoading,
    dispatch,
    activateSuccess,
    setFailed,
    setOpenEditDialog,
    handleInputChange,
    fetchRelations,
    selectedBusiness,
    openAddDialog,
    setOpenAddDialog
  } = props
  const [formErrorMessage, setFormErrorMessage] = useState(''); // ... your state setup for formErrorMessage
  const [connectSII, setConnectSII] = useState(false); // Checkbox state

  const handleOnlyCreateBusiness = async () => {
    try {
      setLoading(true)
      console.log(selectedBusiness)
      if (!validateParams(selectedBusiness, ['businessName', 'identifier'], setFormErrorMessage)) return setLoading(false)
      if (!validaRut(selectedBusiness.identifier))  {
        setLoading(false)
        return alert('Rut inválido')
      }
      await createBusiness(
        dispatch,
        {
          name: selectedBusiness.businessName,
          identifier: selectedBusiness.identifier,
          taxPassword: selectedBusiness.password
        }
      )  
      await fetchRelations()
      activateSuccess('Se ha creado un nuevo Cliente. Puedes activarla cuando lo necesites apretando "Actualizar Cliente"')
    } catch (error: any) {
      console.log(error)
      setFailed(true)
      handleError(error)
    }
    setLoading(false)
    setOpenAddDialog(false);
    setOpenEditDialog(false);
  }

  const handleAddDialog = async() => {
    try {
      setLoading(true)
      if (!validaRut(selectedBusiness.identifier))  {
        setLoading(false)
        return alert('Rut inválido')
      }
      if (!validateParams(selectedBusiness, ['businessName', 'identifier', 'password'], setFormErrorMessage)) return setLoading(false)
      await validateCredentials(
        dispatch,
        {
          taxPassword: selectedBusiness.password,
          taxIdentifier: selectedBusiness.identifier
        }
      )
      const response = await createBusiness(
        dispatch,
        {
          name: selectedBusiness.businessName,
          identifier: selectedBusiness.identifier,
          taxPassword: selectedBusiness.password
        }
      )  
      await startEnrolation(
        dispatch,
        {
          taxPassword: selectedBusiness.password,
          taxIdentifier: selectedBusiness.identifier,
          businessId: response.id
        }
      )
      await fetchRelations()
      activateSuccess('Estamos ingestando las nuevas facturas. Estas estarán disponibles en algunos minutos')
    } catch (error: any) {
      console.log(error)
      setFailed(true)
      handleError(error)
    }
    setLoading(false)
    setOpenAddDialog(false);
    setOpenEditDialog(false);
  }

  return (
      <Dialog open={openAddDialog} onClose={() => setOpenAddDialog(false)}>
          <DialogTitle>Conectar Cliente</DialogTitle>
          <DialogContent>
              <TextField margin="dense" name='businessName' label="Nombre" fullWidth value={selectedBusiness.businessName} onChange={handleInputChange} />
              <TextField margin="dense" name='identifier' label="RUT (sin puntos, con guión)" fullWidth value={selectedBusiness.identifier} onChange={handleInputChange} />
              
              <FormControlLabel
                  control={
                      <Checkbox
                          checked={connectSII}
                          onChange={(e: any) => setConnectSII(e.target.checked)}
                          name="connectSII"
                          color="primary"
                      />
                  }
                  label="Conectar con SII"
              />

              {connectSII && (
                  <TextField margin="dense" name='password' label="Contraseña" value={selectedBusiness.password} onChange={handleInputChange} type="password" fullWidth />
              )}
              
              <Typography variant="body1">{formErrorMessage}</Typography>
          </DialogContent>
          <DialogActions>
              <CustomButton onClick={() => setOpenAddDialog(false)} color="secondary">
                  Cancelar
              </CustomButton>
              <CustomButton onClick={connectSII ? handleAddDialog : handleOnlyCreateBusiness} color="primary">
                  Agregar
              </CustomButton>
          </DialogActions>
      </Dialog>
  );
}

export default ConnectBusiness;

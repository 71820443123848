import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { useDispatch } from 'react-redux';
import {
  createContact,
  getBusinessContactsByIdentifier,
  submitVerification,
} from '../../../../../../../../../../../../../lib/api';
import { validateChileanPhone } from '../../../../../../../../../../../../../lib/functions/utils';
import { ContactForm } from '../../../../../../../../../../../../BusinessProfile/Profile/components/Contacts';
import CustomButton from '../../../../../../../../../../../../../lib/designSystem/Button';
import CenteredCircularProgress from '../../../../../../../../../../../../../lib/designSystem/CircularProgress';
import EnhancedTable from '../../../../../../../../../../../../../lib/designSystem/Table';

const availableTypes = [
  { id: 'mail', name: 'Correo' },
  { id: 'phone', name: 'Teléfono' },
  { id: 'whatsapp', name: 'Whatsapp' },
  { id: 'postVerified', name: 'Post verificado' },
];

const ConfirmInvoiceContactForm = (props: any) => {
  const { newContact, setNewContact, contacts, setContact, contact } = props;
  return (
    <div>
      {newContact ? (
        <div>
          {!contacts.length ? (
            <Typography variant="body1" padding="4px 0px">
              No encotramos contactos para este pagador, a continuación agrega
              uno:
            </Typography>
          ) : (
            <Typography variant="body1" padding="10px 0px">
              Nuevo contacto{' '}
              {contacts.length && (
                <span
                  style={{ color: 'blue', cursor: 'pointer' }}
                  onClick={() => setNewContact(false)}
                >
                  o elige uno existente
                </span>
              )}
            </Typography>
          )}
          <ContactForm
            selectedContact={contact}
            setSelectedContact={setContact}
            tiny
          />
        </div>
      ) : (
        <div>
          <Typography variant="body1" padding="10px 0px">
            Selecciona un contacto{' '}
            <span
              style={{ color: 'blue', cursor: 'pointer' }}
              onClick={() => setNewContact(true)}
            >
              o agrega uno nuevo
            </span>
          </Typography>
          <FormControl fullWidth>
            <InputLabel id="filter-label">{'Contacto'}</InputLabel>
            <Select
              labelId="filter-label"
              id="Filtrar por estado"
              fullWidth
              value={contact.id}
              onChange={(e) => {
                const contact = contacts.find(
                  (x: any) => x.id === e.target.value,
                );
                setContact(contact);
              }}
              label={'Contacto'}
            >
              {contacts.map((x: any, index: any) => (
                <MenuItem key={index} value={x.id}>
                  {x.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      )}
    </div>
  );
};

const ConfirmationForm = (props: any) => {
  const { type, setType, message, setMessage, selectedDocuments } = props;

  const confirmTypesInDocuments = selectedDocuments.map(
    (document: any) => document.confirmType,
  );
  const availableTypeForThisDocuments = availableTypes.filter(
    (type: any) => !confirmTypesInDocuments.includes(type.name),
  );

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={3}>
          <FormControl fullWidth>
            <InputLabel id="filter-label">{'Tipo'}</InputLabel>
            <Select
              labelId="filter-label"
              id="Filtrar por estado"
              fullWidth
              value={type}
              onChange={(e) => setType(e.target.value)}
              label={'Tipo'}
            >
              {availableTypeForThisDocuments.map((x: any, index: any) => (
                <MenuItem key={index} value={x.id}>
                  {x.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={9}>
          <TextField
            label="Comentario"
            value={message}
            fullWidth
            onChange={(e) => setMessage(e.target.value)}
          />
        </Grid>
      </Grid>
    </div>
  );
};

const ConfirmationModule = (props: any) => {
  const {
    selectedRequest,
    setSelectedRequest,
    setSuccessOpen,
    setFailureOpen,
    selected,
    setSelected,
    open,
    setOpen,
  } = props;

  const selectedDocuments = selectedRequest.documents.filter((invoice: any) =>
    selected.includes(invoice.id),
  );

  const [contact, setContact] = useState<any>({
    name: '',
    email: '',
    role: '',
    phone: '',
  });
  const [newContact, setNewContact] = useState<any>(false);
  const [contacts, setContacts] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [type, setType] = useState('');
  const [message, setMessage] = useState('');
  // const [stakeholderIdentifier, setStakeholderIdentifier] = useState<string>('');

  const dispatch = useDispatch();

  const setUpContacts = async () => {
    setLoading(true);
    const contactsResponse = await getBusinessContactsByIdentifier(dispatch, {
      identifier: stakeholderIdentifier,
    });
    setContacts(contactsResponse || []);
    if (!contactsResponse.length) {
      setNewContact(true);
    }
    console.log(loading);
    setLoading(false);
  };

  const getStakeholderData = () => {
    if (selected.length) {
      const stakeholder = selectedRequest.documents.find(
        (invoice: any) => invoice.id === selected[0],
      );
      return {
        stakeholderIdentifier: stakeholder.stakeholderIdentifier,
        stakeholderName: stakeholder.stakeholderName,
      };
    }
    return { stakeholderIdentifier: '', stakeholderName: '' };
  };

  const { stakeholderIdentifier, stakeholderName } =
    selected && getStakeholderData();

  const validateAttributes = () => {
    if (type === 'postVerified') return true;
    if (
      contact.name === '' ||
      contact.email === '' ||
      contact.role === '' ||
      contact.phone === ''
    ) {
      alert('Debes completar todos los campos');
      return false;
    }
    if (!validateChileanPhone(contact.phone)) {
      alert('El teléfono debe ser un número chileno');
      return false;
    }
    return true;
  };

  const validateConfirmation = () => {
    if (type === '') {
      alert('Debes seleccionar un tipo de confirmación');
      return false;
    }
    if (message === '') {
      alert('Debes agregar un comentario');
      return false;
    }
    return true;
  };

  useEffect(() => {
    if (stakeholderIdentifier) {
      setUpContacts();
    }
  }, [stakeholderIdentifier]);

  const handleSubmit = async () => {
    setLoading(true);
    if (!validateConfirmation()) {
      setLoading(false);
      return;
    }
    try {
      const contactWithId = contact;
      if (newContact && type !== 'postVerified') {
        console.log(contact);
        if (!validateAttributes()) {
          setLoading(false);
          return;
        }
        const response = await createContact(dispatch, {
          businessIdentifier: stakeholderIdentifier,
          name: contact.name,
          email: contact.email,
          phone: contact.phone,
          role: contact.role,
        });
        contactWithId.id = response.id;
        setNewContact(false);
      }
      console.log(contactWithId);
      const documents = await submitVerification(dispatch, {
        type,
        message,
        contactId: contactWithId.id,
        documents: selectedRequest.documents.filter((invoice: any) =>
          selected.includes(invoice.id),
        ),
      });
      console.log(documents);
      setSuccessOpen('Facturas confirmadas correctamente');
      const mergedDocuments = selectedRequest.documents.map((document: any) => {
        const newDocument = documents.find(
          (newDocument: any) => newDocument.id === document.id,
        );
        return newDocument || document;
      });
      setSelectedRequest({ ...selectedRequest, documents: mergedDocuments });
      setSelected([]);
      setSuccessOpen('Facturas confirmadas correctamente');
      setOpen(false);
      setLoading(false);
    } catch (error) {
      setFailureOpen('Error al confirmar facturas');
      console.log(error);
    }
    setLoading(false);
  };

  if (loading) return <CenteredCircularProgress type="dialog" />;

  return (
    <Dialog maxWidth="md" open={open} onClose={() => setOpen(false)}>
      <DialogContent>
        <Typography variant="body1" padding="16px 0px" fontWeight={600}>
          Verificar documentos de {stakeholderName} ({stakeholderIdentifier})
        </Typography>
        <div style={{ padding: '10px 0px' }}>
          <EnhancedTable
            data={selectedDocuments}
            dataHeaders={[
              { key: 'folio', label: 'Folio' },
              {
                key: 'amount',
                label: 'Monto',
                type: 'money',
              },
              {
                key: 'confirmDate',
                label: 'Confirmada',
                type: 'confirm-lights',
                canNull: true,
              },
              {
                key: 'confirmedBy',
                label: 'Confirmada por',
                canNull: true,
              },
              {
                key: 'confirmType',
                label: 'Tipo Confirmación',
                canNull: true,
              },
              {
                key: 'confirmMessage',
                label: 'Comentario',
              },
            ]}
            rowsPerPageDefault={5}
            exportToFile="xlsx"
          />
        </div>
        <ConfirmationForm
          type={type}
          setType={setType}
          meesage={message}
          setMessage={setMessage}
          selectedDocuments={selectedDocuments}
        />
        {type !== 'postVerified' && (
          <div>
            <div style={{ padding: '10px 0px' }}>
              <Divider />
            </div>
            <ConfirmInvoiceContactForm
              newContact={newContact}
              setNewContact={setNewContact}
              contacts={contacts}
              setContact={setContact}
              contact={contact}
            />
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <CustomButton color="secondary" onClick={() => setOpen(false)}>
          Salir
        </CustomButton>
        <CustomButton color="primary" onClick={() => handleSubmit()}>
          Confirmar
        </CustomButton>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationModule;

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from './services/redux/store';
import LoginForm from './pages/LoginForm/index';
import AppRoutes from './routes';
import ExpressRoutes from './routesExpress';

import OpenRoutes from './routesOpen';
import { logout } from './services/redux/authSlice';

export const DATA_VERSION = '1.1';

const App: React.FC = () => {
  const dispatch = useDispatch();
  const auth = useSelector((state: RootState) => state.auth);

  if (auth.isAuthenticated) {
    // List of properties that the User type should have
    const userProperties = [
      'email',
      'name',
      'companyName',
      'permissionPolicy',
      'id',
      'roles',
      'companyTypes',
      'companyId',
    ];
    // Check if auth.user has all properties
    const hasAllProperties = userProperties.every(
      (prop) => auth.user && auth.user.hasOwnProperty(prop),
    );
    if (auth.user?.dataVersion !== DATA_VERSION) {
      console.log('Data version mismatch');
      dispatch(logout());
      return <LoginForm />;
    }
    if (!hasAllProperties) {
      console.log('Missing properties');
      dispatch(logout());
      return <LoginForm />; // Redirect to login or appropriate place after lsogging out
    }

    if (auth.user?.express) {
      return <ExpressRoutes />;
    }
    return <AppRoutes />;
  } else {
    return <OpenRoutes />;
  }
};

export default App;

import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import {
  DialogActions,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material';
import CustomButton from '../../../../designSystem/Button';
import { postTransferPortfolio } from '../../../../api/portfolio';
import { useDispatch } from 'react-redux';

interface TransferListProps {
  options: any[];
  setLoading: (loading: boolean) => void;
  setOpen: (open: boolean) => void;
  reload: number;
  setReload: (reload: number) => void;
  setSuccess: (success: boolean) => void;
  setError: (error: boolean) => void;
  selectedUserId?: number;
}

function not(a: readonly any[], b: readonly any[]) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a: readonly any[], b: readonly any[]) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

function union(a: readonly any[], b: readonly any[]) {
  return [...a, ...not(b, a)];
}

export default function SelectAllTransferList(props: TransferListProps) {
  const {
    options,
    setLoading,
    setOpen,
    setError,
    setSuccess,
    reload,
    setReload,
    selectedUserId,
  } = props;
  const dispatch = useDispatch();
  const [checked, setChecked] = useState<readonly any[]>([]);
  const [originUser, setOriginUser] = useState<any>();
  const [destinationUser, setDestinationUser] = useState<any>();
  const [originPortfolio, setOriginPortfolio] = useState<any[]>([]);
  const [destinationPortfolio, setDestinationPortfolio] = useState<any[]>([]);
  const [loadingButton, setLoadingButton] = useState(false);
  const leftChecked = intersection(checked, originPortfolio);
  const [destinationOptions, setDestinationOptions] = useState<any[]>(options);

  const handleToggle = (value: any) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const numberOfChecked = (items: readonly number[]) =>
    intersection(checked, items).length;

  const handleCheckedRight = () => {
    setDestinationPortfolio(destinationPortfolio.concat(leftChecked));
    setOriginPortfolio(not(originPortfolio, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleAllRight = () => {
    setDestinationPortfolio(originPortfolio.concat(destinationPortfolio));
    setOriginPortfolio([]);
  };

  const handleSelectOriginUser = (value: number) => {
    const user = options.find((option) => option.id === value);
    setOriginUser(user);
    setDestinationOptions(not(options, [user]));
    setOriginPortfolio(user.business);
  };

  const handleSelectDestinationUser = (value: number) => {
    const user = destinationOptions.find((option) => option.id === value);
    setDestinationUser(user);
    setDestinationPortfolio(user.business);
  };

  const handleSave = async () => {
    setLoading(true);
    setLoadingButton(true);
    const payload = {
      transfer: {
        userId: destinationUser.id,
        portfolioIds: destinationPortfolio.map((portfolio) => portfolio.id),
      },
    };
    try {
      await postTransferPortfolio(dispatch, payload);
      setOpen(false);
      setSuccess(true);
      setLoading(false);
      setLoadingButton(false);
    } catch {
      setError(true);
      setLoading(false);
      setLoadingButton(false);
    } finally {
      setReload(reload + 1);
    }
  };

  const customList = (user: any, items: readonly any[], origin: boolean) => (
    <Card>
      <CardHeader
        title={
          <FormControl sx={{ m: 1, width: '300px' }}>
            <InputLabel id="demo-simple-select-helper-label">
              Usuario
            </InputLabel>
            <Select
              labelId="demo-simple-select-helper-label"
              id="demo-simple-select-helper"
              label="Usuario"
              disabled={!origin && !originUser}
              value={user?.id}
              defaultValue={selectedUserId}
              onChange={(e) =>
                origin
                  ? handleSelectOriginUser(parseInt(e.target.value))
                  : handleSelectDestinationUser(parseInt(e.target.value))
              }
              fullWidth
            >
              {(origin ? options : destinationOptions).map((option) => (
                <MenuItem key={option.id} value={parseInt(option.id)}>
                  {option.name} ({option.email})
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        }
        subheader={`${numberOfChecked(items)}/${items.length} seleccionado`}
      />
      <Divider />
      <List
        sx={{
          height: 230,
          bgcolor: 'background.paper',
          overflow: 'auto',
        }}
        dense
        component="div"
        role="list"
      >
        {items.map((value: any) => {
          return (
            <ListItemButton
              key={value.id}
              role="listitem"
              onClick={handleToggle(value)}
              sx={{ width: '100%' }}
            >
              <ListItemIcon>
                <Checkbox
                  checked={checked.indexOf(value) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{
                    'aria-labelledby': value.id,
                  }}
                />
              </ListItemIcon>
              <ListItemText id={value.id} primary={value.name} />
            </ListItemButton>
          );
        })}
      </List>
    </Card>
  );

  useEffect(() => {
    if (selectedUserId) {
      handleSelectOriginUser(selectedUserId);
    }
  }, [selectedUserId]);

  return (
    <>
      <Grid container spacing={2} justifyContent="center" alignItems="center">
        <Grid item>{customList(originUser, originPortfolio, true)}</Grid>
        <Grid item>
          <Grid container direction="column" alignItems="center">
            <Button
              sx={{ my: 0.5 }}
              variant="outlined"
              size="small"
              onClick={handleCheckedRight}
              disabled={
                leftChecked.length === 0 || !originUser || !destinationUser
              }
              aria-label="move selected right"
            >
              &gt;
            </Button>
            <Button
              sx={{ my: 0.5 }}
              variant="outlined"
              size="small"
              onClick={handleAllRight}
              disabled={
                originPortfolio.length === 0 || !originUser || !destinationUser
              }
              aria-label="move selected right"
            >
              &gt; &gt;
            </Button>
          </Grid>
        </Grid>
        <Grid item>
          {customList(destinationUser, destinationPortfolio, false)}
        </Grid>
      </Grid>
      <DialogActions>
        {' '}
        <CustomButton
          color="secondary"
          onClick={() => {
            setOpen(false);
          }}
        >
          {' '}
          Cancelar{' '}
        </CustomButton>
        <CustomButton
          disabled={loadingButton || !originUser || !destinationUser}
          onClick={() => handleSave()}
        >
          {' '}
          Guardar{' '}
        </CustomButton>
      </DialogActions>
    </>
  );
}

import { exportToExcel } from '../../../../designSystem/DataExporter/utils/exportToExcel';

export const handleExportSantanderXLSX = async (
  payments: any,
  id: any,
  message?: string,
) => {
  const messsage = message || `Operación ${id}`;
  const headers = [
    { key: 'bankAccountOriginNumber', label: 'Cuenta origen (obligatorio)' },
    { key: 'currencyOrigin', label: 'Moneda origen (obligatorio)' },
    {
      key: 'bankAccountDestinationNumber',
      label: 'Cuenta destino (obligatorio)',
    },
    { key: 'currencyDestination', label: 'Moneda destino (obligatorio)' },
    {
      key: 'bankAccountDestinationBankCode',
      label:
        'Código banco destino (obligatorio solo si banco destino no es Santander)',
    },
    {
      key: 'bankAccountDestinationHolderIdentifier',
      label:
        'RUT beneficiario (obligatorio solo si banco destino no es Santander)',
    },
    {
      key: 'bankAccountDestinationHolderName',
      label:
        'Nombre beneficiario (obligatorio solo si banco destino no es Santander)',
    },
    { key: 'amount', label: 'Monto transferencia (obligatorio)' },
    {
      key: 'customizedTransferNote',
      label: 'Glosa personalizada transferencia (opcional)',
    },
    {
      key: 'bankAccountDestinationHolderEmail',
      label: 'Correo beneficiario (opcional)',
    },
    { key: 'emailMessage', label: 'Mensaje correo beneficiario (opcional)' },
    {
      key: 'originatorStatementNote',
      label: 'Glosa cartola originador (opcional)',
    },
    {
      key: 'beneficiaryStatementNote',
      label:
        'Glosa cartola beneficiario (opcional, solo aplica si cuenta destino es Santander)',
    },
  ];

  const data = payments.map((x: any) => {
    return {
      bankAccountOriginNumber: x.bankAccountOriginNumber,
      currencyOrigin: x.currency,
      bankAccountDestinationNumber: x.bankAccountDestinationNumber,
      currencyDestination: x.currency,
      bankAccountDestinationBankCode: x.bankAccountDestinationBankCode,
      bankAccountDestinationHolderIdentifier:
        x.bankAccountDestinationHolderIdentifier.split('-').join(''),
      bankAccountDestinationHolderName: x.bankAccountDestinationHolderName,
      amount: x.amount,
      customizedTransferNote: messsage,
      bankAccountDestinationHolderEmail: x.bankAccountDestinationHolderEmail,
      emailMessage: messsage,
      originatorStatementNote: messsage,
      beneficiaryStatementNote: messsage,
    };
  });

  await exportToExcel(data, headers, `Santander_${id}.xlsx`);
};

import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  TextField,
  Typography,
  Grid,
  IconButton,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import CenteredCircularProgress from '../../../../../../../lib/designSystem/CircularProgress';
import { Add } from '@mui/icons-material';
import {
  formatDateBack,
  formatMoney,
  formatMoneyBack,
  validaRut,
} from '../../../../../../../lib/functions/utils';
import { checkSIIDocumentForUploadingInvoices } from '../../../../../../../lib/api/sii';

const AddInvoicesForm = (props: any) => {
  const { setInvoices, invoices, business, stakeholderIdentifier } = props;
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const newInvoiceDefault = {
    folio: '',
    businessIdentifier: business ? business.identifier : '',
    stakeholderIdentifier: stakeholderIdentifier ? stakeholderIdentifier : '',
    stakeholderName: '',
    issuedDate: '',
    amount: 0,
    type: '',
  };
  const [newInvoice, setNewInvoice] = useState(newInvoiceDefault);

  const checkWithSII = async (invoices: any) => {
    try {
      const response = await checkSIIDocumentForUploadingInvoices(dispatch, {
        invoices,
      });
      return response[0].valid;
    } catch (error) {
      console.log(error);
    }
  };

  const existingFolio = (folio: string) => {
    return invoices.some((invoice: any) => invoice.folio === folio);
  };

  const validateRuts = () => {
    if (validaRut(newInvoice.stakeholderIdentifier)) {
      return true;
    }
    return false;
  };

  const checkAllFields = () => {
    if (
      newInvoice.folio === '' ||
      newInvoice.businessIdentifier === '' ||
      newInvoice.stakeholderIdentifier === '' ||
      newInvoice.issuedDate === '' ||
      !newInvoice.amount
    ) {
      alert('Debes llenar todos los campos');
      setLoading(false);
      return false;
    }
    return true;
  };

  const handleChangeDate = (e: any) => {
    const [year, month, day] = e.target.value.split('-');
    setNewInvoice({ ...newInvoice, issuedDate: `${day}${month}${year}` });
  };

  const handleAddInvoice = async () => {
    setLoading(true);
    if (existingFolio(newInvoice.folio)) {
      alert('Ya existe una factura con este folio');
      setLoading(false);
      return;
    }
    if (!checkAllFields()) return;
    if (!validateRuts()) {
      alert('RUT inválido');
      setLoading(false);
      return;
    }
    if (await checkWithSII([newInvoice])) {
      newInvoice.issuedDate = formatDateBack(newInvoice.issuedDate);
      setInvoices((prev: any) => [
        ...prev,
        {
          ...newInvoice,
          id: `${newInvoice.folio}-${newInvoice.businessIdentifier}`,
        },
      ]);
      setNewInvoice(newInvoiceDefault);
    } else {
      alert('La factura no existe en el SII');
    }
    setLoading(false);
  };

  if (loading) return <CenteredCircularProgress type="dialog" />;

  return (
    <div>
      <Typography variant="body1">
        A continuación, ingresa los datos de las facturas que quieres verificar.
      </Typography>
      <div style={{ padding: '20px 0px' }}>
        <Grid container spacing={2} alignItems={'center'}>
          <Grid item xs={2}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Tipo</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Tipo"
                value={newInvoice.type}
                onChange={(e) =>
                  setNewInvoice({ ...newInvoice, type: String(e.target.value) })
                }
              >
                <MenuItem value="electronic-invoice">
                  Factura electrónica
                </MenuItem>
                <MenuItem value="electronic-invoice-exempt">
                  Factura exenta
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={2}>
            <TextField
              fullWidth
              label="Folio"
              value={newInvoice.folio}
              onChange={(e) =>
                setNewInvoice({ ...newInvoice, folio: e.target.value })
              }
            />
          </Grid>
          {!business && (
            <Grid item xs={2}>
              <TextField
                fullWidth
                label="RUT emisor *"
                value={newInvoice.businessIdentifier}
                onChange={(e) =>
                  setNewInvoice({
                    ...newInvoice,
                    businessIdentifier: e.target.value,
                  })
                }
              />
            </Grid>
          )}
          <Grid item xs={2}>
            <TextField
              fullWidth
              label="RUT receptor *"
              value={newInvoice.stakeholderIdentifier}
              // disabled={stakeholderIdentifier}
              onChange={(e) =>
                setNewInvoice({
                  ...newInvoice,
                  stakeholderIdentifier: e.target.value,
                })
              }
            />
          </Grid>
          {/* {business && (
            <Grid item xs={2}>
              <TextField
                fullWidth
                label="Nombre"
                value={newInvoice.stakeholderName}
                onChange={(e) =>
                  setNewInvoice({
                    ...newInvoice,
                    stakeholderName: e.target.value,
                  })
                }
              />
            </Grid>
          )} */}
          <Grid item xs={3}>
            <TextField
              fullWidth
              label="Fecha de Emisión"
              type="date"
              value={formatDateBack(newInvoice.issuedDate)}
              onChange={(e) => handleChangeDate(e)}
            />
          </Grid>
          <Grid item xs={2}>
            <TextField
              fullWidth
              label="Monto"
              value={formatMoney(newInvoice.amount)}
              onChange={(e) =>
                setNewInvoice({
                  ...newInvoice,
                  amount: Number(formatMoneyBack(e.target.value)),
                })
              }
            />
          </Grid>

          <Grid item xs={1}>
            {loading ? (
              <CircularProgress size={24} />
            ) : (
              <IconButton
                color="primary"
                onClick={() => {
                  handleAddInvoice();
                }}
              >
                <Add />
              </IconButton>
            )}
          </Grid>
        </Grid>
        <Typography variant="caption">* Rut sin puntos y con guión</Typography>
      </div>
    </div>
  );
};

export default AddInvoicesForm;

import React, { useState, useEffect } from 'react';
import { Grid, PopperPlacementType, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';
import SimpleLineChart from './components/LineChart';
import { getCessionsSummary } from '../../../../../../../lib/api/dashboard';
import CenteredCircularProgress from '../../../../../../../lib/designSystem/CircularProgress';
import EnhacedBox from '../../../../../../../lib/designSystem/BigBox';
import CustomPieChart from '../../../../../../../lib/designSystem/PieChart';
import EnhancedTable from '../../../../../../../lib/designSystem/Table';
import MiniPieChart from './components/miniPieChart';
import { LightTooltip } from '../../../../../../../lib/designSystem/WhiteTooltip';
import { Info } from '@mui/icons-material';

const MyWalletSummary = () => {
  const dispatch = useDispatch();
  const [cessionsSummary, setCessionsSummary] = useState({
    graph: [],
    businessTable: [],
    factoringTable: [],
    last6Months: [],
    summary: {
      lastMonth: [],
    },
  });
  const [loading, setLoading] = useState(false);
  const [selectedMonths, setSelectedMonths] = useState<any>([]);
  const [otherFactoringsSelected, setOtherFactoringsSelected] = useState<any>(
    [],
  );
  const [openBusinessPopper, setOpenBusinessPopper] = React.useState(false);
  const [businessAnchorEl, setBusinessAnchorEl] =
    React.useState<HTMLButtonElement | null>(null);
  const [businessPlacement, setBusinessPlacement] =
    React.useState<PopperPlacementType>('right');
  const [selectedBusinessDate, setSelectedBusinessDate] = useState<any>(null);
  const [selectedBusiness, setSelectedBusiness] = useState<any>(null);

  const [openFactoringPopper, setOpenFactoringPopper] = React.useState(false);
  const [factoringAnchorEl, setFactoringAnchorEl] =
    React.useState<HTMLButtonElement | null>(null);
  const [factoringPlacement, setFactoringPlacement] =
    React.useState<PopperPlacementType>('right');
  const [selectedFactoringDate, setSelectedFactoringDate] = useState<any>(null);
  const [selectedFactoring, setSelectedFactoring] = useState<any>(null);

  const fetchCessions = async () => {
    setLoading(true);
    try {
      const cessionsSummaryResponse = await getCessionsSummary(dispatch);
      setCessionsSummary(cessionsSummaryResponse || []);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const handleBusinessSelectPercentage = (key: string, id: string, e: any) => {
    const business: any = cessionsSummary.businessTable.find(
      (x: any) => x.businessIdentifier === id,
    );
    if (!business) return;
    const dateSelected = business.otherFactorings[key];
    if (!dateSelected)
      return alert('Este cliente no tuvo cesiones en este mes');

    const factoringsValues = Object.keys(dateSelected).map((x) => ({
      name: x,
      value: dateSelected[x],
    }));
    console.log(factoringsValues);
    setOtherFactoringsSelected(factoringsValues);
    setSelectedBusiness(business);
    setSelectedBusinessDate(key === 'allYear' ? 'Año completo' : key);
    handleClickBusinessPopper('right')(e);
  };

  const handleFactoringSelectPercentage = (key: string, id: string, e: any) => {
    const factoring: any = cessionsSummary.factoringTable.find(
      (x: any) => x.id === id,
    );
    if (!factoring) return;
    const dateSelected = factoring.businesses[key];
    if (!dateSelected)
      return alert('Este factoring no tuvo cesiones en este mes');

    const businessValues = Object.keys(dateSelected).map((x) => ({
      name: x,
      value: dateSelected[x],
    }));

    setOtherFactoringsSelected(businessValues);
    setSelectedFactoring(factoring);
    console.log(key);
    setSelectedFactoringDate(key === 'allYear' ? 'Año completo' : key);
    handleClickFactoringPopper('right')(e);
  };

  const handleClickBusinessPopper =
    (newPlacement: PopperPlacementType) =>
    async (event: React.MouseEvent<HTMLButtonElement>) => {
      setBusinessAnchorEl(event.currentTarget);
      setOpenBusinessPopper(
        (prev) => businessPlacement !== newPlacement || !prev,
      );
      setBusinessPlacement(newPlacement);
    };

  const handleClickFactoringPopper =
    (newPlacement: PopperPlacementType) =>
    async (event: React.MouseEvent<HTMLButtonElement>) => {
      setFactoringAnchorEl(event.currentTarget);
      setOpenFactoringPopper(
        (prev) => factoringPlacement !== newPlacement || !prev,
      );
      setFactoringPlacement(newPlacement);
    };

  useEffect(() => {
    fetchCessions();
  }, []);

  if (loading) return <CenteredCircularProgress type="layout" />;

  return (
    <div>
      <Typography variant="h5">Cómo vamos? </Typography>
      <Grid container spacing={2}>
        <Grid item xs={8}>
          <EnhacedBox>
            <SimpleLineChart
              aspect={4.0 / 1.5}
              data={cessionsSummary.graph}
              selectedMonths={selectedMonths}
              setSelectedMonths={setSelectedMonths}
            />
          </EnhacedBox>
        </Grid>
        <Grid item xs={4}>
          <EnhacedBox>
            <div style={{ minHeight: 290 }}>
              <Typography variant="body1" fontWeight={'bold'}>
                Este mes...
              </Typography>
              <div style={{ padding: '6px 0px' }}>
                <CustomPieChart
                  data={cessionsSummary.summary.lastMonth}
                  money
                />
              </div>
            </div>
          </EnhacedBox>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h5">Penetración de mercado</Typography>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Typography variant="body1" fontWeight="bold">
              Por clientes
            </Typography>
            <LightTooltip
              placement="right"
              title={
                <Typography variant="body1" padding={1}>
                  Estos son los porcentajes de penetración de tu factoring en
                  cada cliente. Si apretas en un porcentaje, podrás ver la
                  distribución.
                </Typography>
              }
            >
              <Info style={{ fontSize: 16, marginLeft: 8 }} />
            </LightTooltip>
          </div>
          <EnhancedTable
            data={cessionsSummary.businessTable}
            dataHeaders={[
              { key: 'businessName', label: 'Cliente' },
              {
                key: 'allYear',
                label: 'Año completo',
                type: 'percentage',
                onClick: handleBusinessSelectPercentage,
              },
              ...(cessionsSummary.last6Months.map((x: any) => ({
                key: x,
                label: x,
                type: 'percentage',
                onClick: handleBusinessSelectPercentage,
              })) || []),
            ]}
            finalSumRow
            exportToFile="xlsx"
          />
        </Grid>
        <Grid item xs={12}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Typography variant="body1" fontWeight="bold">
              Por factorings
            </Typography>
            <LightTooltip
              placement="right"
              title={
                <Typography variant="body1" padding={1}>
                  Estos son los porcentajes de penetración de otros factorings
                  en tus cesiones perdidas. Si apretas un porcentaje, podrás ver
                  la distribución.
                </Typography>
              }
            >
              <Info style={{ fontSize: 16, marginLeft: 8 }} />
            </LightTooltip>
          </div>
          <EnhancedTable
            data={cessionsSummary.factoringTable}
            dataHeaders={[
              { key: 'cessionaryName', label: 'Factoring' },
              {
                key: 'allYear',
                label: 'Año completo',
                type: 'percentage',
                onClick: handleFactoringSelectPercentage,
              },
              ...(cessionsSummary.last6Months.map((x: any) => ({
                key: x,
                label: x,
                type: 'percentage',
                onClick: handleFactoringSelectPercentage,
              })) || []),
            ]}
            finalSumRow
            exportToFile="xlsx"
          />
        </Grid>
      </Grid>
      <MiniPieChart
        data={otherFactoringsSelected}
        openPopper={openBusinessPopper}
        setOpenPopper={setOpenBusinessPopper}
        anchorEl={businessAnchorEl}
        setAnchorEl={setBusinessAnchorEl}
        placement={businessPlacement}
        handleClickPopper={handleClickBusinessPopper}
        date={selectedBusinessDate}
        name={selectedBusiness?.businessName}
      />
      <MiniPieChart
        data={otherFactoringsSelected}
        openPopper={openFactoringPopper}
        setOpenPopper={setOpenFactoringPopper}
        anchorEl={factoringAnchorEl}
        setAnchorEl={setFactoringAnchorEl}
        placement={factoringPlacement}
        handleClickPopper={handleClickFactoringPopper}
        date={selectedFactoringDate}
        name={selectedFactoring?.cessionaryName}
      />
    </div>
  );
};

export default MyWalletSummary;

import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
  getBusinessBankruptcyPosts,
  getConsultorResponse,
} from '../../../../../lib/api';
import HeaderWithActions from '../../../../../lib/designSystem/HeaderWithActions';
import CenteredCircularProgress from '../../../../../lib/designSystem/CircularProgress';
import EnhancedTable from '../../../../../lib/designSystem/Table';
const bankruptcyHeaders = [
  { key: 'Rol', label: 'Rol' },
  { key: 'Procedimiento Concursal', label: 'Procedimiento ' },
  { key: 'Deudor', label: 'Deudor' },
  { key: 'Veedor Liquidador Titular', label: 'Veedor Liquidador Titular' },
  { key: 'Nombre Publicación', label: 'Publicación' },
  { key: 'Tribunal', label: 'Tribunal' },
  { key: 'Fecha Publicación', label: 'Publicación' },
];
const BaknrruptcyPosts = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState<any>([]);
  const { id } = useParams<{
    id: string;
    businessId: string;
    page: string;
  }>();

  const fetchRelations = async () => {
    if (!id) {
      alert('No se ha encontrado el id de la empresa');
      return;
    }
    setLoading(true);
    const responseConsult = await getConsultorResponse(dispatch, {
      identifier: id.toLocaleLowerCase(),
      consult: 'bankruptcy',
    });
    console.log(responseConsult);
    setResponse(responseConsult[id.toLocaleLowerCase()] || []);
    setLoading(false);
  };

  useEffect(() => {
    fetchRelations();
  }, []);

  if (loading) return <CenteredCircularProgress relative />;

  return (
    <div>
      <HeaderWithActions variant="h6" subHeader buttons={[]} title="" />
      <EnhancedTable
        dataHeaders={bankruptcyHeaders}
        data={response}
        rowsPerPageDefault={100}
      />
      {loading && <CenteredCircularProgress relative />}
    </div>
  );
};

export default BaknrruptcyPosts;

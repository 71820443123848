import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { AuthState } from '../../../../../../services/redux/authSlice';
import FinanceHubForm from './components/financeHubForm';
import SimulationForm from './components/simluationForm';
import { formatMoneyBack } from '../../../../../../lib/functions/utils';

const Parameters = (props: any) => {
  const {
    setFormData,
    formData,
    selectedRequest,
    updateSimulationValues,
    executiveCreating,
    setDocumentsToApply,
    onlySimulation,
    subProduct,
    onEditing,
    liveForm,
  } = props;
  const user = useSelector((state: { auth: AuthState }) => state.auth.user);
  const [documents, setDocuments] = useState<any>([]);

  const inRange = (value: number | string) => {
    return Number(value) >= 0 && Number(value) <= 100;
  };

  const handleSelectDocument = (ids: string[]) => {
    setFormData({
      ...formData,
      selectedDocumentsToApply: ids,
    });
  };

  const handleChange = (id: string, newValue: string) => {
    const moneyIds = ['operationCostComissionFixed', 'expensesCost'];
    // if (newValue === '') newValue = '0';
    console.log(formatMoneyBack(newValue));
    const value = moneyIds.includes(id)
      ? formatMoneyBack(newValue) || 0
      : newValue;
    if (isNaN(Number(value))) return;
    if (id === 'advance' && !inRange(value)) return;
    if (id === 'operationCostComissionVariable') {
      if (!inRange(value)) return;
      const variableAmount = selectedRequest.amount * (Number(value) / 100);
      const actualForm = {
        ...formData,
        operationCostComissionVariable: variableAmount,
        operationCostComissionVariablePercentage: value,
      };
      updateSimulationValues({
        actualForm,
      });
      return setFormData({
        ...formData,
        [id]: variableAmount,
        operationCostComissionVariablePercentage: value,
      });
    }
    if (id === 'rate' && !inRange(value)) return;
    if (id === 'arrearRate' && !inRange(value)) return;
    setFormData({
      ...formData,
      [id]: value,
    });
    updateSimulationValues({
      actualForm: { ...formData, [id]: value },
    });
  };

  return (
    <div>
      {onlySimulation ? (
        <SimulationForm
          formData={formData}
          setFormData={setFormData}
          handleChange={handleChange}
          selectedRequest={selectedRequest}
          updateSimulationValues={updateSimulationValues}
          subProduct={subProduct}
        />
      ) : (
        <FinanceHubForm
          liveForm={liveForm}
          formData={formData}
          setFormData={setFormData}
          handleChange={handleChange}
          executiveCreating={executiveCreating}
          user={user}
          subProduct={subProduct}
          selectedRequest={selectedRequest}
          updateSimulationValues={updateSimulationValues}
          setDocumentsToApply={setDocumentsToApply}
          documents={documents}
          setDocuments={setDocuments}
          selected={formData.selectedDocumentsToApply}
          setSelected={handleSelectDocument}
          onEditing={onEditing}
        />
      )}
    </div>
  );
};

export default Parameters;

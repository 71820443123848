// LoginForm.tsx
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { authenticate } from '../../../../services/redux/authSlice'; // Import the action
import {
  authenticateUser,
  getAutoEnrollmentStatus,
  isAFactoringCompany,
} from '../../../../lib/api';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import CenteredCircularProgress from '../../../../lib/designSystem/CircularProgress';
import CustomButton from '../../../../lib/designSystem/Button';
import { Box, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import { Copyright } from '../../../PublicLandings/EnrolationForm';
import { DATA_VERSION } from '../../../../App';

const LoginExpress = () => {
  const { clientId } = useParams<{ clientId: string }>();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoding] = useState(false);
  const [canAccess, setCanAccess] = useState(false);

  const dispatch = useDispatch();

  const handleSubmit = async (e: React.FormEvent): Promise<void> => {
    try {
      e.preventDefault();
      setLoding(true);
      const response = await authenticateUser(username, password);

      if (response.success) {
        if (!response.active) {
          alert(
            'Debes activar tu cuenta con el link que se te envió al email con que hiciste el registro. Si no has recibido el link, comunicate con soporte.',
          );
          return setLoding(false);
        }
        const autoEnrolationStatus = await getAutoEnrollmentStatus(dispatch);
        const userDetails = {
          email: response.email,
          name: response.name,
          companyName: response.companyName,
          companyId: response.companyId,
          permissionPolicy: response.permissionPolicy,
          id: response.id,
          roles: response.roles,
          companyTypes: response.companyTypes,
          express: true,
          clientId,
          autoEnrrolled: autoEnrolationStatus.success,
          businessId: autoEnrolationStatus.businessId,
          businessIdentifier: autoEnrolationStatus.businessIdentifier,
          dataVersion: DATA_VERSION,
        };
        console.log(userDetails);
        dispatch(authenticate(userDetails));
      } else {
        alert('Credenciales inválidas');
      }
    } catch (error) {
      alert('Hubo un error');
    }
    setLoding(false);
  };

  const setAccess = async () => {
    try {
      setLoding(true);

      const companyResponse = await isAFactoringCompany(
        dispatch,
        clientId as string,
      );
      console.log(companyResponse);
      setCanAccess(companyResponse.isFactoringCompany);
    } catch (error) {
      setCanAccess(false);
    }
    setLoding(false);
  };

  useEffect(() => {
    setAccess();
  }, []);

  if (loading) return <CenteredCircularProgress type="dialog" />;

  if (!canAccess) return <div>No encontramos esta página</div>;

  return (
    <div style={{ backgroundColor: '#F8FAFC', height: '100vh' }}>
      <div style={{ margin: 'auto', maxWidth: '40%', padding: 40 }}>
        <Typography variant="h4" textAlign="center">
          Iniciar Sesión{' '}
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Email"
              variant="outlined"
              id="email"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Contraseña"
              fullWidth
              type="password"
              variant="outlined"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              id="password"
              autoComplete="new-password"
            />
          </Grid>
        </Grid>
        <Box sx={{ mt: 2 }}>
          <CustomButton
            onClick={(e) => handleSubmit(e)}
            fullWidth
            color="primary"
            variant="contained"
          >
            Entrar
          </CustomButton>
        </Box>
        <Box sx={{ mt: 2 }} display="flex" justifyContent="space-between">
          <Typography>
            <a href={`/express/${clientId}/sign-in`}>Registrarse</a>
          </Typography>
          <Typography>
            <a href={`/express/${clientId}/password-recovery`}>
              Olvidé mi constraseña{' '}
            </a>
          </Typography>
        </Box>
      </div>
      <Copyright />
    </div>
  );
};

export default LoginExpress;

import React from 'react';
import { useSelector } from 'react-redux';
import FactoringRequestsRecieved from '../../../../Finance/Components/Financing/Factoring/components/Recieved';
import OperativeInfo from './components/OperativeInfo';
import { validateCompanyTypes } from '../../../../../lib/functions/utils';
import { AuthState } from '../../../../../services/redux/authSlice';

const OperativeProfile = () => {
  const user = useSelector((state: { auth: AuthState }) => state.auth.user);

  return (
    <div>
      {!validateCompanyTypes(user, 'Accountant') && (
        <div style={{ padding: '20px 0px' }}>
          <OperativeInfo />
        </div>
      )}
      <FactoringRequestsRecieved profile />
    </div>
  );
};

export default OperativeProfile;

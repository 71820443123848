import { CircularProgress, IconButton, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { styled } from '@mui/material/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';
import EnhancedTable from '../../../../../../lib/designSystem/Table';
import { validateCompanyTypes } from '../../../../../../lib/functions/utils';
import { AuthState } from '../../../../../../services/redux/authSlice';
import { useDispatch, useSelector } from 'react-redux';
import CustomButton from '../../../../../../lib/designSystem/Button';
import { syncInvoicesCessionWithSII } from '../../../../../../lib/api';
import CustomAlert from '../../../../../../lib/designSystem/Alert';

export const ExpandMore = styled((props: any) => {
  const { ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }: { theme: any; expand: any }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginRight: '10px',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

const DocumentsPreview = (props: any) => {
  const {
    selectedRequest,
    setSelectedRequest,
    simulationInProgress,
    documentsInSelection,
    updateSimulationValues,
    selected,
    setSelected,
  } = props;
  const user = useSelector((state: { auth: AuthState }) => state.auth.user);
  const [miniLoading, setMiniLoading] = React.useState(false);
  const [openSuccess, setOpenSuccess] = React.useState(false);
  const [openFailed, setOpenFailed] = React.useState(false);
  const dispatch = useDispatch();

  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  let headers: any = [{ key: 'folio', label: 'Folio' }];

  headers.unshift({ key: 'active', label: '', type: 'checked' });

  headers = [
    ...headers,

    { key: 'cedded', label: 'Cedida', type: 'cession-lights' },

    {
      key: 'cancelDate',
      label: 'Reclamos?',
      type: 'cancel-lights',
      canNull: true,
    },
    {
      key: 'confirmDate',
      label: 'Confirmada',
      type: 'confirm-lights',
      canNull: true,
    },
  ];

  headers = [
    ...headers,
    { key: 'stakeholderName', label: 'Pagador' },
    { key: 'issuedDate', label: 'Emisión', type: 'date' },
    // ...(selectedRequest.status === 'Aceptada'
    //   ? []
    //   : [{ key: 'confirmDate', label: 'Confirmada', type: 'date' }]),
    { key: 'paymentDate', label: 'Pago', type: 'date' },
    { key: 'amount', label: 'Monto Doc.', type: 'money' },
  ];

  if (simulationInProgress) {
    headers = [
      ...headers,
      { key: 'costRate', label: 'Costo tasa', type: 'money' },
      { key: 'daysToCount', label: 'Días' },
      { key: 'amountRetention', label: 'Excedente', type: 'money' },
      { key: 'amountDocument', label: 'Saldo documento', type: 'money' },
      { key: 'amountToDeposit', label: 'Saldo deuda', type: 'money' },
    ];
  }

  const updateActiveDocuments = (newSelected: any) => {
    if (updateSimulationValues) {
      const newSelectedRequest = selectedRequest;
      for (const document of newSelectedRequest.documents) {
        if (newSelected.includes(document.id)) {
          document.active = true;
        } else {
          document.active = false;
        }
      }
      updateSimulationValues({ newSelectedRequest });
    }
  };

  const handleSyncWithSII = async () => {
    setMiniLoading(true);
    try {
      const response = await syncInvoicesCessionWithSII(dispatch, {
        quoteId: selectedRequest.quoteActive.id,
      });
      if (response.update) {
        addNewSyncDataToInvoices(
          response.documentsWithCessionData,
          selectedRequest.documents,
        );
        setOpenSuccess(true);
      } else {
        setOpenFailed(true);
      }
    } catch (error) {
      console.log('error', error);
    }
    setMiniLoading(false);
  };

  const addNewSyncDataToInvoices = (
    invoicesWithNewData: any,
    oldsInvoices: any,
  ) => {
    const invoicesMerged = oldsInvoices.map((oldInvoice: any) => {
      const newInvoice = invoicesWithNewData.find(
        (invoice: any) => invoice.id === oldInvoice.id,
      );
      if (newInvoice) {
        return {
          ...oldInvoice,
          cancelDate: newInvoice.cancelled,
          cedded: newInvoice.cedded,
        };
      }
    });
    console.log('invoicesMerged', invoicesMerged);
    setSelectedRequest({
      ...selectedRequest,
      documents: invoicesMerged,
    });
  };

  useEffect(() => {
    updateActiveDocuments(selected);
  }, []);

  // const showActions =
  //   selectedRequest.documents.filter((document: any) => !document.confirmDate)
  //     .length > 0;

  // const actions = [
  //   {
  //     action: canVerificate,
  //     actionLabel: 'Verificar',
  //     canShow: canVerificate,
  //   },
  // ];

  return (
    <div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <div style={{ padding: 0, display: 'flex', alignItems: 'center' }}>
          <Typography fontWeight="bold" padding="0px 0px 6px" variant="h6">
            Detalle documentos
          </Typography>
          <ExpandMore
            expand={expanded}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
          >
            <ExpandMoreIcon />
          </ExpandMore>
        </div>
        <div>
          <div style={{ padding: '20px 0px' }}>
            {miniLoading ? (
              <CircularProgress />
            ) : (
              selectedRequest.status === 'En revisión' && (
                <div>
                  <CustomButton onClick={handleSyncWithSII}>
                    Sincronizar SII
                  </CustomButton>
                </div>
              )
            )}
          </div>
        </div>
      </div>

      {simulationInProgress && !validateCompanyTypes(user, 'Accountant') && (
        <Typography variant="body1" padding="0px 0px 6px">
          * Si no puedes seleccionar una factura, es porque fue rechazada
        </Typography>
      )}
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <EnhancedTable
          dataHeaders={headers}
          data={selectedRequest.documents}
          rowsPerPageDefault={5}
          finalSumRow
          selected={documentsInSelection ? selected : null}
          setSelected={setSelected}
          updateValues={updateActiveDocuments}
        />
      </Collapse>
      <CustomAlert
        open={openSuccess}
        severity="success"
        text={
          'Se han actualizado los datos de los documentos con los datos del SII'
        }
        setOpen={setOpenSuccess}
      />
      <CustomAlert
        open={openFailed}
        title="No hay cambios"
        severity="warning"
        text="No hemos encontrado cambios en los documentos"
        setOpen={setOpenFailed}
      />
    </div>
  );
};

export default DocumentsPreview;

import React, { useEffect, useState } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { makeStyles, createStyles } from '@mui/styles';
import CustomButton from '../Button';
import {
  Chip,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Tooltip,
} from '@mui/material';
import { logout } from '../../../services/redux/authSlice';
import { useDispatch } from 'react-redux';
import { Close, Light, Notifications, Settings } from '@mui/icons-material';
import NotificationsDrawer from '../../../layouts/notificationDrawer';
import { getUnReadNotifications } from '../../api';
import { setPendingNotifications } from '../../../services/redux/notificactionSlice';
import { LightTooltip } from '../WhiteTooltip';

interface TabPanelProps {
  children: React.ReactElement; // Ensure children is a single React element
  title?: string;
  index: number;
  value: number;
  type?: 'principal' | 'secondary';
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, title, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 0 }}>
          <Typography>{React.cloneElement(children, { title })}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

interface TabRow {
  label: string;
  component: React.JSX.Element;
  disabled?: boolean;
  hoverText?: string;
}

interface EnhancedTabProps {
  tabs: TabRow[];
  startValue?: number;
  onTabChange?: any;
  title?: string;
  backAction?: any;
  infoChip?: any;
  colorChip?: any;
  canShowTab?: any;
  logoutButton?: any;
  tabType?: 'principal' | 'secondary';
  closeAction?: any;
  notificationsIcon?: boolean;
  updater?: any;
  context?: string;
  chip?: any;
  availableSettings?: any;
}

const useStyles = makeStyles(() =>
  createStyles({
    tabIndicator: {
      backgroundColor: '#0F23DF', // Change this to your desired color
      color: '#0F23DF',
    },
  }),
);

const paddingTitle: any = {
  principal: '18px 0px',
  secondary: '18px 0px',
};

const EnhancedTab: React.FC<EnhancedTabProps> = ({
  tabs = [],
  logoutButton,
  canShowTab,
  startValue = 0,
  onTabChange,
  title,
  backAction,
  infoChip,
  colorChip,
  context,
  closeAction,
  tabType = 'principal',
  notificationsIcon,
  availableSettings,
  updater,
  chip,
}) => {
  const [notificationDrawerOpen, setNotificationDrawerOpen] =
    React.useState(false);
  const classes = useStyles();
  const dispatch = useDispatch();
  const [notificationsPending, setNotificationsPending] = React.useState(0);
  const [anchorEl, setAnchorEl] = useState(null);

  const [value, setValue] = React.useState(startValue);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    if (onTabChange) {
      onTabChange(newValue);
    }
  };

  if (notificationsIcon) {
    const getPendingNotifications = async () => {
      try {
        const notifications = await getUnReadNotifications(dispatch);
        console.log(notifications);
        setNotificationsPending(notifications.length);
        dispatch(setPendingNotifications(notifications.length));
      } catch (error) {
        console.log(error);
      }
    };

    useEffect(() => {
      // getPendingNotifications();
    }, []);

    // useEffect(() => {
    //   const intervalId = setInterval(getPendingNotifications, 60000);
    //   // Cleanup on component unmount
    //   return () => clearInterval(intervalId);
    // }, []);
  }

  useEffect(() => {
    setValue(startValue);
  }, [startValue]);

  useEffect(() => {
    if (updater) {
      setValue(startValue);
    }
  }, [updater]);

  const handleLogout = () => {
    dispatch(logout());
  };

  return (
    <Box sx={{ width: '100%', padding: '0px 0px' }}>
      <Box
        sx={{
          padding: 0,
          display: 'flex',
          alignItems: 'center',
          width: '100%',
          justifyContent: 'space-between',
        }}
      >
        <Box
          sx={{
            padding: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-around',
          }}
        >
          {/* Title */}
          {title && (
            <Box sx={{ marginRight: 2, padding: paddingTitle[tabType] }}>
              <Typography variant="h3">{`${title.length > 26 ? `${title.slice(0, 26)}...` : title}`}</Typography>
            </Box>
          )}
          <Tabs
            classes={{ indicator: classes.tabIndicator }}
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
          >
            {tabs.map((x, index) => (
              <Tooltip
                key={index}
                title={x.hoverText || x.label}
                placement="bottom"
              >
                <Tab
                  color="#0F23DF"
                  label={x.label}
                  {...a11yProps(index)}
                  disabled={x.disabled}
                />
              </Tooltip>
            ))}
          </Tabs>
        </Box>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {backAction && (
            <CustomButton onClick={() => backAction(false)} color="primary">
              Atrás
            </CustomButton>
          )}
          {closeAction && (
            <IconButton onClick={() => closeAction()}>
              <Close />
            </IconButton>
          )}
          {context && (
            <LightTooltip
              title={<Typography padding="8px">{context}</Typography>}
            >
              <Typography variant="h3">
                {context.length > 20
                  ? `${context.substring(0, 20)}...`
                  : context}
              </Typography>
            </LightTooltip>
          )}
          {logoutButton && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                minWidth: 150,
              }}
            >
              {notificationsIcon && (
                <div style={{ position: 'relative' }}>
                  <IconButton onClick={() => setNotificationDrawerOpen(true)}>
                    <Notifications style={{ fontSize: 24 }} />
                  </IconButton>
                  {notificationsPending !== 0 ? (
                    <Typography
                      style={{
                        position: 'absolute',
                        top: -4,
                        right: -2,
                        color: 'white',
                        fontSize: 12,
                        padding: '4px 8px',
                        borderRadius: 50,
                        fontWeight: '400',
                        margin: '1px 2px',
                        backgroundColor: '#E8C547',
                      }}
                    >
                      {notificationsPending}
                    </Typography>
                  ) : (
                    <div />
                  )}
                </div>
              )}
              {/* <Divider orientation="vertical" flexItem />
            <CustomButton>Línea de crédito</CustomButton> */}
              <Divider orientation="vertical" flexItem />
              <IconButton
                onClick={(event: any) => setAnchorEl(event.currentTarget)}
              >
                <Settings
                  style={{
                    fontSize: 24,
                  }}
                />
              </IconButton>
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(null)}
              >
                {availableSettings &&
                  availableSettings.map((x: any, index: number) => (
                    <MenuItem key={index} onClick={x.action}>
                      {x.label}
                    </MenuItem>
                  ))}
                <Divider />

                <MenuItem onClick={handleLogout}>Salir</MenuItem>
              </Menu>
            </div>
          )}
          {infoChip && (
            <Stack direction="row" spacing={1}>
              <Chip
                label={infoChip}
                style={{ backgroundColor: colorChip, color: 'white' }}
              />
            </Stack>
          )}
          {chip}
        </div>
      </Box>
      {tabs.map((x, index) => (
        <CustomTabPanel key={index} title={title} value={value} index={index}>
          {canShowTab !== undefined && !canShowTab ? (
            <p>no existe esta página</p>
          ) : (
            x.component
          )}
        </CustomTabPanel>
      ))}
      <NotificationsDrawer
        open={notificationDrawerOpen}
        setOpen={setNotificationDrawerOpen}
      />
    </Box>
  );
};

export default EnhancedTab;

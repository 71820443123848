import React, { useState } from 'react';

import { TextField, Typography, Grid, Autocomplete } from '@mui/material';

const PaymentContainer = (props: any) => {
  const {
    setPayIn,
    payIn,
    setOpenBankAccountModal,
    setOpen,
    setAccountData,
    accountData,
    accounts,
    type,
  } = props;
  const [editPayIn, setEditPayIn] = useState(false);

  const handleChange = (event: any, newValue: any) => {
    setAccountData(newValue);
    setPayIn({ ...payIn, [type]: newValue.id });
    setEditPayIn(false);
  };

  const handleNewBankAccount = () => {
    setOpen(false);
    setOpenBankAccountModal(true);
  };

  return (
    <div>
      {editPayIn && (
        <div>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography variant="h6" fontWeight={600}>
              {type === 'bankAccountOriginId'
                ? 'Cuenta de origen del pago'
                : 'Cuenta de destino del pago'}
            </Typography>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              {editPayIn && (
                <Typography
                  margin="0px 0px 0px 10px"
                  variant="caption"
                  style={{ cursor: 'pointer' }}
                  onClick={() => setEditPayIn(false)}
                >
                  Volver
                </Typography>
              )}
            </div>
          </div>
          <Autocomplete
            options={accounts}
            getOptionLabel={(option) =>
              `${option.holderName} | ${option.bankName} | ${option.accountNumber}`
            }
            renderInput={(params: any) => (
              <TextField
                {...params}
                label="Titular | Empresa | Banco | Número de cuenta"
              />
            )}
            onChange={handleChange}
            fullWidth
          />
          <div style={{ textAlign: 'right' }}>
            <Typography
              variant="caption"
              color="primary"
              style={{ cursor: 'pointer' }}
              onClick={() => handleNewBankAccount()}
            >
              ¿Es una cuenta nueva? Agrégala aqui!
            </Typography>
          </div>
        </div>
      )}
      {!editPayIn ? (
        <div
          style={{
            padding: '20px 0px',
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Typography variant="h6" fontWeight={600}>
              {type === 'bankAccountOriginId'
                ? 'Cuenta de origen'
                : 'Cuenta de destino'}
            </Typography>
            <Typography
              variant="caption"
              fontWeight={600}
              color="primary"
              style={{ cursor: 'pointer' }}
              onClick={() => setEditPayIn(true)}
            >
              Editar
            </Typography>
          </div>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Typography
                variant="body1"
                fontWeight={600}
                color="textSecondary"
                padding="10px 0px"
              >
                Titular
              </Typography>
              <Typography variant="body1">{accountData.holderName}</Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography
                variant="body1"
                fontWeight={600}
                color="textSecondary"
                padding="10px 0px"
              >
                Banco
              </Typography>
              <Typography variant="body1">{accountData.bankName}</Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography
                variant="body1"
                fontWeight={600}
                color="textSecondary"
                padding="10px 0px"
              >
                N° Cuenta
              </Typography>
              <Typography variant="body1">
                {accountData.accountNumber}
              </Typography>
            </Grid>
          </Grid>
        </div>
      ) : null}
    </div>
  );
};

export default PaymentContainer;

import React, { useEffect, useState } from 'react';
import HeaderWithActions from '../../../../../../lib/designSystem/HeaderWithActions';
import EnhancedTable from '../../../../../../lib/designSystem/Table';
import { useDispatch } from 'react-redux';
import { Download } from '@mui/icons-material';
import ExcelJS from 'exceljs';
import Container from '@mui/material/Container';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import CenteredCircularProgress from '../../../../../../lib/designSystem/CircularProgress';
import DebtProjector from './components/DebtProjector';
import { getActiveMoneyReports } from '../../../../../../lib/api/reports';

const ActiveMoney: React.FC = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedDocuments, setSelectedDocuments] = useState<any>([]);
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();

  const exportToExcel = async () => {
    const plainData = data.map((item: any) => {
      return [
        item.businessIdentifier,
        item.businessName,
        item.economicSector,
        item.executive,
        item.stakeholderIdentifier,
        item.stakeholderName,
        item.operationId,
        item.operationDate,
        item.documentType,
        item.documentNumber,
        item.amount,
        item.dueDate,
        item.conciliationAmount,
        item.lastConciliationDate,
        item.leftAmount,
        item.anticipatedAmount,
        item.priceDifference,
        item.dailyAccrual,
        item.collectionStatus,
        item.arrearDays,
        item.arrearRate,
        item.documentState,
        item.fundType,
      ];
    });
    const headers = [
      'Rut cliente',
      'Nombre cliente',
      'Sector económico',
      'Ejecutivo',
      'Rut deudor',
      'Nombre deudor',
      'Número operación',
      'Fecha operación',
      'Tipo documento',
      'Número documento',
      'Monto documento',
      'Fecha liberación',
      'Abono',
      'Fecha último abono',
      'Saldo documento',
      'Monto anticipado',
      'Diferencia de precio',
      'Devengo diario',
      'Estado cobranza',
      'Días mora',
      'Interés mora',
      'Estado documento',
      'Fondo',
    ];
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Sheet1');

    // Add data to worksheet
    [headers, ...plainData].forEach((rowData, rowIndex) => {
      const row = worksheet.addRow(rowData);
      if (rowIndex === 0) {
        // Make the first row bold, visible, and add grey background color
        row.eachCell((cell) => {
          cell.font = { bold: true };
          cell.alignment = { vertical: 'middle', horizontal: 'center' };
          worksheet.getColumn(cell.col).width = 20;
          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'FFDDDDDD' }, // Grey background color
          };
        });
      }
    });
    // Save workbook
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
    const url = URL.createObjectURL(blob);

    const a = document.createElement('a');
    a.href = url;
    a.download = 'data.xlsx';
    a.click();

    URL.revokeObjectURL(url);
  };

  const setUp = async () => {
    try {
      setLoading(true);
      const response = await getActiveMoneyReports(dispatch);
      setData(response);
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };
  useEffect(() => {
    setUp();
  }, []);

  if (loading) return <CenteredCircularProgress type="layout" />;
  return (
    <Container style={{ padding: 0, margin: 0 }}>
      <div>
        <HeaderWithActions
          title="Acá encontrarás todos los documentos de tu cartera de clientes."
          variant="body1"
          buttons={[
            {
              action: () => setOpen(true),
              name: 'Proyectar mora',
              color: 'secondary',
              startIcon: <QueryStatsIcon />,
              disabled: selectedDocuments.length === 0 ? true : false,
            },
            {
              action: () => exportToExcel(),
              name: 'Descargar XSLX',
              color: 'primary',
              startIcon: <Download />,
            },
          ]}
        />
        <EnhancedTable
          dataHeaders={[
            { key: 'businessIdentifier', label: 'Rut cliente', type: 'report' },
            { key: 'businessName', label: 'Nombre cliente' },
            { key: 'economicSector', label: 'Sector económico' },
            { key: 'executive', label: 'Ejecutivo' },
            { key: 'stakeholderIdentifier', label: 'Rut deudor' },
            { key: 'stakeholderName', label: 'Nombre deudor' },
            { key: 'operationId', label: 'Número operación' },
            { key: 'operationDate', label: 'Fecha operación', type: 'date' },
            { key: 'documentType', label: 'Tipo documento' },
            { key: 'documentNumber', label: 'Número documento' },
            { key: 'amount', label: 'Monto documento', type: 'money' },
            { key: 'dueDate', label: 'Fecha liberación', type: 'date' },
            { key: 'conciliationAmount', label: 'Abono', type: 'money' },
            {
              key: 'lastConciliationDate',
              label: 'Fecha último abono',
              type: 'date',
            },
            { key: 'leftAmount', label: 'Saldo documento', type: 'money' },
            {
              key: 'anticipatedAmount',
              label: 'Monto anticipado',
              type: 'money',
            },
            {
              key: 'priceDifference',
              label: 'Diferencia de precio',
              type: 'money',
            },
            { key: 'dailyAccrual', label: 'Devengo diario', type: 'money' },
            { key: 'collectionStatus', label: 'Estado cobranza' },
            { key: 'arrearDays', label: 'Días mora' },
            { key: 'arrearAmount', label: 'Interés mora', type: 'money' },
            { key: 'documentState', label: 'Estado documento' },
            { key: 'fundType', label: 'Fondo' },
          ]}
          data={data}
          selected={selectedDocuments}
          setSelected={setSelectedDocuments}
          actions={[]}
          showActions
          rowsPerPageDefault={100}
        />
        <DebtProjector
          open={open}
          documents={data.filter((document: any) =>
            selectedDocuments.includes(document.id),
          )}
          setOpen={setOpen}
        />
      </div>
    </Container>
  );
};

export default ActiveMoney;
